import { useMemo } from "react";
import styled from "styled-components";
import { em, rem } from "polished";
import useMenuItems from "@/hooks/useMenuItems";
import useMedia from "@/hooks/useMedia";
import { hoverUnderline } from "@/styles/helpers";
import Nav from "@/components/Nav";

const StyledNav = styled(Nav)`
  ul {
    width: 100%;
  }
  
  @media ${({ theme }) => theme.devices.tablet} {
    ul {
      width: auto;
    }
  }

  li {
    white-space: nowrap;
  }

  a {
    color: ${({ theme }) => theme.colors.offsetBlack};
    text-decoration: none;

    ${hoverUnderline()}
  }
`;


const SpecialWrapper = styled.div`
  && {
    grid-column: 2;
  }

  display: flex;

  > nav > ul {
    flex-direction: row;
  }

  > nav + nav {
    margin: 0 ${rem(40)};
  }

  > div {
    margin-left: auto;
  }
`;

function SpecialLayoutWrapper({ children }) {
  const matchSpecialLayout = useMedia(`screen and (min-width: ${em(1480)})`);
  return matchSpecialLayout ? (
    <SpecialWrapper>{children}</SpecialWrapper>
  ) : (
    children
  );
}

export default function NavLinksMobile() {
  const secondaryNavItems = useMenuItems("Secondary menu");

  const secondaryNavItemsSplit = useMemo(() => {
    const half = Math.ceil(secondaryNavItems.length / 2);
    const firstHalf = secondaryNavItems.slice(0, half);
    const secondHalf = secondaryNavItems.slice(-half);
    return [firstHalf, secondHalf];
  }, [secondaryNavItems]);

  return (
    <SpecialLayoutWrapper>
      <StyledNav
        testNamePrefix="footer-link-"
        items={secondaryNavItemsSplit[0]}
        vertical
      />
      <StyledNav
        testNamePrefix="footer-link-"
        items={secondaryNavItemsSplit[1]}
        vertical
      />
    </SpecialLayoutWrapper>
  );
}
