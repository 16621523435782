import { default as NextLink } from "next/link";
import styled, { css } from "styled-components";
import Typography from "@/components/Typography";

const StyledLink = styled.a`
  margin-left: auto;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;

  > span {
    flex-grow: 0;
    display: block;
    margin-right: 0.375em;
  }

  > svg {
    display: none;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    margin-left: 0;

    > svg {
      display: block;
      flex-shrink: 0;
    }
  }

  ${({ linkCapitalized }) =>
    !!linkCapitalized &&
    css`
      color: ${({ theme }) => theme.colors.grey1};
      > span {
        color: ${({ theme }) => theme.colors.grey1};
      }
    `};

  ${({ theme }) =>
    theme.appearance === "dark" &&
    css`
      color: ${theme.colors.grey3};

      &:hover {
        color: ${({ theme }) => theme.colors.white};
      }
    `};
`;

export default function Link({
  to,
  children,
  target,
  smallLink,
  linkCapitalized,
  rel,
  onClick,
  ...rest
}) {
  return (
    <NextLink href={to} passHref>
      <StyledLink
        linkCapitalized={linkCapitalized}
        target={target}
        rel={rel}
        onClick={onClick}
        {...rest}
      >
        <Typography
          as="span"
          variant={
            linkCapitalized
              ? "linkCapitalized"
              : smallLink
              ? "linkSmallBody"
              : "linkBody"
          }
        >
          {children}
        </Typography>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m16.869 5.69-1.06 1.06 4.5 4.5H.75v1.5h19.558l-4.5 4.5 1.061 1.06 6.31-6.31-6.31-6.31Z"
            fill="currentColor"
          />
        </svg>
      </StyledLink>
    </NextLink>
  );
}
