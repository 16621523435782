import { useRef, useState } from "react";
import styled, { css } from "styled-components";
import { rem } from "polished";
import Block from "@/blocks/Block";
import Link from "@/components/Link";
import { useBlockContext } from "@/lib/template";
import Swipe, { SwipeItem } from "swipejs/react";
import Typography from "@/components/Typography";
import useMedia from "@/hooks/useMedia";
import theme from "@/styles/theme";
import { useMessage } from "@/components/Message";

const BlockLayout = styled(Block)`
  display: block;

  @media ${({ theme }) => theme.devices.tablet} {
    display: grid;
    grid-template-columns: 36fr 98fr;
    grid-template-rows: 1fr auto;
    grid-column-gap: ${rem(20)};
    grid-row-gap: ${rem(30)};
  }
`;

const LineContainer = styled.div`
  position: relative;
  width: 100%;
  height: 2px;
  background: ${({ theme }) => theme.colors.grey4};

  @media ${({ theme }) => theme.devices.tablet} {
    height: 4px;
  }
`;

const LineSection = styled.div`
  position: absolute;
  height: 2px;
  background: ${({ theme }) => theme.colors.grey1};
  transition: left 80ms ease-in-out;

  ${({ current, total }) => css`
    left: ${(current * 100.0) / total}%;
    width: ${100.0 / total}%;
  `};

  @media ${({ theme }) => theme.devices.tablet} {
    height: 4px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${rem(20)};
`;

const Button = styled.button`
  border-radius: 100%;
  margin-left: ${rem(12)};
  width: ${rem(16)};
  height: ${rem(16)};
  border: none;
  padding: 0;
  background: ${({ theme }) => theme.colors.grey4};
  cursor: pointer;
  transition: background 80ms ease-in-out;

  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.colors.grey1};
    `};

  @media ${({ theme }) => theme.devices.tablet} {
    margin-left: ${rem(8)};
  }
`;

const SwipeContainer = styled.div`
  grid-column: 2;
  overflow: hidden;
  position: relative;
`;

const SwipeImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1;
  overflow: hidden;
  position: relative;
`;

const ImageContainer = styled.div`
  width: 100%;
  max-width: 180px;
  max-height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${({ theme }) => theme.devices.tablet} {
    max-width: 240px;
    max-height: 240px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
`;

const SwipeWrap = styled(Swipe)`
  margin-top: ${rem(20)};
  overflow: hidden;
  visibility: hidden;
  position: relative;

  @media ${({ theme }) => theme.devices.tablet} {
    margin-top: ${rem(30)};
  }
`;

const SwipeItemStyled = styled(SwipeItem)`
  float: left;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 4px;
`;

const QuotesChar = styled.span`
  color: ${({ theme }) => theme.colors.yellow};
  display: block;
  font-size: ${rem(56)};
  line-height: 0.8;
  margin-bottom: ${rem(-16)};

  @media ${({ theme }) => theme.devices.tablet} {
    font-size: ${rem(160)};
    margin-bottom: ${rem(-60)};
  }
`;

const Quote = styled(Typography)`
  padding-top: ${rem(10)};
`;

const Caption = styled(Typography)`
  padding-top: ${rem(13)};

  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: ${rem(24)};
  }
`;

const StyledLink = styled(Link)`
  margin-left: auto;
  margin-top: ${rem(5)};

  @media ${({ theme }) => theme.devices.tablet} {
    margin-left: -0.5em;
    margin-top: ${rem(14)};
  }
`;

export const fragment = `
  fragment Testimonials on Page_Contentblocks_content_Section_Blocks_Testimonials {
    fieldGroupName
    entries {
      quote
      caption
      client {
        title
        link
      }
      image {
        sourceUrl
        altText
      }
    }
    link {
      label
      path
    }
    display5works
  }
`;

const get5QuotesFromWorks = (works, linkTitle) => {
  let result = [];
  let i = 0;
  while (result.length < 5 && i < works.length) {
    let quote = null;
    const contents = works[i].contentBlocks.content;
    let k = 0;
    while (!quote && k < contents.length) {
      const blocks = contents[k].section.blocks;
      let m = 0;
      while (!quote && m < blocks.length) {
        if (blocks[m].entries && blocks[m].entries.length > 0) {
          quote = blocks[m].entries[0];
        }
        m++;
      }
      k++;
    }
    if (quote) {
      quote.title = `${works[i].title} | ${quote.caption}`;
      quote.link = {
        label: linkTitle,
        path: works[i].uri,
      };
      if (quote.image) {
        quote.image = {
          sourceUrl: quote.image.sourceUrl,
          altText: quote.image.altText,
        };
      }
      result.push(quote);
    }
    i++;
  }
  return result;
};

export default function Testimonials(props) {
  const { entries, link, display5works } = props;
  const swipeRef = useRef();
  const matchMobile = useMedia(theme.devices.mobile);
  const linkTitle = useMessage("quote_link_label", "Check our collaboration");

  const { works } = useBlockContext();
  let items = [...entries];
  items.forEach((e) => {
    e.link = link;
  });

  if (display5works) {
    items = get5QuotesFromWorks(works.nodes, linkTitle);
  }

  const [index, setIndex] = useState(0);
  const [activeItem, setActiveItem] = useState(items[0]);
  const itemsCount = items?.length ?? 0;
  const multipleItems = itemsCount > 1;
  const buttonRefs = useRef([]);
  const isTablet = useMedia(theme.devices.tablet);
  const shouldRenderImage = display5works ? isTablet : true;

  const onSlideChange = (newIndex) => {
    setIndex(newIndex);
    focusButton(newIndex);
    setActiveItem(items[newIndex]);
  };

  const handleClick = (index) => () => {
    swipeRef.current.slide(index, 300);
  };

  const focusButton = (i) => {
    if (buttonRefs.current[i]) {
      buttonRefs.current[i].focus();
    }
  };

  const handleKeyDown = (event, i) => {
    if (event.key === "ArrowLeft") {
      setIndex((prevIndex) =>
        prevIndex === 0 ? itemsCount - 1 : prevIndex - 1
      );
      handleClick((i - 1 + itemsCount) % itemsCount)();
      focusButton((i - 1 + itemsCount) % itemsCount);
    } else if (event.key === "ArrowRight") {
      setIndex((prevIndex) =>
        prevIndex === itemsCount - 1 ? 0 : prevIndex + 1
      );
      handleClick((i + 1) % itemsCount)();
      focusButton((i + 1) % itemsCount);
    }
  };

  if (itemsCount > 0) {
    return (
      <BlockLayout>
        {shouldRenderImage && (
          <SwipeImageContainer>
            {activeItem?.image && (
              <ImageContainer>
                <StyledImage
                  src={activeItem.image.sourceUrl}
                  alt={activeItem.image.altText}
                />
              </ImageContainer>
            )}
          </SwipeImageContainer>
        )}
        <SwipeContainer>
          {multipleItems && (
            <>
              <LineContainer>
                <LineSection current={index} total={itemsCount} />
              </LineContainer>
              <ButtonsContainer>
                {Array(itemsCount)
                  .fill(0)
                  .map((_, i) => {
                    return (
                      <Button
                        key={i}
                        onClick={handleClick(i)}
                        onKeyDown={(event) => handleKeyDown(event, i)}
                        ref={(el) => (buttonRefs.current[i] = el)}
                        active={index === i}
                        aria-label={`Quote slide ${i + 1}`}
                        tabIndex={index === i ? 0 : -1}
                      />
                    );
                  })}
              </ButtonsContainer>
            </>
          )}
          {display5works && !isTablet && (
            <SwipeImageContainer>
              {activeItem?.image && (
                <ImageContainer>
                  <StyledImage
                    src={activeItem.image.sourceUrl}
                    alt={activeItem.image.altText}
                  />
                </ImageContainer>
              )}
            </SwipeImageContainer>
          )}
          <SwipeWrap
            draggable={multipleItems}
            continuous
            ref={swipeRef}
            auto={0}
            callback={onSlideChange}
          >
            {items.map((item, i) => {
              const isVisible = index === i;
              return (
                <SwipeItemStyled key={item.quote}>
                  <QuotesChar
                    aria-hidden={isVisible ? "false" : "true"}
                    aria-label={isVisible ? "Quotation mark" : ""}
                    role="img"
                  >
                    “
                  </QuotesChar>
                  <Quote
                    component="div"
                    variant={matchMobile ? "body" : "largeBody"}
                    render={item.quote}
                    aria-hidden={isVisible ? "false" : "true"}
                  />
                  <Caption
                    variant={matchMobile ? "body" : "largeBody"}
                    colorVariant="textLight"
                    render={item.title || item.caption}
                    aria-hidden={isVisible ? "false" : "true"}
                  />
                  {item.link?.label && (
                    <StyledLink
                      linkCapitalized
                      target="_blank"
                      to={item.link.path}
                      tabIndex={isVisible ? 0 : -1}
                      aria-hidden={isVisible ? "false" : "true"}
                    >
                      {item.link.label}
                    </StyledLink>
                  )}
                </SwipeItemStyled>
              );
            })}
          </SwipeWrap>
        </SwipeContainer>
      </BlockLayout>
    );
  }

  return null;
}
