import React from "react";
import styled from "styled-components";
import Image from "next/image";
import { rem } from "polished";
import clutchImg from "@/assets/badge-clutch.svg";

const StyledDiv = styled.div`
  width: 100%;

  @media ${({ theme }) => theme.devices.mobile} {
    margin-bottom: ${rem(16)};
    margin-top: ${rem(0)};
  }

  @media ${({ theme }) => theme.devices.tablet} {
    margin-bottom: ${rem(0)};
    margin-top: ${rem(0)};
  }

  @media ${({ theme }) => theme.devices.laptop} {
    margin-top: ${rem(16)};
  }
`;

export default function ClutchWidget() {
  return (
    <StyledDiv>
      <Image src={clutchImg} alt="Clutch" width={125} height={50} />
    </StyledDiv>
  );
}
