import styled from "styled-components";
import useMenuItems from "@/hooks/useMenuItems";
import Nav from "@/components/Nav";

const StyledNav = styled(Nav)`
  ul {
    justify-content: space-between;
  }
  
  a {
    text-decoration: none;
  }
`;

export default function NavLinksDesktop() {
  const secondaryNavItems = useMenuItems("Secondary menu");

  return <StyledNav items={secondaryNavItems} />;
}
