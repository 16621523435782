import styled from "styled-components";
import { rem } from "polished";
import { useBlockContext } from "@/lib/template";
import { useRouter } from "next/router";
import { AllSolutions } from "@/blocks/Solutions/AllSolutions";
import { SolutionsPost } from "@/blocks/Solutions/SolutionsPost";
import { BackToServices } from "@/blocks/Solutions/BackToServices";
import SectionHeading from "@/components/SectionHeading";

export const Wrapper = styled.div`
  @media ${({ theme }) => theme.devices.tablet} {
    margin-bottom: 0;
    margin-top: ${rem(-48)} !important;
  }
`;

export const BlockLayout = styled.div`
  grid-column: 3/4;

  > ul {
    margin: 0;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    display: grid;
    grid-template-columns: 36fr 98fr;
    grid-column-gap: ${rem(20)};

    > ul {
      grid-column: 2;
    }
  }
`;

const StyledSectionHeading = styled(SectionHeading)`
  margin-bottom: 0;

  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media ${({ theme }) => theme.devices.mobile} {
    order: 1;
    margin-bottom: ${rem(24)};
  }
`;

const HeadSection = styled.div`
  @media ${({ theme }) => theme.devices.tablet} {
    display: grid;
    grid-template-columns: 36fr 98fr;
    grid-column-gap: ${rem(20)};
    margin-top: ${rem(63)};
  }

  @media ${({ theme }) => theme.devices.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

const StyledSolutionsPost = styled(SolutionsPost)`
  @media ${({ theme }) => theme.devices.mobile} {
    order: 2;
  }
`;

export const fragment = `
  fragment SolutionsBlock on Page_Contentblocks_content_Section_Blocks_Solutions {
    fieldGroupName
    label
    tagsOnly
    headline
  }
`;

export default function Solutions(props) {
  const { label, tagsOnly, headline } = props;
  const { postType, solutions, solutionsUri } = useBlockContext();
  const isSolutionPost = postType === "solution";

  const router = useRouter();
  const [parentPath] = /^.*\//.exec(router.asPath) || [];
  const selectedSolution = solutions.nodes.find(
    (s) => s.uri === `${router?.asPath}/`
  );

  if (tagsOnly && isSolutionPost) {
    return (
      <Wrapper>
        <BackToServices solutionsUri={solutionsUri} parentPath={parentPath} />
        <HeadSection>
          <StyledSolutionsPost
            solutions={solutions?.nodes}
            parentPath={parentPath}
            selectedSolution={selectedSolution}
            solutionsUri={solutionsUri}
            isSolutionPost={isSolutionPost}
          />
          {headline && (
            <StyledSectionHeading as="h1">{headline}</StyledSectionHeading>
          )}
        </HeadSection>
      </Wrapper>
    );
  }

  return <AllSolutions solutions={solutions?.nodes} label={label} />;
}
