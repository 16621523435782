import { useState, useEffect } from "react";

const useMedia = (query, defaultState = false) => {
  const [state, setState] = useState(() => defaultState);

  useEffect(() => {
    const targetQuery = window.matchMedia(query);

    function onChange() {
      setState(targetQuery.matches);
    }

    targetQuery.addEventListener("change", onChange);
    onChange();

    return () => {
      targetQuery.removeEventListener("change", onChange);
    };
  }, [query]);

  return state;
};

export default useMedia;
