import styled from "styled-components";
import { useState, useEffect } from "react";

const StyledWrapper = styled.div`
  position: fixed;
  top: 90%;
  left: 85%;
  z-index: 1;

  @media (min-width: 426px) {
    display: none;
  }
`;

const StyledButtonBackToTop = styled.button`
  background-color: ${({ theme }) => theme.colors.yellow};
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font: inherit;
  padding: 0.6em 0.4em;
`;

export default function ButtonBackToTop(props) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showGoTop, setShowGoTop] = useState("none");

  useEffect(() => {
    window.addEventListener("scroll", handleVisibleButton);
  });

  const handleVisibleButton = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);

    if (scrollPosition > window.innerHeight / 2 && window.innerWidth < 426) {
      return setShowGoTop("block");
    } else {
      return setShowGoTop("none");
    }
  };

  return (
    <StyledWrapper
      {...props}
      style={{ display: showGoTop }}
      onClick={props.scrollUp}
    >
      <StyledButtonBackToTop>
        <svg
          width="17"
          height="10"
          viewBox="0 0 17 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M-6.18161e-08 8.58581L1.41419 10L7.414 4.00019L8.41394 2.9773L9.41406 4.00038L15.4137 10L16.8279 8.58581L8.41394 0.171753L-6.18161e-08 8.58581Z"
            fill="#161623"
          />
        </svg>
      </StyledButtonBackToTop>
    </StyledWrapper>
  );
}
