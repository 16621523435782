import styled from "styled-components";
import { rem } from "polished";
import Location from "@/components/Location";
import Block from "@/blocks/Block";

export const fragment = `
  fragment Locations on Page_Contentblocks_content_Section_Blocks_Locations {
    fieldGroupName
    locations {
      label
      name
      address
      image {
        src: sourceUrl
        alt: altText
        mediaDetails {
          width
          height
        }
      }
      links {
        link
        text
      }
    }
  }
`;

const LocationsSection = styled(Block)`
  grid-row-gap: ${rem(64)};
  margin-top: ${rem(33)} !important;
  margin-bottom: 5em !important;
`;

export default function Locations({ locations = [] }) {
  return (
    <LocationsSection fullWidth>
      {locations.map((location) => (
        <Location key={location.name} {...location} />
      ))}
    </LocationsSection>
  );
}
