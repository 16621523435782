import { useRouter } from "next/router";
import styled from "styled-components";
import { rem } from "polished";
import Block from "@/blocks/Block";
import Button from "@/components/Button";
import Message from "@/components/Message";
import useDialogContext from "@/hooks/useDialogContext";

const BlockOffset = styled.div`
  @media ${({ theme }) => theme.devices.laptop} {
    display: grid;
    /* layout: A2 */
    grid-template-columns: 36fr 98fr;
    grid-column-gap: ${rem(20)};

    > * {
      grid-column: 2;
    }
  }
`;

const BlockLayout = styled(Block)`
  display: grid;
  grid-template-rows: 1fr auto;
  grid-column-gap: ${rem(40)};
  grid-row-gap: ${rem(20)};

  button, a {
    justify-self: center;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;

    button, a {
      align-self: start;
      justify-self: start;
    }
  }
`;

export const fragment = `
  fragment CtaBlock on Page_Contentblocks_content_Section_Blocks_Cta {
    fieldGroupName
    text
    cta {
      action
      label
      anchor
      ctaAppearance
      pageUrl
      dataTestid
    }
  }
`;

export default function Cta(props) {
  const { text, cta, className } = props;
  const { action, label, anchor, ctaAppearance, pageUrl, dataTestid } = cta || {};

  const router = useRouter();
  const [, setScheduleCallOpen] = useDialogContext();

  function handleAction() {
    switch (action) {
      case "schedule-a-call":
        setScheduleCallOpen(true);
        break;

      case "scroll-anchor":
        router.push(`#${anchor}`);
        break;

      default:
        console.warn(`CTA action "${action}" not implemented`);
    }
  }

  return (
    <BlockOffset className={className}>
      <BlockLayout>
        {text && <h3>{text}</h3>}
        <Button as = {action === "open-page" ? "a" : "button"} href={pageUrl} onClick={action === "open-page" ? undefined : handleAction} appearance={ctaAppearance} testName={dataTestid}>
          {action === "schedule-a-call" ? (
            <Message id="cta_schedule_call" fallbackText="Schedule A Call" />
          ) : (
            label
          )}
        </Button>
      </BlockLayout>
    </BlockOffset>
  );
}
