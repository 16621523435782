import styled from "styled-components";

const StyledButtonBlank = styled.button`
  flex-shrink: 0;
  appearance: none;
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0;
  font-size: ${({ theme }) => theme.fonts.body.fontSize};
  font-weight: ${({ theme }) => theme.fonts.body.fontWeight};
  line-height: ${({ theme }) => theme.fonts.body.lineHeight};
`;

export default function ButtonBlank({ children, ...props }) {
  return <StyledButtonBlank {...props}>{children}</StyledButtonBlank>;
}
