import React from "react";
import styled, { keyframes } from "styled-components";

const rotateAnim = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dashAnim = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;

const StyledSpinner = styled.svg`
  animation: ${rotateAnim} 2s linear infinite;
  width: 1em;
  height: 1em;

  circle {
    animation: ${dashAnim} 1.5s ease-in-out infinite;
  }
`;

export default function Spinner() {
  return (
    <StyledSpinner viewBox="0 0 50 50">
      <circle
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
      />
    </StyledSpinner>
  );
}
