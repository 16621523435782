import { useState } from "react";
import styled, { css } from "styled-components";
import { rem } from "polished";
import { AnimatePresence, motion } from "framer-motion";
import Block from "@/blocks/Block";
import DetailsPanel from "@/components/DetailsPanel";
import RichText from "@/components/RichText";
import Image from "@/components/Image";
import Link from "@/components/Link";
import useMedia from "@/hooks/useMedia";
import theme from "@/styles/theme";

const IntroLayout = styled.div`
  margin-top: ${rem(8)};
  margin-bottom: ${rem(8)};

  > p {
    max-width: 766px;
  }

  @media ${({ theme }) => theme.devices.laptop} {
    margin-top: 0;
    margin-bottom: 0;
    display: grid;
    grid-template-columns: 36fr 98fr;

    > p {
      grid-column: 2;
    }
  }
`;

const BlockLayout = styled(Block)`
  display: inherit;
  grid-template-rows: 1fr auto;
  grid-row-gap: ${rem(40)};

  @media ${({ theme }) => theme.devices.laptop} {
    /* layout: C2 */
    grid-template-columns: 58fr 76fr;
  }
`;

const imageRatioStyles = css`
  > div {
    padding-top: 75%; /* 4/3 ratio */
    position: relative;

    > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

const ImageWrapper = styled(motion.div)`
  grid-column: 2;
  grid-row: 1 / -1;
  ${imageRatioStyles};
`;

// const InlineImageWrapper = styled.div`
//   margin-top: ${rem(30)};
//   ${imageRatioStyles};
// `;

const imageMotion = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

export const fragment = `
  fragment DetailsWithImage on Page_Contentblocks_content_Section_Blocks_DetailsWithImage {
    fieldGroupName
    intro
    entries {
      summary
      content
      image {
        src: sourceUrl
      }
    }
    link {
      label
      path
    }
  }
`;

export default function DetailsWithImage(props) {
  const { intro, entries, link } = props;

  const [index, setIndex] = useState(0);
  const entry = entries[index];

  const laptopWidth = useMedia(theme.devices.laptop);

  return (
    <>
      {intro && (
        <IntroLayout>
          <p>{intro}</p>
        </IntroLayout>
      )}
      <BlockLayout>
        <DetailsPanel
          data={entries}
          onChange={setIndex}
          renderContent={(content) => <RichText light render={content} />}
        />
        {laptopWidth && (
          <AnimatePresence exitBeforeEnter>
            <ImageWrapper
              key={entry.image.src}
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={imageMotion}
            >
              <Image src={entry.image.src} alt="" />
            </ImageWrapper>
          </AnimatePresence>
        )}
        {link?.label && (
          <Link linkCapitalized to={link.path}>
            {link.label}
          </Link>
        )}
      </BlockLayout>
    </>
  );
}
