import styled from "styled-components";
import BackLink from "@/components/BackLink";
import { useEffect, useState } from "react";

const BackLinkContainer = styled.div`
  grid-column: 3/4;
  position: relative;
`;

const BackLinkStyled = styled(BackLink)`
  position: absolute;
  margin-top: calc(2rem - 9.5px);
`;

export const SectionBackLink = ({ backButtonLabel }) => {
  const [backButtonVisible, setBackButtonVisible] = useState(false);

  useEffect(() => {
    // only display if page is visited from outside this portal
    if (document.referrer !== window.location.href) {
      setBackButtonVisible(true);
    }
  }, []);

  return backButtonVisible ? (
    <BackLinkContainer>
      <BackLinkStyled fallbackText={backButtonLabel} />
    </BackLinkContainer>
  ) : null;
};
