import styled, { css } from "styled-components";
import { rem } from "polished";
import Link from "next/link";

const StyledNav = styled.nav`
  ul {
    display: flex;
    margin: ${rem(-5)} ${rem(-20)};
  }

  li {
    padding: ${rem(5)} ${rem(20)};
  }

  ${({ theme }) =>
    theme.appearance === "dark" &&
    css`
      a {
        color: ${theme.colors.white};

        &:hover {
          color: ${theme.colors.white};
        }
      }
    `};

  ${({ vertical }) =>
    vertical &&
    css`
      ul {
        flex-direction: column;
      }
    `};
`;

const renderDefault = (item, onItemClick = () => {}, testNamePrefix) => {
  const { id, label, path, selected } = item;
  return (
    <li key={id} role="presentation">
      <Link href={path} passHref>
        <a
          onClick={() => {
            onItemClick(item);
          }}
          aria-selected={selected === true ? selected : undefined}
          data-testid={testNamePrefix ? `${testNamePrefix}${label}` : undefined}
          role="tab"
          aria-roledescription="link"
        >
          {label}
        </a>
      </Link>
    </li>
  );
};

export default function Nav({
  items = [],
  secondary = false,
  vertical = false,
  render = () => {},
  className,
  style,
  onItemClick,
  testNamePrefix,
}) {
  if (items.length) {
    return (
      <StyledNav
        secondary={secondary}
        vertical={vertical}
        className={className}
        style={style}
      >
        <ul role="tablist">
          {items.map(
            (item) =>
              render(item, onItemClick) ||
              renderDefault(item, onItemClick, testNamePrefix)
          )}
        </ul>
      </StyledNav>
    );
  }

  return null;
}
