import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { rem } from "polished";
import RichText from "@/components/RichText";
import Cta from "@/blocks/Cta";
import Block from "@/blocks/Block";
import Script from "next/script";
import useOnScreen from "@/hooks/useOnScreen";

export const fragment = `
  fragment VideoBlock on Page_Contentblocks_content_Section_Blocks_VideoBlock {
    fieldGroupName
    videoId
    text
     cta {
      action
      label
      anchor
      ctaAppearance
      pageUrl
      dataTestid
    },
  }
`;

const IframeWrapper = styled.div`
  align-self: start;
  margin-bottom: 1em;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

const StyledRichText = styled(RichText)`
  padding-right: 0 !important;

  @media ${({ theme }) => theme.devices.tablet} {
    h2 {
      margin: 0 0 ${rem(34)} 0 !important;
    }
  }
`;

const BlockLayout = styled(Block)`
  grid-column-gap: 8rem;

  > div:first-of-type {
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 400px;
  }

  @media ${({ theme }) => theme.devices.laptop} {
    grid-template-columns: 58fr 76fr;
  }
`;

const StyledCta = styled(Cta)`
  grid-template-columns: 0fr !important;
  grid-column-gap: 0;
  margin-top: ${rem(26)};
`;

export default function VideoBlock({ videoId, cta, text }) {
  const vidRef = useRef(null);
  const [player, setPlayer] = useState(null);

  const ref = useRef(null);
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    if (!isVisible || !videoId) return;

    const onYouTubeIframeAPIReady = () => {
      createPlayer();
    };

    if (window.YT && window.YT.Player) {
      onYouTubeIframeAPIReady();
    } else {
      window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    }

    return () => {
      if (player) player.destroy();
    };
  }, [videoId, isVisible]);

  const createPlayer = () => {
    if (!videoId) return;

    const newPlayer = new window.YT.Player(vidRef.current, {
      videoId,
      playerVars: {
        modestbranding: 1,
        rel: 0,
        mute: 1,
        controls: 0,
        showinfo: 0,
        autoplay: 1,
        loop: 1,
        playlist: videoId,
        disablekb: 1,
      },
      events: {
        onReady: (event) => {
          event.target.playVideo();
        },
      },
      title: "Mooncascade video",
    });
    setPlayer(newPlayer);
  };

  return (
    <BlockLayout ref={ref}>
      {isVisible && (
        <>
          <Script src="https://www.youtube.com/iframe_api" />
          <div>
            <IframeWrapper ref={vidRef} />
          </div>
          <div>
            <StyledRichText render={text} />
            <StyledCta cta={cta} />
          </div>
        </>
      )}
    </BlockLayout>
  );
}
