import styled, { css } from "styled-components";

function applyColorStyle(props) {
  const { light, theme } = props;
  const darkTheme = theme.appearance === "dark";
  if (light) {
    return css`
      color: ${darkTheme
        ? theme.colors.grey2
        : theme.colors.grey1};
    `;
  } else if (darkTheme) {
    return css`
      color: ${theme.colors.white};
    `;
  }
}

// FIXME: Similarities with RichText

const StyledText = styled.p`
  font-size: var(--font-size-base);
  margin-top: 0;

  @media ${({ theme }) => theme.devices.laptop} {
    padding-right: 5em;
  }

  ${({ narrow }) =>
    narrow &&
    css`
      max-width: 36ch;
    `};

  ${applyColorStyle};
`;

// FIXME: Headings sequential order

export default function Text(props) {
  const { light, narrow, as, children, className, style } = props;
  return (
    <StyledText
      light={light}
      narrow={narrow}
      as={as}
      className={className}
      style={style}
    >
      {children}
    </StyledText>
  );
}
