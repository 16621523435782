import { useMemo } from "react";
import { useGlobalContext } from "@/lib/global-context";

export default function useMenuItems(menuName) {
  const site = useGlobalContext();
  const nodes = useMemo(() => site?.menus?.nodes ?? [], [site]);
  const navItems = useMemo(() => {
    const menu = nodes.find(({ name }) => name === menuName);
    const navItems = menu?.menuItems?.edges?.map(({ node }) => node);
    return navItems || [];
  }, [menuName, nodes]);
  return navItems;
}
