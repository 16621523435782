import { em, rem } from "polished";

export function fluidValue(property, minValue, maxValue, minWidth, maxWidth) {
  return `
    ${property}: ${rem(minValue)};
    
    @media screen and (min-width: ${em(minWidth)}) {
      ${property}: calc(${rem(minValue)} + ${
    maxValue - minValue
  } * ((100vw - ${em(minWidth)}) / ${maxWidth - minWidth}));
    }

    @media screen and (min-width: ${em(maxWidth)}) {
      ${property}: ${rem(maxValue)};
    }
  `;
}

export function hoverUnderline(extraStyle) {
  return `
   @media (hover: hover) {
    &:hover {
      text-decoration: underline;
      
      ${extraStyle}
    }
  }
  @media (hover: none) {
    &:active:hover {
      text-decoration: underline;
      
      ${extraStyle}
    }
  }
  `;
}
