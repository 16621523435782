import { useMemo } from "react";
import { useGlobalContext } from "@/lib/global-context";

export default function useStrings() {
  const site = useGlobalContext();
  const strings = useMemo(
    () => site?.acfOptionsFunctionalTexts?.functionalTexts?.entries ?? [],
    [site]
  );
  return strings;
}
