import styled, { css } from "styled-components";
import { rem } from "polished";
import Image from "next/image";
import { absoluteSrcLoader } from "@/lib/wp";
import useMedia from "@/hooks/useMedia";
import theme from "@/styles/theme";

const Wrapper = styled.div`
  grid-column: 1 / -1;
  overflow: hidden;

  > div {
    display: block !important;
  }

  img {
    image-rendering: pixelated;
    left: 50% !important;
    transform: translateX(-50%);
  }

  ${({ top }) =>
    top &&
    css`
      margin-top: ${rem(40)};
    `};

  ${({ bottom }) =>
    bottom &&
    css`
      margin-bottom: ${rem(40)};
    `};

  ${({ dark, top, bottom }) => {
    if (dark) {
      if (top) {
        return css`
          margin-bottom: 0;
        `;
      } else if (bottom) {
        return css`
          margin-top: 0;
        `;
      }
    } else {
      if (top) {
        return css`
          margin-bottom: ${rem(80)};
        `;
      } else if (bottom) {
        return css`
          margin-top: ${rem(80)};
        `;
      }
    }
  }};
`;

export default function SectionGraphic(props) {
  const { image, imageMobile, top, bottom, dark } = props;
  const matchTablet = useMedia(theme.devices.tablet);
  const targetImage = matchTablet ? image : imageMobile ? imageMobile : image;
  const originWidth = targetImage?.mediaDetails?.width;
  const originHeight = targetImage?.mediaDetails?.height;
  return (
    <Wrapper top={top} bottom={bottom} dark={dark}>
      <Image
        src={targetImage.src}
        alt=""
        layout="fixed"
        width={originWidth}
        height={originHeight}
        loader={absoluteSrcLoader}
      />
    </Wrapper>
  );
}
