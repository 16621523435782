import styled from "styled-components";
import { rem } from "polished";
import ButtonBlank from "@/components/ButtonBlank";

const StyledPagination = styled.div`
  display: flex;
  align-items: center;
  user-select: none;

  > button {
    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  > span {
    display: inline-block;
    font-size: ${rem(14)};
    margin: 0 ${rem(10)};
  }
`;

function Arrow({ left, large }) {
  let styleProps = {};
  if (left) {
    styleProps.transform = "scaleX(-1)";
  }
  if (large) {
    styleProps.width = rem(48);
    styleProps.height = rem(48);
  }
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={styleProps}
    >
      <path
        d="M16.869 5.68945L15.8084 6.75009L20.3083 11.25H0.75V12.75H20.3081L15.8084 17.2497L16.869 18.3104L23.1796 11.9999L16.869 5.68945Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default function Pagination({
  large,
  onPrevious,
  onNext,
  disabledPrevious,
  disabledNext,
  showPrevious = true,
  showNext = true,
  children,
}) {
  return (
    <StyledPagination>
      {showPrevious && (
        <ButtonBlank
          onClick={onPrevious}
          aria-label="Previous item"
          disabled={disabledPrevious}
        >
          <Arrow left large={large} />
        </ButtonBlank>
      )}
      {children && <span>{children}</span>}
      {showNext && (
        <ButtonBlank
          onClick={onNext}
          aria-label="Next item"
          disabled={disabledNext}
        >
          <Arrow large={large} />
        </ButtonBlank>
      )}
    </StyledPagination>
  );
}
