import styled, { css } from "styled-components";

const StyledAnchor = styled.a`
  ${({ noUnderline }) =>
    noUnderline &&
    css`
      text-decoration: none;
    `};
`;

export default function Anchor({ url, label, noUnderline }) {
  return (
    <StyledAnchor href={url} noUnderline={noUnderline}>
      {label}
    </StyledAnchor>
  );
}
