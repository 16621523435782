import { createContext, Fragment, useContext } from "react";
import Head from "next/head";
import HomePageTemplate from "@/templates/page-home";
import OurWorkPageTemplate from "@/templates/page-our-work";
import DefaultPageTemplate from "@/templates/page-default";

import Section from "@/components/Section";
import SectionGraphic from "@/components/SectionGraphic";
import Hairline from "@/components/Hairline";
import ButtonBackToTop from "@/components/ButtonBackToTop";

import Categories from "@/blocks/Categories";
import Career from "@/blocks/Career";
import Cta from "@/blocks/Cta";
import Details from "@/blocks/Details";
import DetailsWithImage from "@/blocks/DetailsWithImage";
import Excerpt from "@/blocks/Excerpt";
import Images from "@/blocks/Images";
import Meta from "@/blocks/Meta";
import Partners from "@/blocks/Partners";
import CloudPartners from "@/blocks/CloudPartners";
import PressCoverage from "@/blocks/PressCoverage";
import RelatedPosts from "@/blocks/RelatedPosts";
import Solutions from "@/blocks/Solutions";
import SubscribeDownload from "@/blocks/SubscribeDownload";
import Stats from "@/blocks/Stats";
import StatsWithTransition from "@/blocks/StatsWithTransition";
import Testimonials from "@/blocks/Testimonials";
import Text from "@/blocks/Text";
import TextIconsList from "@/blocks/TextIconsList";
import Timeline from "@/blocks/Timeline";
import WorkByCategory from "@/blocks/WorkByCategory";
import WorkShowcase from "@/blocks/WorkShowcase";
import { ABOVE, BELOW } from "../constants";
import SectionHeading from "@/components/SectionHeading";
import ContactBlockAsync from "@/blocks/ContactBlockAsync";
import People from "@/blocks/People";
import SocialBlock from "@/blocks/SocialBlock";
import Locations from "@/blocks/Locations";
import VideoBlock from "@/blocks/VideoBlock";
import { SectionBackLink } from "@/components/SectionBackLink";

export function renderTemplate(props) {
  // const postTemplate = props?.postType;
  const pageTemplate = props?.page?.frontendFeatures?.pageTemplate;

  let pageComponent;
  switch (pageTemplate) {
    case "home":
      pageComponent = <HomePageTemplate key="home-page-template" {...props} />;
      break;

    case "our-work":
      pageComponent = (
        <OurWorkPageTemplate key="our-work-page-template" {...props} />
      );
      break;

    case "default":
      pageComponent = (
        <DefaultPageTemplate key="default-page-template" {...props} />
      );
      break;

    default:
      console.warn(`Page template for "${props.page.uri}" not implemented`);
  }

  const seo = props?.page?.seo ?? {};

  return (
    <>
      {seo && (
        <Head>
          {seo.title && <title>{seo.title}</title>}
          {seo.metaDesc && <meta name="description" content={seo.metaDesc} />}
          {seo.focuskw && <meta name="keywords" content={seo.focuskw} />}
          {seo.opengraphTitle && (
            <meta property="og:title" content={seo.opengraphTitle} />
          )}
          {seo.opengraphDescription && (
            <meta
              property="og:description"
              content={seo.opengraphDescription}
            />
          )}
          {seo.opengraphImage && (
            <meta property="og:image" content={seo.opengraphImage.sourceUrl} />
          )}
          {seo.opengraphImage?.altText && (
            <meta
              property="og:image:alt"
              content={seo.opengraphImage.altText}
            />
          )}
          {seo.opengraphUrl && (
            <meta property="og:url" content={seo.opengraphUrl} />
          )}
          {seo.opengraphSiteName && (
            <meta property="og:site_name" content={seo.opengraphSiteName} />
          )}
          <meta property="og:type" content="website" />
        </Head>
      )}

      {pageComponent}
    </>
  );
}

export function renderBlock(block, index) {
  const { fieldGroupName, ...props } = block;
  if (fieldGroupName) {
    const blockName = fieldGroupName.slice(fieldGroupName.lastIndexOf("_") + 1);
    const key = `${blockName}_${index}`;

    // TODO: Wrap in ErrorBoundary component

    switch (blockName) {
      case "Categories":
        return <Categories key={key} {...props} />;

      case "Career":
        return <Career key={key} {...props} />;

      case "Cta":
        return <Cta key={key} {...props} />;

      case "Details":
        return <Details key={key} {...props} />;

      case "Excerpt":
        return <Excerpt key={key} {...props} />;

      case "Images":
        return <Images key={key} {...props} />;

      case "Meta":
        return <Meta key={key} {...props} />;

      case "Partners":
        return <Partners key={key} {...props} />;

      case "CloudPartners":
        return <CloudPartners key={key} {...props} />;

      case "PressCoverage":
        return <PressCoverage key={key} {...props} />;

      case "RelatedPosts":
        return <RelatedPosts key={key} {...props} />;

      case "DetailsWithImage":
        return <DetailsWithImage key={key} {...props} />;

      case "Solutions":
        return <Solutions key={key} {...props} />;

      case "SubscribeDownload":
        return <SubscribeDownload key={key} {...props} />;

      case "Stats":
        return <Stats key={key} {...props} />;

      case "StatsWithTransition":
        return <StatsWithTransition key={key} {...props} />;

      case "Testimonials":
        return <Testimonials key={key} {...props} />;

      case "Text":
        return <Text key={key} {...props} />;

      case "TextIconsList":
        return <TextIconsList key={key} {...props} />;

      case "Timeline":
        return <Timeline key={key} {...props} />;

      case "WorkByCategory":
        return <WorkByCategory key={key} {...props} />;

      case "WorkShowcase":
        return <WorkShowcase key={key} {...props} />;

      case "ContactForm":
        return <ContactBlockAsync key={key} {...props} id="template_block" />;

      case "People":
        return <People key={key} {...props} />;

      case "SocialBlock":
        return <SocialBlock key={key} {...props} />;

      case "VideoBlock":
        return <VideoBlock key={key} {...props} />;

      case "Locations":
        return <Locations key={key} {...props} />;

      default:
        console.warn(`Content block "${blockName}" not implemented`);
        return null;
    }
  }
  // empty block
  return null;
}

// FIXME: Consistent section margins

export function renderSection({ section }, index) {
  const {
    headline,
    blocks,
    theme,
    graphic,
    line,
    hasBackButton,
    backButtonLabel,
  } = section;
  const firstSection = index === 0;
  const key = `section_${index}`;
  const isDarkTheme = theme === "dark";

  const sectionStyle = {};
  const graphicTop = graphic && graphic.image && graphic.align === "top";
  if (graphicTop) {
    sectionStyle.marginTop = 0;
  }
  const graphicBottom = graphic && graphic.image && graphic.align === "bottom";
  if (graphicBottom) {
    sectionStyle.marginBottom = 0;
  }

  const handleScrollUp = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Fragment key={key}>
      <ButtonBackToTop scrollUp={handleScrollUp} />
      {graphicTop && (
        <SectionGraphic
          key={`${key}_top_graphic`}
          {...graphic}
          dark={isDarkTheme}
          top
        />
      )}

      {line === ABOVE && <Hairline />}
      {hasBackButton && <SectionBackLink backButtonLabel={backButtonLabel} />}
      <Section key={key} appearance={theme} fullWidth style={sectionStyle}>
        {headline && (
          <SectionHeading as={firstSection ? "h1" : "h2"}>
            {headline}
          </SectionHeading>
        )}
        {blocks.map(renderBlock)}
      </Section>

      {line === BELOW && <Hairline />}

      {graphicBottom && (
        <SectionGraphic
          key={`${key}_bottom_graphic`}
          {...graphic}
          dark={isDarkTheme}
          bottom
        />
      )}
    </Fragment>
  );
}

export const BlockContext = createContext({});

export function useBlockContext() {
  const context = useContext(BlockContext);
  return context;
}
