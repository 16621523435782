import HtmlToReact from "html-to-react";
import styled, { css } from "styled-components";
import defaultTheme from "@/styles/theme";

const parser = new HtmlToReact.Parser();

function applyColorStyle(props) {
  const { light, theme } = props;
  const darkTheme = theme.appearance === "dark";
  if (light) {
    return css`
      color: ${darkTheme ? theme.colors.grey2 : theme.colors.grey1};
    `;
  } else if (darkTheme) {
    return css`
      color: ${theme.colors.white};
    `;
  }
}

const StyledRichText = styled.div`
  line-height: ${defaultTheme.fonts.body.lineHeight};

  @media ${({ theme }) => theme.devices.laptop} {
    padding-right: 15em;
  }

  @media ${defaultTheme.devices.mobile} {
    p {
      font-weight: ${defaultTheme.fontsMobile.body.fontWeight};
      line-height: ${defaultTheme.fontsMobile.body.lineHeight};
    }
  }

  a {
    color: ${({ theme }) => theme.colors.grey1};
    text-decoration: underline;
    display: inline;

    &:hover {
      color: ${({ theme }) => theme.colors.offsetBlack};
    }
  }

  h1 > a,
  h2 > a {
    color: ${({ theme }) => theme.colors.grey2};
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.colors.grey1};
    }
  }

  ul {
    list-style-type: disc;

    li {
      display: block;

      &::before {
        content: " ⋅ ";
        display: inline-block;
        font-size: 1.5em;
        line-height: 1em;
        white-space: pre;
        transform: translateY(0.075em);
      }
    }
  }

  p + p,
  ul + p,
  ol + p {
    margin-top: 1em;
  }

  ${applyColorStyle};
`;

export default function RichText({ render, light, className, style }) {
  const content = parser.parse(render);
  return (
    <StyledRichText light={light} className={className} style={style}>
      {content}
    </StyledRichText>
  );
}
