import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import Icon from "@/components/Icon";
import iconPlay from "@/assets/icon-play.svg";
import iconPause from "@/assets/icon-pause.svg";

const VideoWithPause = styled.div`
  position: relative;
`;

export default function Video({ src, ...videoProps }) {
  const vidRef = useRef(null);
  const [videoPaused, setVideoPaused] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const toggleVideo = () => {
    if (vidRef?.current?.paused) {
      vidRef?.current?.play();
      setVideoPaused(false);
    } else {
      vidRef?.current?.pause();
      setVideoPaused(true);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLoaded) {
            setIsLoaded(true);
            observer.unobserve(vidRef?.current);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (vidRef?.current) {
      observer.observe(vidRef?.current);
    }

    return () => {
      if (vidRef?.current) {
        observer.unobserve(vidRef?.current);
      }
    };
  }, [isLoaded]);

  return (
    <VideoWithPause>
      <Icon
        onClick={toggleVideo}
        src={videoPaused ? iconPlay : iconPause}
        style={{
          position: "absolute",
          bottom: "0",
          right: "0",
          cursor: "pointer",
          zIndex: 1,
        }}
      />
      <video ref={vidRef} {...(isLoaded ? { src } : {})} {...videoProps} />
    </VideoWithPause>
  );
}
