import styled, { css } from "styled-components";

const StyledLine = styled.div`
  height: 1px;
  position: relative;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
  }

  &:before {
    content: "";
    background-color: ${({ theme }) => theme.colors.grey4};
    display: block;
    height: 1px;
    width: 100%;
  }

  &:after {
    content: "";
    background-color: ${({ theme }) => theme.colors.offsetBlack};
    display: block;
    height: 1px;
    width: 70%;
    transform: scaleX(0);
    transform-origin: left top;
  }

  @media ${({ theme }) => theme.devices.laptop} {
    &:after {
      width: 30%;
    }
  }

  ${({ highlight }) =>
    highlight &&
    css`
      display: none;
    `};

  ${({ theme }) =>
    theme.appearance === "dark" &&
    css`
      &::before {
        background-color: ${theme.colors.grey2};
      }

      &::after {
        background-color: ${theme.colors.white};
      }
    `};
`;

export default function Line({
  highlight = true,
  withTransition = false,
  className,
  style,
}) {
  return (
    <StyledLine
      highlight={highlight}
      withTransition={withTransition}
      className={className}
      style={style}
    />
  );
}
