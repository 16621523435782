import { useEffect, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { rem } from "polished";
import { motion, useViewportScroll } from "framer-motion";
import Link from "next/link";
import { useRouter } from "next/router";
import Logo from "@/components/Logo";
import Button from "@/components/Button";
import ButtonBlank from "@/components/ButtonBlank";
import Message from "@/components/Message";
import Nav from "@/components/Nav";
import useDialogContext from "@/hooks/useDialogContext";
import useMobileNavContext from "@/hooks/useMobileNavContext";
import useMenuItems from "@/hooks/useMenuItems";
import useMedia from "@/hooks/useMedia";
import theme from "@/styles/theme";
import { hoverUnderline } from "@/styles/helpers";
import { EVENT_CTA_CLICK } from "@/lib/gtm";

const StyledHeader = styled(motion.header)`
  background-color: ${({ theme }) => theme.colors.offsetBlack};
  color: ${({ theme }) => theme.colors.white};
  padding-top: ${rem(15)};
  padding-bottom: ${rem(14)};
  position: sticky;
  top: 0;
  z-index: 50;

  display: inherit;
  grid-template-columns: inherit;
  grid-column: 1/-1;

  @media ${({ theme }) => theme.devices.tablet} {
    min-height: ${rem(76.2)}; //cta button is not always in sight, but we need to keep header hight
  }
`;

const Wrapper = styled(motion.div)`
  grid-column: 3/4;

  display: grid;
  /* layout: A2 */
  grid-template-columns: 1fr auto 1fr;
  grid-column-gap: ${rem(20)};
  align-items: center;
  justify-items: start;

  > button:not(:last-child) {
    font-size: ${rem(14)};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    width: 100%;
  }

  @media ${({ theme }) => theme.devices.mobile} {
    > button:not(:last-child) {
      width: auto;
    }
  }

  @media ${({ theme }) => theme.devices.laptop} {
    /* layout: A2 */
    grid-template-columns: 26fr 98fr;
  }

  @media ${({ theme }) => theme.devices.site} {
    /* layout: A2 */
    grid-template-columns: 36fr 98fr;
  }
`;

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;

  > button {
    justify-self: end;
    margin-left: ${rem(20)};
  }

  @media ${({ theme }) => theme.devices.site} {
    justify-content: space-between;
  }
`;

const StyledNav = styled(Nav)`
  li {
    display: flex;
  }

  @media ${({ theme }) => theme.devices.laptop} {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  @media ${({ theme }) => theme.devices.site} {
    display: block;
    li {
      margin: 0 10px;
    }
  }

  a {
    position: relative;
    text-decoration: none;
    white-space: nowrap;
    font-size: ${theme.fonts.smallBody.fontSize};
    line-height: 1.2;

    &:hover:after,
    &[aria-selected="true"]:after {
      content: " ";
      position: absolute;
      bottom: 0.4em;
      left: 0.5em;
      right: 0.5em;
      height: 1px;
      background: ${({ theme }) => theme.colors.white};
    }
  }
`;

const MenuButton = styled(ButtonBlank)`
  grid-column: 3;
  justify-self: right;

  display: flex;
  align-items: center;
  font-size: ${theme.fontsMobile.body.fontSize};
  font-weight: ${theme.fontsMobile.body.fontWeight};
  margin: -0.75em -0.5em -0.75em -0.75em;
  padding: 0.75em;

  > svg {
    margin-left: ${rem(10)};
  }

  ${hoverUnderline()}
`;

const headerMotion = {
  hidden: {
    y: -100,
    transition: {
      type: "spring",
      stiffness: 50,
    },
  },
  visible: {
    y: 0,
    transition: {
      type: "spring",
      damping: 50,
      stiffness: 500,
    },
  },
};

export default function Header() {
  const navItems = useMenuItems("Primary menu");

  const router = useRouter();
  const matchMobile = useMedia(theme.devices.mobile);
  const matchLaptop = useMedia(theme.devices.laptop);
  const matchSite = useMedia(theme.devices.site);

  const { scrollY } = useViewportScroll();
  const [headerVisible, setHeaderVisible] = useState(true);
  const [, setScheduleCallOpen] = useDialogContext();
  const [mobileNavOpen, setMobileNavOpen] = useMobileNavContext();

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    const handleRouteChange = (url) => {
      let urlWithTrailingSlash = url === "/" ? url : `${url}/`;
      navItems.forEach(
        (i) => (i.selected = urlWithTrailingSlash.startsWith(i.url))
      );
    };
    router.events.on("routeChangeStart", handleRouteChange);
    handleRouteChange(window.location.pathname);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, []);

  useEffect(() => {
    function updateHeaderVisible() {
      const latestY = scrollY.get();
      const vy = latestY - scrollY.prev;
      setHeaderVisible(vy < 0 || latestY < 501);
    }
    const unsubscribeScrollY = scrollY.onChange(updateHeaderVisible);
    return () => {
      unsubscribeScrollY();
    };
  }, [scrollY]);

  const handleScheduleCall = () => {
    setScheduleCallOpen(true);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: EVENT_CTA_CLICK,
      category: "get_in_touch",
      position: "header",
    });
  };

  return (
    <StyledHeader
      initial="visible"
      animate={headerVisible || matchMobile ? "visible" : "hidden"}
      variants={headerMotion}
    >
      <ThemeProvider theme={{ ...theme, appearance: "dark" }}>
        {/* to hide initial layout shift (matchTablet) */}
        <Wrapper
          initial="hidden"
          animate={mounted ? "visible" : "hidden"}
          variants={headerMotion}
        >
          <Message
            id="aria_goto_home"
            fallbackText="Go to home page"
            render={(message) => (
              <Link href="/" passHref>
                <a
                  title={message}
                  onClick={() => {
                    setMobileNavOpen(false);
                  }}
                  data-testid="header-home-link"
                >
                  <Logo showType={mounted && matchSite} />
                </a>
              </Link>
            )}
          />

          {matchLaptop ? (
            <NavWrapper>
              <StyledNav items={navItems} testNamePrefix="header-" />
              <Button
                compact
                onClick={handleScheduleCall}
                testName="header-contact-overlay-open"
              >
                <Message
                  id="cta_schedule_call"
                  fallbackText="Schedule A Call"
                />
              </Button>
            </NavWrapper>
          ) : (
            <Button
              compact
              onClick={handleScheduleCall}
              testName="header-contact-overlay-open"
            >
              <Message id="cta_schedule_call" fallbackText="Schedule A Call" />
            </Button>
          )}

          {!matchLaptop && (
            <MenuButton
              onClick={() => {
                setMobileNavOpen(!mobileNavOpen);
              }}
              data-testid="header-mobile-menu"
            >
              {mobileNavOpen ? (
                <Message id="close" fallbackText="Close" />
              ) : (
                <Message id="button_menu" fallbackText="Menu" />
              )}
            </MenuButton>
          )}
        </Wrapper>
      </ThemeProvider>
    </StyledHeader>
  );
}
