import styled, { css } from "styled-components";
import { rem } from "polished";

export const sharedInputStyles = css`
  display: block;
  font: inherit;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.offsetBlack};
  line-height: ${26 / 19};
  padding: ${rem(10)} 0;
  width: 100%;

  &:focus {
    outline: none;
  }

  ${({ theme }) =>
    theme.appearance === "dark" &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `};
`;

export const sharedWrapperStyles = css`
  position: relative;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
  }

  &::before {
    background-color: ${({ theme }) => theme.colors.grey4};
  }

  &::after {
    background-color: ${({ theme }) => theme.colors.yellow};
    transform: scaleX(0);
    transform-origin: left top;
    transition: transform 350ms ${({ theme }) => theme.easing.standard};
  }

  &:focus-within {
    &::after {
      transform: scaleX(1);
    }
  }
`;

const Wrapper = styled.div`
  ${sharedWrapperStyles};

  input {
    ${sharedInputStyles};
  }
`;

export default function Input({ innerRef, ...props }) {
  return (
    <Wrapper>
      <input ref={innerRef} {...props} />
    </Wrapper>
  );
}
