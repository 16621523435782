import styled, { css } from "styled-components";
import { rem } from "polished";
import Block from "@/blocks/Block";
import WorkCard from "@/components/WorkCard";
import Link from "@/components/Link";
import { useBlockContext } from "@/lib/template";
import useMedia from "@/hooks/useMedia";
import theme from "@/styles/theme";

const BlockLayout = styled(Block)`
  display: grid;
  grid-column-gap: ${rem(10)};
  grid-row-gap: ${rem(20)};

  @media ${({ theme }) => theme.devices.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: ${rem(20)};
    grid-row-gap: ${rem(20)};

    ${({ emphasize }) =>
      emphasize &&
      css`
        > *:first-child {
          grid-column: 1 / span 2;

          > div:last-child {
            height: ${(7 / 12) * 100}%;
            padding-top: ${(5 / 12) * 100}%;
          }
        }
      `};
  }
`;

const LinkStyled = styled(Link)`
  @media ${({ theme }) => theme.devices.mobile} {
    padding-top: ${rem(23)};
  }
`;

export const fragment = `
  fragment WorkShowcaseBlock on Page_Contentblocks_content_Section_Blocks_WorkShowcase {
    fieldGroupName
    work {
      ... on Work {
        id
        title
        featuredImage {
          node {
            src: sourceUrl
            alt: altText
            mediaDetails {
              width
              height
            }
          }
        }
        categories {
          nodes {
            id
            name
            slug
          }
        }
        uri
        workFields {
          featuredImage {
            mobileImage {
              src: sourceUrl
              alt: altText
            }
            laptopImage {
              src: sourceUrl
              alt: altText
            }
          }
          showcaseImage {
            squareImage {
              src: sourceUrl
              alt: altText
            }
            wideImage {
              src: sourceUrl
              alt: altText
            }
          }
        }
      }
    }
    link {
      label
      path
    }
  }
`;

export default function WorkShowcase(props) {
  const {
    work,
    link,
    renderWork = () => {},
    emphasize,
    showCount,
    lastCardRef,
    loadMoreClicked,
  } = props;
  const { works } = useBlockContext();
  const largerThanMobile = useMedia(theme.devices.tablet);

  let workList = [...work];

  // add third related work item if is not mobile view
  if (largerThanMobile && work.length === 2) {
    let index = works.nodes.findIndex((w) => w.id === work[1].id);
    index++;
    index %= works.nodes.length;
    workList.push(works.nodes[index]);
  }

  return (
    <BlockLayout emphasize={emphasize}>
      {workList.map((workItem, index) => {
        let i = index;
        if (emphasize && i > 0) {
          i++;
        }
        const row = Math.floor(i / 3.0) + 1;
        const column = (i % 3) + 1;

        return (
          renderWork(workItem) || (
            <WorkCard
              key={workItem.id}
              ref={
                index === showCount - 3 && loadMoreClicked ? lastCardRef : null
              }
              {...workItem}
              row={row}
              column={column}
              emphasize={i === 0 && emphasize}
            />
          )
        );
      })}
      {link?.label && (
        <LinkStyled linkCapitalized to={link.path}>
          {link.label}
        </LinkStyled>
      )}
    </BlockLayout>
  );
}
