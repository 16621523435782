import { useRouter } from "next/router";
import Message from "@/components/Message";
import Button from "@/components/Button";
import useDialogContext from "@/hooks/useDialogContext";

export default function CtaButton({ cta, id }) {
  const { action, label, anchor, ctaAppearance, pageUrl, dataTestid } = cta || {};

  const router = useRouter();
  const [, setScheduleCallOpen] = useDialogContext();

  function handleAction() {
    switch (action) {
      case "schedule-a-call":
        setScheduleCallOpen(true);
        break;

      case "scroll-anchor":
        router.push(`#${anchor}`);
        break;

      case "open-page":
        window.open(pageUrl, "_blank");
        break;

      default:
        console.warn(`CTA action "${action}" not implemented`);
    }
  }

  return (
    <Button id={id} onClick={handleAction} appearance={ctaAppearance} testName={dataTestid}>
      {label ? label : action === "schedule-a-call" && (
        <Message id="cta_schedule_call" fallbackText="Schedule A Call" />
      )}
    </Button>
  )
}
