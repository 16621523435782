import { useState } from "react";
import { rem } from "polished";
import Block from "@/blocks/Block";
import Text from "@/components/Text";
import ArticleItem from "@/components/ArticleItem";
import ArticlePagination from "@/components/ArticlePagination";
import styled from "styled-components";

const BlockLayout = styled(Block)`
  grid-row-gap: 0;

  > p {
    margin-bottom: ${rem(40)};
  }

  > ul {
    margin: 0;
  }

  @media ${({ theme }) => theme.devices.laptop} {
    /* layout: C2 */
    grid-template-columns: 58fr 76fr;
    margin: ${rem(60)} 0;

    > p,
    > ul {
      grid-row: 1;
    }

    > p {
      grid-column: 1;
    }
  }
`;

const ArticlesList = styled.ul`
  grid-column: 1 / -1;
`;

export const fragment = `
  fragment PressCoverageBlock on Page_Contentblocks_content_Section_Blocks_PressCoverage {
    fieldGroupName
    label
    articles {
      title
      medium
      thumbnail {
        src: sourceUrl
        alt: altText
      }
      link
    }
  }
`;

export default function PressCoverage(props) {
  const { label, articles = [] } = props;
  const [selectedPage, setSelectedPage] = useState(0);

  const articleChunks = articles.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 2);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return (
    <>
      <BlockLayout>
        {label && <Text light>{label}</Text>}
        <ArticlesList>
          {articleChunks[selectedPage].map((article) => (
            <ArticleItem key={article.title} article={article} />
          ))}
        </ArticlesList>
      </BlockLayout>
      <ArticlePagination
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        articleChunks={articleChunks}
      />
    </>
  );
}
