import Image from "next/image";
import styled from "styled-components";
import { rem } from "polished";
import { absoluteSrcLoader } from "@/lib/wp";

const StyledUl = styled.ul`
  display: flex;
  margin: 0;
  justify-content: flex-end;

  li + li {
    margin-left: ${rem(20)};
  }

  a {
    margin: 0;
    padding: 0;

    img {
      margin: 0;
      max-height: 122px;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    ul {
      justify-content: flex-end;
    }

    li + li {
      margin-left: ${rem(40)};
    }
  }
`;

export default function PartnersLinks({ partners }) {
  return (
    <StyledUl>
      {partners.map((entry) => {
        const { image, title, link } = entry;
        const { sourceUrl, mediaDetails } = image;
        return (
          <li key={title}>
            <a
              href={link}
              target="_blank"
              rel="noreferrer noopener"
              title={title}
            >
              <Image
                src={sourceUrl}
                alt=""
                width={Math.round(mediaDetails.width / 3)}
                height={Math.round(mediaDetails.height / 3)}
                loader={absoluteSrcLoader}
              />
            </a>
          </li>
        );
      })}
    </StyledUl>
  );
}
