import { em, rem } from "polished";

const theme = {
  breakpoints: {
    mobile: 476,
    tablet: 769,
    laptop: 1024,
    wide: 1921,
  },
  colors: {
    white: "#FFFFFF",
    offsetBlack: "#161623",
    videoOffsetBlack: "#17172A",
    grey1: "#545759",
    grey2: "#7C8184",
    grey3: "#AAAEAF",
    grey4: "#D7DBDD",
    grey5: "#F5F5F7",
    yellow: "#FEDF24",
    success: "#2AA76B",
    error: "#E43333",

    khaki: "#D0B724",
    coolLilac: "#BAB0EB",
    lightBlue: "#C5E3FF",
    metalGreen: "#5B7879",
  },
  easing: {
    standard: "cubic-bezier(0.4, 0.0, 0.2, 1)",
  },
  motion: {
    standard: {
      transition: {
        type: "spring",
        damping: 50,
        stiffness: 1000,
      },
    },
  },
  fonts: {
    extraLarge: {
      fontSize: rem(160),
      lineHeight: 1.0,
      fontWeight: 200,
    },
    h1: {
      fontSize: rem(68),
      lineHeight: 1.2,
      fontWeight: 400,
    },
    h2: {
      fontSize: rem(58),
      lineHeight: 1.2,
      fontWeight: 400,
    },
    h3: {
      fontSize: rem(22),
      lineHeight: 1.2,
      fontWeight: 400,
    },
    largeBody: {
      fontSize: rem(28),
      lineHeight: 1.2,
      fontWeight: 400,
    },
    mediumBody: {
      fontSize: rem(19),
      lineHeight: 1.4,
      fontWeight: 500,
    },
    linkCapitalized: {
      fontSize: rem(19),
      lineHeight: 1.4,
      fontWeight: 400,
      textTransform: "uppercase",
    },
    body: {
      fontSize: rem(19),
      lineHeight: 1.4,
      fontWeight: 400,
    },
    linkBody: {
      fontSize: rem(19),
      lineHeight: 1.4,
      fontWeight: 400,
    },
    smallBody: {
      fontSize: rem(16),
      lineHeight: 1.2,
      fontWeight: 400,
    },
    linkSmallBody: {
      fontSize: rem(16),
      lineHeight: 1.2,
      fontWeight: 400,
    },
    secondaryCTA: {
      fontSize: rem(16),
      lineHeight: 1.175,
      fontWeight: 400,
    },
  },
  fontsMobile: {
    extraLarge: {
      fontSize: rem(75),
      lineHeight: 0.88,
      fontWeight: 300,
    },
    h1: {
      fontSize: rem(32),
      lineHeight: 1.1,
      fontWeight: 400,
    },
    h2: {
      fontSize: rem(20),
      lineHeight: 1.4,
      fontWeight: 400,
    },
    h3: {
      fontSize: rem(20),
      lineHeight: 1.4,
      fontWeight: 400,
    },
    largeBody: {
      fontSize: rem(32),
      lineHeight: 1.4,
      fontWeight: 300,
    },
    mediumBody: {
      fontSize: rem(17),
      lineHeight: 1.4,
      fontWeight: 500,
    },
    linkCapitalized: {
      fontSize: rem(17),
      lineHeight: 1.4,
      fontWeight: 300,
      textTransform: "uppercase",
    },
    body: {
      fontSize: rem(17),
      lineHeight: 1.4,
      fontWeight: 300,
    },
    linkBody: {
      fontSize: rem(17),
      lineHeight: 1.4,
      fontWeight: 300,
    },
    smallBody: {
      fontSize: rem(12),
      lineHeight: 1.4,
      fontWeight: 400,
    },
    linkSmallBody: {
      fontSize: rem(12),
      lineHeight: 1.4,
      fontWeight: 400,
    },
    extraSmallBody: {
      fontSize: rem(10),
      lineHeight: 1.4,
      fontWeight: 300,
    },
    secondaryCTA: {
      fontSize: rem(16),
      lineHeight: 1.175,
      fontWeight: 400,
    },
  },
  sizes: {
    containerWidth: 1366,
  },
};

//TODO Improve brakepoints to distinguish devices
export const devices = {
  mobile: `screen and (max-width: ${em(theme.breakpoints.tablet)})`,
  tablet: `screen and (min-width: ${em(theme.breakpoints.tablet + 1)})`,
  laptop: `screen and (min-width: ${em(theme.breakpoints.laptop + 1)})`,
  site: `screen and (min-width: ${em(theme.sizes.containerWidth + 1)})`,
  wide: `screen and (min-width: ${em(theme.breakpoints.wide + 1)})`,
};

const defaultTheme = {
  ...theme,
  devices,
};

export default defaultTheme;
