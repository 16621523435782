import styled from "styled-components";
import { useRouter } from "next/router";
import { rem } from "polished";
import ChipGroupSolutions from "@/components/ChipGroupSolutions";
import Select from "@/components/Select";
import useMedia from "@/hooks/useMedia";
import theme from "@/styles/theme";

const BlockLayout = styled.div`
  @media ${({ theme }) => theme.devices.mobile} {
    margin-bottom: ${rem(40)};
  }
  
  ul {
    margin: 0 !important;
    
    > li > button {
      padding-left: 0 !important;
    }
  }
`;

export const SolutionsPost = ({
  solutions,
  selectedSolution,
  isSolutionPost,
  className,
}) => {
  const router = useRouter();
  const isTablet = useMedia(theme.devices.tablet);

  const onSelectClick = (id) => {
    const nextSolution = solutions.find((s) => s.id === id);
    if (nextSolution) {
      router.push(nextSolution.uri);
    }
  };

  return (
    <BlockLayout className={className}>
      {!isTablet ? (
        <Select
          items={solutions}
          onClick={onSelectClick}
          current={selectedSolution?.id}
        />
      ) : (
        <ChipGroupSolutions
          solutions={solutions}
          isSolutionPost={isSolutionPost}
        />
      )}
    </BlockLayout>
  );
};
