import { useEffect } from "react";
import Head from "next/head";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "@/components/ErrorFallback";
import ThemeProvider from "@/components/ThemeProvider";
import Layout from "@/components/Layout";
import { GlobalContext } from "@/lib/global-context";
import globalSiteData from "@/data/global-site-data.json";
import { EVENT_PAGE_VIEW, GTM_ID } from "@/lib/gtm";

function getPageTitle(page) {
  const siteTitle = globalSiteData?.generalSettings?.title;
  const pageTitle = page ? (page.isFrontPage ? null : page.title) : "404";
  const title = pageTitle ? `${pageTitle} - ${siteTitle}` : siteTitle;
  return title;
}

function App({ Component, pageProps, router }) {
  const hideFooter = router.pathname === "/404";
  const canonicalUrl = `${process.env.NEXT_PUBLIC_BASE_URL}${
    router.asPath === "/" ? "" : router.asPath
  }`.split("?")[0];
  const pageTitle = getPageTitle(pageProps.page);

  useEffect(() => {
    // Datalayer may not exist yet, create one if not
    window.dataLayer = window.dataLayer || [];

    // Log page views for GTM
    window.dataLayer.push({
      event: EVENT_PAGE_VIEW,
      location: window.location.href,
      title: pageTitle,
    });
  }, [pageTitle]);

  // FIXME: Something prevents the "About Us" page
  // from scrolling to top after navigation.
  // Temporary fix with 'routeChangeComplete'.
  useEffect(() => {
    let timeout;
    const handleRouteChange = () => {
      if (!window.location.hash) {
        timeout = window.setTimeout(() => {
          window.scroll({
            top: 0,
            behavior: "smooth",
          });
        }, 5);
      }
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      if (timeout) {
        window.clearTimeout(timeout);
        timeout = null;
      }
    };
  }, []);

  return (
    <>
      <Head>
        <link rel="canonical" href={canonicalUrl} />
        {GTM_ID && (
          <>
            <script
              dangerouslySetInnerHTML={{
                __html: `
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://gtm.mooncascade.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer', '${GTM_ID}');
                `,
              }}
            />
          </>
        )}
        <title>{pageTitle}</title>
      </Head>

      <GlobalContext.Provider value={globalSiteData}>
        <ThemeProvider>
          <Layout hideFooter={hideFooter}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Component {...pageProps} />
            </ErrorBoundary>
          </Layout>
        </ThemeProvider>
      </GlobalContext.Provider>
    </>
  );
}

export default App;
