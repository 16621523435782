import styled, { css } from "styled-components";
import { rem } from "polished";
import Block from "@/blocks/Block";
import Typography from "@/components/Typography";

const BlockLayout = styled(Block)`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.devices.tablet} {
    display: grid;
    grid-gap: ${rem(20)};
    grid-template-columns: 36fr 49fr 49fr;
    grid-column-gap: ${rem(40)};
  }
`;

const StyledStat = styled.div`
  &:nth-of-type(1n + 2) {
    margin-top: ${rem(34)};
  }

  @media ${({ theme }) => theme.devices.tablet} {
    &:nth-of-type(1n + 2) {
      margin-top: 0;
    }
  }

  ${({ column }) =>
    column &&
    css`
      grid-column: ${column};
    `};
`;

const Label = styled.p`
  margin-bottom: ${rem(6)};
  color: ${({theme}) => theme.colors.grey1};
  @media ${({ theme }) => theme.devices.mobile} {
    margin-bottom: ${rem(10)};
  }
`;


export const fragment = `
  fragment StatsBlock on Page_Contentblocks_content_Section_Blocks_Stats {
    fieldGroupName
    entry {
      label
      value
    }
  }
`;

export default function Stats(props) {
  const { entry = [] } = props;
  return (
    <BlockLayout>
      {entry.map(({ label, value }, index) => (
        <StyledStat key={label} column={index + 1}>
          <Label>{label}</Label>
          <Typography variant="extraLarge" as="p">{value}</Typography>
        </StyledStat>
      ))}
    </BlockLayout>
  );
}
