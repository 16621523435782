import styled, { css } from "styled-components";
import ResponsiveImage from "@/components/ResponsiveImage";

const Wrapper = styled.div`
  grid-column: 1/-1;
`;

// TODO: mobileImage and laptopImage integration

export default function FeaturedImage({
  mobileImage,
  laptopImage,
  fallbackImage,
  mobileRatio,
  laptopRatio,
}) {
  return (
    <Wrapper mobileRatio={mobileRatio} laptopRatio={laptopRatio}>
      <ResponsiveImage
        mobileImage={mobileImage}
        laptopImage={laptopImage}
        fallbackImage={fallbackImage}
        layout="intrinsic"
        priority={true}
        fullscreenImage={true}
      />
    </Wrapper>
  );
}
