import { useMemo } from "react";
import { useRouter } from "next/router";
import WorkShowcase from "@/blocks/WorkShowcase";
import Message from "@/components/Message";
import { useBlockContext } from "@/lib/template";
import { wrap } from "@/lib/helpers";

export const fragment = `
  fragment RelatedPostsBlock on Page_Contentblocks_content_Section_Blocks_RelatedPosts {
    fieldGroupName
  }
`;

export default function RelatedPosts(props) {
  const router = useRouter();
  const uri = router.asPath;

  const { postType, works } = useBlockContext();

  const workPath = useMemo(() => {
    let path = postType === "solution" ? works?.nodes?.[0]?.uri : uri;
    if (path) {
      if (path.endsWith("/")) {
        path = path.substr(0, path.length - 1);
      }
      const [, workPath] = /(^.*)\//.exec(path) || [];
      return workPath;
    }
    return "/";
  }, [postType, works]);

  const nodes = useMemo(() => {
    const isSameUri = (node) => {
      let nodeUri = node?.uri;
      if (nodeUri && nodeUri.endsWith("/")) {
        nodeUri = nodeUri.substr(0, nodeUri.length - 1);
      }
      return nodeUri === uri;
    };

    const nodes = works.nodes;
    const nodesCount = nodes.length;

    if (postType === "solution") {
      const relatedWorks = nodes
        .filter((node) => node?.workFields?.solutions?.some(isSameUri))
        .slice(0, 2);
      return relatedWorks;
    } else {
      // work
      const currentIndex = nodes.findIndex(isSameUri);
      if (currentIndex > -1) {
        const prevIndex = wrap(0, nodesCount, currentIndex - 1);
        const nextIndex = wrap(0, nodesCount, currentIndex + 1);
        const prevNode = nodes[prevIndex];
        const NextNode = nodes[nextIndex];
        if (nodesCount < 3) {
          return [prevNode];
        }
        return [prevNode, NextNode];
      }
    }
    return null;
  }, [postType, works, uri]);

  return nodes ? (
    <Message
      id="view_all"
      fallbackText="View all"
      render={(message) => (
        <WorkShowcase
          work={nodes}
          link={{ label: message, path: workPath }}
          layout="3"
        />
      )}
    />
  ) : null;
}
