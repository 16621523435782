import { useState } from "react";
import { renderSection, BlockContext } from "@/lib/template";

export default function OurWorkPageTemplate({
  page,
  postType,
  pageTemplate,
  categories,
  works,
}) {
  const [category, setCategory] = useState(null);
  const sections = page?.contentBlocks?.content ?? [];
  return (
    <BlockContext.Provider
      value={{
        postType,
        pageTemplate,
        category,
        setCategory,
        categories,
        works,
      }}
    >
      {sections.map(renderSection)}
    </BlockContext.Provider>
  );
}
