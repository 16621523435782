import { useState } from "react";
import styled, { css } from "styled-components";
import expand from "@/assets/expand_down.svg";
import Image from "next/image";
import Typography from "@/components/Typography";

const Dropdown = styled.div`
  position: relative;
  width: 355px;
  max-width: 100%;
`;

const DropdownHeader = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  font-family: inherit;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey4};
  padding: 16px 18px;
  cursor: pointer;
`;

const DropdownBody = styled.ul`
  display: none;
  margin: 0;

  ${({ open }) =>
    open &&
    css`
      display: block;
      position: absolute;
      z-index: 10;
      top: calc(100% + 8px);
      width: 100%;
      background: ${({ theme }) => theme.colors.white};
      box-shadow: 0 4px 36px rgba(0, 0, 0, 0.05);
    `};
`;

const DropdownItem = styled.li`
  padding: 12px 18px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.grey4};
  }

  ${({ selected }) =>
    selected &&
    css`
      background: ${({ theme }) => theme.colors.grey4};
    `};
`;

const Expand = styled.div`
  position: absolute;
  display: flex;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`;

export default function Select(props) {
  const { items, current, onClick, testName } = props;
  const [isOpen, setOpen] = useState(false);

  const toggleDropdown = () => setOpen(!isOpen);

  const handleItemClick = (id) => {
    onClick(id);
    setOpen(false);
  };

  const handleKeyPress = (e, id) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      handleItemClick(id);
    }
  };

  return (
    <Dropdown data-testid={testName}>
      <DropdownHeader
        onClick={toggleDropdown}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        <Typography component="span" variant="body" colorVariant="text">
          {current
            ? items.find((item) => item.id === current).label
            : "Select value"}
        </Typography>
        <Expand>
          <Image src={expand} alt="" width={20} height={20} />
        </Expand>
      </DropdownHeader>
      <DropdownBody
        open={isOpen}
        role="listbox"
        aria-activedescendant={current}
      >
        {items.map((item) => (
          <DropdownItem
            key={item.id}
            onClick={() => handleItemClick(item.id)}
            id={item.id}
            selected={item.id === current}
            role="option"
            aria-selected={item.id === current}
            tabIndex={0}
            onKeyPress={(e) => handleKeyPress(e, item.id)}
          >
            <Typography component="span" variant="body" colorVariant="text">
              {item.label}
            </Typography>
          </DropdownItem>
        ))}
      </DropdownBody>
    </Dropdown>
  );
}
