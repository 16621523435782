import { useMemo } from "react";
import styled, { css } from "styled-components";
import useMedia from "@/hooks/useMedia";
import Image from "@/components/Image";
import theme from "@/styles/theme";
import { absoluteSrcLoader } from "@/lib/wp";

const Wrapper = styled.div`
  position: relative;
  padding-top: 100%;

  ${({ wideAspect }) =>
    wideAspect &&
    css`
      padding-top: 42%;
    `};

  ${({ fullscreenImage }) =>
    fullscreenImage &&
    css`
      padding-top: initial;
    `};

  @media ${({ theme }) => theme.devices.tablet} {
    ${({ row, column }) =>
      row != null &&
      column != null &&
      css`
        grid-row: ${row};
        grid-column: ${column};
      `};
    ${({ emphasize }) =>
      emphasize &&
      css`
        grid-column-end: span 2;
      `};
  }
`;

// FIXME: Calculate and pass image sizes attribute based on layout

export default function ResponsiveImage(props) {
  const {
    mobileImage,
    laptopImage,
    fallbackImage,
    onClick = () => {},
    layout = "fill",
    priority = false,
    className,
    style,
    row,
    column,
    emphasize,
    fullscreenImage = false
  } = props;
  const matchLaptop = useMedia(theme.devices.laptop);
  const wideAspect = matchLaptop && laptopImage;
  const image = useMemo(() => {
    const responsiveImage = matchLaptop
      ? laptopImage || mobileImage
      : mobileImage;
    return responsiveImage || fallbackImage;
  }, [matchLaptop, fallbackImage, mobileImage, laptopImage]);
  return (
    <Wrapper
      onClick={onClick}
      wideAspect={wideAspect}
      className={className}
      style={style}
      row={row}
      column={column}
      emphasize={emphasize}
      fullscreenImage={fullscreenImage}
    >
      {image && (
        <Image
          layout={layout}
          priority={priority}
          width="100%"
          height="100%"
          fullscreenImage={fullscreenImage}
          src={image.src}
          alt={image.alt}
          loader={absoluteSrcLoader}
        />
      )}
    </Wrapper>
  );
}
