import styled from "styled-components";
import { rem } from "polished";
import useMedia from "@/hooks/useMedia";
import theme from "@/styles/theme";
import Block from "@/blocks/Block";
import Image from "next/image";

export const fragment = `
  fragment CloudPartnersBlock on Page_Contentblocks_content_Section_Blocks_CloudPartners {
    fieldGroupName
    label
    partners {     
      title
      link
      imageWidthDesktop
      imageHeightDesktop
      imageWidthMobile
      imageHeightMobile
      image {
        src: sourceUrl
      }
    }
  }
`;

const BlockLayout = styled(Block)`
  display: inherit;
  grid-template-columns: inherit;
  grid-row-gap: ${rem(32)};
  grid-column: 1 / -1;

  > h3 {
    grid-column: 3 / 4;
    margin-bottom: 0;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    grid-column: 3 / 4;
    /* layout A2 */
    grid-template-columns: 36fr 98fr;
    grid-template-rows: 1fr auto;
    grid-row-gap: ${rem(20)};

    > h3 {
      grid-column: 1;
    }
  }
`;

const List = styled.ul`
  grid-column: 3 / 4;
  margin: ${rem(-10)} 0;
  display: grid;
  grid-template-columns: 50% 50%;

  @media ${({ theme }) => theme.devices.laptop} {
    grid-column: 2 / 4;
    grid-template-columns: 25% 25% 25% 25%;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    grid-column: 2 / 4;
  }
`;

const Item = styled.li`
  user-select: none;
  display: flex;
  justify-content: center;

  @media ${({ theme }) => theme.devices.laptop} {
    display: block;
  }
`;

export default function CloudPartners({ label, partners }) {
  const matchMobile = useMedia(theme.devices.mobile);

  return (
    <BlockLayout>
      <h3>{label}</h3>
      <List>
        {partners.map(
          ({
            title,
            link,
            imageWidthDesktop,
            imageHeightDesktop,
            imageWidthMobile,
            imageHeightMobile,
            image,
          }) => {
            const img = (
              <Image
                src={image.src}
                alt={title}
                width={matchMobile ? imageWidthMobile : imageWidthDesktop}
                height={matchMobile ? imageHeightMobile : imageHeightDesktop}
                loader={({ src }) => src}
              />
            );

            return (
              <Item key={link} data-testid={`cloud-partner-${title}`}>
                {link ? (
                  <a target="_blank" href={link} rel="noopener noreferrer">
                    {img}
                  </a>
                ) : (
                  img
                )}
              </Item>
            );
          }
        )}
      </List>
    </BlockLayout>
  );
}
