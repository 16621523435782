import FeaturedImage from "@/components/FeaturedImage";
import FeaturedVideo from "@/components/FeaturedVideo";
import { BlockContext, renderSection } from "@/lib/template";

export default function HomePageTemplate({ page, works }) {
  const { featuredVideo, featuredImage } = page;
  const sections = page?.contentBlocks?.content ?? [];

  return (
    <BlockContext.Provider
      value={{
        works,
      }}
    >
      {featuredVideo ? (
        <FeaturedVideo {...featuredVideo} />
      ) : featuredImage ? (
        <FeaturedImage
          fallbackImage={featuredImage.node}
          mobileRatio={1}
          laptopRatio={5 / 16}
        />
      ) : null}
      {sections.map(renderSection)}
    </BlockContext.Provider>
  );
}
