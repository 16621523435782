import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { rem } from "polished";
import Button from "@/components/Button";
import Message from "@/components/Message";
import RichText from "@/components/RichText";
import Hairline from "@/components/Hairline";
import ArrowIcon from "@/assets/arrow-right-up-icon.svg";
import Image from "@/components/Image";

const BlockLayout = styled.div`
  @media ${({ theme }) => theme.devices.tablet} {
    display: grid;
    grid-template-columns: 36fr 98fr;
  }

  ul,
  h3 {
    margin-top: 0;
  }
`;

export const fragment = `
  fragment CareerBlock on Page_Contentblocks_content_Section_Blocks_Career {
    fieldGroupName
    anchor
    heading
  }
`;

const Item = styled.li`
  &:not(:first-child) {
    margin-top: ${rem(48)};
  }

  &:not(:last-child) {
    margin-bottom: ${rem(60)};
  }

  h2 {
    margin-top: 0;
    margin-bottom: ${rem(48)};
  }

  @media ${({ theme }) => theme.devices.tablet} {
    /* layout: A2 */
    display: grid;
    grid-template-columns: 6fr 1fr;
    grid-column-gap: ${rem(20)};

    a {
      grid-column: 2;
    }
  }
`;
const ButtonWrapper = styled.div`
  @media ${({ theme }) => theme.devices.mobile} {
    margin-top: ${rem(48)};
    text-align: center;
  }
`;
const maxDescriptionLength = 370;

export default function Career(props) {
  const { anchor, heading } = props;
  const mountedRef = useRef();
  const [openings, setOpenings] = useState([]);

  useEffect(() => {
    mountedRef.current = true;

    async function getJobs() {
      const res = await fetch("/api/jobs");
      if (res.ok) {
        const data = await res.json();
        if (mountedRef.current) {
          setOpenings(data.jobs);
        }
      }
    }
    getJobs();

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const blockRef = useRef();
  useEffect(() => {
    if (anchor) {
      const block = blockRef.current;
      const section = block.parentNode;
      section.id = anchor;
    }
  }, [blockRef, anchor]);

  // TODO: Message if no position is open
  return (
    <BlockLayout ref={blockRef}>
      <h3>{heading}</h3>
      <ul>
        {openings.map((position, index) => {
          const { id, title, description, url } = position;

          let descriptionWithLimit = description;
          if (description && description.length > maxDescriptionLength) {
            descriptionWithLimit =
              description.slice(0, maxDescriptionLength).trimEnd() + "…";
          }

          return (
            <>
              <Item key={id}>
                <div>
                  <h2>{title}</h2>
                  <RichText light render={descriptionWithLimit} />
                </div>
                <ButtonWrapper>
                  <Button
                    as="a"
                    href={url}
                    linkProps={{ rel: "noopenner" }}
                    appearance="secondary"
                  >
                    <Message id="link-apply" fallbackText="Apply" />
                    <Image
                      src={ArrowIcon}
                      width={19}
                      height={19}
                      layout="fixed"
                      transparent
                      style={{ marginLeft: rem(8) }}
                      fullscreenImage
                    />
                  </Button>
                </ButtonWrapper>
              </Item>
              {index + 1 !== openings.length && <Hairline />}
            </>
          );
        })}
      </ul>
    </BlockLayout>
  );
}
