import styled, { css } from "styled-components";
import { rem } from "polished";
import Block from "@/blocks/Block";
import Image from "@/components/Image";
import Text from "@/components/Text";
import Link from "@/components/Link";
import Hairline from "@/components/Hairline";
import { ABOVE, BELOW } from "../constants";

function tabletLayoutStyles({ itemCount }) {
  switch (itemCount) {
    case 1:
      return css`
        grid-template-columns: 1fr;
      `;

    case 2:
      return css`
        grid-template-columns: 1fr 1fr;
      `;

    case 3:
      return css`
        /* layout: A */
        grid-template-columns: 45fr 51fr 45fr;

        figure:nth-of-type(2) {
          grid-column: 2;
          grid-row: 1 / 3;
        }
      `;

    case 4:
    case 5:
    case 6:
    default:
      return css`
        /* layout: A */
        grid-template-columns: 45fr 51fr 45fr;
      `;
  }
}

const BlockLayout = styled(Block)`
  display: grid;
  grid-row-gap: ${rem(20)};

  @media ${({ theme }) => theme.devices.tablet} {
    grid-template-rows: 1fr auto;

    a {
      grid-column: 1;
      align-self: end;
    }

    ${tabletLayoutStyles};
  }
`;

const StyledFigure = styled.figure`
  margin: 0;

  h5 {
    font-size: var(--font-size-base);
    font-weight: 400;
    margin-top: ${rem(20)};
    margin-bottom: 0;
  }

  p {
    font-size: var(--font-size-base);
    margin-top: ${rem(4)};
    word-break: break-word;
    padding-right: 0;
  }
`;

const StyledImage = styled(Image)`
  @media ${({ theme }) => theme.devices.mobile} {
    margin-left: ${rem(-10)};
    margin-right: ${rem(-10)};
  }
`;

export const fragment = `
  fragment ImagesBlock on Page_Contentblocks_content_Section_Blocks_Images {
    fieldGroupName
    line
    gallery {
      image {
        src: sourceUrl
        alt: altText
        mediaDetails {
          width
          height
        }
      }
      title
      caption
    }
    link {
      label
      page {
        ... on Page {
          id
          uri
        }
      }
      url
    }
  }
`;

// TODO: Aspect ratio switching:
// 1:1 mobile / 12:5 (~42%) laptop

export default function Images(props) {
  const { gallery, link, line } = props;
  const linkPathOrUrl = link?.page?.uri || link?.url;
  return (
    <>
      {line === ABOVE && <Hairline />}
      <BlockLayout itemCount={gallery.length}>
        {gallery.map((entry) => {
          const { image, title, caption } = entry;
          if (image) {
            const { src, alt, mediaDetails } = image;
            return (
              <StyledFigure key={src}>
                <StyledImage
                  src={src}
                  alt={alt}
                  width={mediaDetails?.width}
                  height={mediaDetails?.height}
                  layout="responsive"
                />
                {/* FIXME: Headings sequential order */}
                {title && <Text as="h5">{title}</Text>}
                {caption && <Text light>{caption}</Text>}
              </StyledFigure>
            );
          }
        })}

        {link?.label && linkPathOrUrl && (
          <Link
            linkCapitalized
            target={link?.url && `_blank`}
            to={link?.page?.uri || link?.url}
          >
            {link.label}
          </Link>
        )}
      </BlockLayout>
      {line === BELOW && <Hairline />}
    </>
  );
}
