import { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { default as NextImage } from "next/image";
import styled, { css } from "styled-components";
import { absoluteSrcLoader } from "@/lib/wp";

const Wrapper = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.grey4};

  > div {
    position: relative;
    height: 100%;

    ${({ fullscreenImage }) =>
      fullscreenImage &&
      css`
        > span {
          display: block !important;

          > span {
            display: none !important;
          }
          
          > img {
            width: 100% !important;
            height: 100% !important;
            position: initial !important;
          }
        }
    `};
  }

  ${({ imageLayout }) =>
    imageLayout === "fill" &&
    css`
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    `};
  
  ${({ transparent }) =>
    transparent &&
    css`
      background-color: transparent;
    `};
`;

const fadeIn = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

export default function Image({
  src,
  alt = "",
  transparent = false,
  className,
  style,
  fullscreenImage = false,
  layout,
  ...props
}) {
  const [loaded, setLoaded] = useState(false);

  return (
    <Wrapper
      className={className}
      style={style}
      fullscreenImage={fullscreenImage}
      imageLayout={layout || "fill"}
      transparent={transparent}
    >
      <motion.div
        initial="hidden"
        animate={loaded ? "visible" : "hidden"}
        variants={fadeIn}
      >
        <NextImage
          src={src}
          alt={alt}
          quality="95"
          layout={layout || "fill"}
          objectFit="cover"
          {...props}
          loader={absoluteSrcLoader}
          onLoadingComplete={() => {
            setLoaded(true);
          }}
        />
      </motion.div>
    </Wrapper>
  );
}
