import dynamic from "next/dynamic";

export const fragment = `
  fragment ContactBlock on Page_Contentblocks_content_Section_Blocks_ContactForm {
    fieldGroupName
    label
  }
`;

const DynamicContactBlock = dynamic(() => import("@/blocks/ContactBlock"), {
  loading: () => <div />,
});

export default function ContactBlockAsync(props) {
  return <DynamicContactBlock {...props} />;
}
