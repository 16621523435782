import styled from "styled-components";
import { rem } from "polished";
import Typography from "@/components/Typography";
import Image from "@/components/Image";
import LinksList from "@/components/LinksList";

const StyledLocation = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.devices.laptop} {
    display: grid;
    grid-template-columns: 37fr 26fr 37fr;
    grid-column-gap: ${rem(20)};
  }
`;

const H3Title = styled.h3`
  margin-bottom: ${rem(30)};

  @media ${({ theme }) => theme.devices.laptop} {
    margin-bottom: 0;
  }
`;

const TypographyLocationName = styled(Typography)`
  margin-top: 0;
  margin-bottom: 0;

  @media ${({ theme }) => theme.devices.laptop} {
    font-size: ${rem(68)};
    margin: 0;
  }
`;

const TypographyAddress = styled(Typography)`
  margin-top: ${rem(30)};
`;

const LocationImageWrapper = styled.div`
  position: relative;
  padding-top: 100%;
  margin-top: ${rem(64)};
  width: 100vw;
  margin-left: calc(-1 * var(--container-padding-inline));

  @media ${({ theme }) => theme.devices.tablet} {
    width: auto;
    margin-left: 0;
  }

  @media ${({ theme }) => theme.devices.laptop} {
    padding-top: 72%;
    margin-right: min(14%, ${rem(80)});
    margin-top: 0;
  }
`;

export default function Location({ label, name, address, image, links }) {
  return (
    <StyledLocation>
      <H3Title>{label}</H3Title>
      <div>
        <TypographyLocationName variant="h1" component="h2" render={name} />
        <TypographyAddress
          variant="body"
          colorVariant="textLight"
          render={address}
        />
        {links && <LinksList links={links} />}
      </div>
      <LocationImageWrapper>
        <Image src={image.src} alt={image.alt || ""} />
      </LocationImageWrapper>
    </StyledLocation>
  );
}
