import styled from "styled-components";
import { rem } from "polished";
import Block from "@/blocks/Block";
import RichText from "@/components/RichText";
import useMedia from "@/hooks/useMedia";
import theme from "@/styles/theme";

const BlockLayout = styled(Block)`
  & + & {
    margin-top: ${rem(40)};
  }

  > h3 {
    align-self: start;
    margin-bottom: 1em;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    /* layout: A2 */
    grid-template-columns: ${({ shouldUseGrid }) =>
      shouldUseGrid ? "36fr 98fr" : "none"};

    & + & {
      margin-top: ${rem(80)};
    }
  }
`;

export const fragment = `
  fragment TextBlock on Page_Contentblocks_content_Section_Blocks_Text {
    fieldGroupName
    label
    text
  }
`;

export default function TextBlock({ label, text }) {
  const isLaptop = useMedia(theme.devices.laptop);
  return (
    <BlockLayout shouldUseGrid={label || isLaptop}>
      {(label || isLaptop) && <h3>{label}</h3>}
      <RichText render={text} />
    </BlockLayout>
  );
}
