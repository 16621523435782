import { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { rem } from "polished";
import Block from "@/blocks/Block";
import { fluidValue } from "@/styles/helpers";

const BlockLayout = styled(Block)`
  grid-column: 1 / -1;
  margin: ${rem(20)} 0;

  > div:first-child {
    display: grid;
    grid-template-columns: 1fr auto;
  }

  @media ${({ theme }) => theme.devices.laptop} {
    grid-column: 3 / 4;
    display: grid;
    /* layout A2 */
    grid-template-columns: 36fr 98fr;
    margin: ${rem(40)} 0 ${rem(60)};

    > div:first-child {
      grid-column: 2;
      grid-template-columns: 1fr auto;
    }
  }
`;

const StatsGroup = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.yellow};
  padding: var(--container-padding-inline);

  @media ${({ theme }) => theme.devices.laptop} {
    margin-left: ${rem(-54)};
    padding: ${rem(54)};
  }
`;

const VideoWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.yellow};
  position: relative;
  width: ${rem(180)};
  height: ${rem(180)};

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media ${({ theme }) => theme.devices.mobile} {
    width: ${rem(200)};
    height: ${rem(200)};
  }

  @media ${({ theme }) => theme.devices.tablet} {
    width: ${rem(320)};
    height: ${rem(320)};
  }
`;

const StyledStat = styled.div`
  span {
    color: ${({ theme }) => theme.colors.offsetBlack};
    display: inline-block;
    font-size: var(--font-size-base);
    margin-bottom: ${rem(20)};
  }
  p {
    ${({ theme }) =>
      fluidValue(
        "font-size",
        56,
        160,
        theme.breakpoints.mobile,
        theme.sizes.containerWidth
      )}
    font-weight: 200;
    margin: 0;
    line-height: 1;
  }
`;

const ScreenReaderOnly = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;

export const fragment = `
  fragment StatsWithTransitionBlock on Page_Contentblocks_content_Section_Blocks_StatsWithTransition {
    fieldGroupName
    entry {
      label
      value
    }
    video {
      mediaItemUrl
    }
  }
`;

const statsMotion = {
  hidden: ({ distance = 20 } = {}) => ({
    opacity: 0,
    y: distance * -1,
  }),
  visible: ({ delay = 0 } = {}) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay,
    },
  }),
  exit: {
    opacity: 0,
  },
};

export default function Stats(props) {
  const { entry = [], video } = props;
  const [index, setIndex] = useState(0);
  const currentEntry = entry[index];
  const { label, value } = currentEntry;

  const videoRef = useRef();
  const prevTime = useRef(0);
  useEffect(() => {
    const video = videoRef.current;
    function onTimeUpdate(event) {
      // loop started
      if (prevTime.current > video.currentTime) {
        let newIndex = index + 1;
        if (newIndex > entry.length - 1) {
          newIndex = 0;
        }
        setIndex(newIndex);
      }
      prevTime.current = video.currentTime;
    }
    video.addEventListener("timeupdate", onTimeUpdate);
    return () => {
      video.removeEventListener("timeupdate", onTimeUpdate);
    };
  }, [index, prevTime]);

  return (
    <BlockLayout>
      <div>
        <StatsGroup>
          {entry.map(({}, index) => (
            <div
              key={`stat_${index}`}
              role="group"
              aria-labelledby={`stat_description_${index} stat_value_${index} stat_label_${index}`}
            >
              <ScreenReaderOnly aria-hidden id={`stat_description_${index}`}>
                Statistics {index + 1} of {entry.length},
              </ScreenReaderOnly>
            </div>
          ))}
          <StyledStat>
            <AnimatePresence exitBeforeEnter>
              <motion.span
                key={`label_${index}`}
                id={`stat_label_${index}`}
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={statsMotion}
                aria-hidden="true"
              >
                {label}
              </motion.span>
              <motion.p
                key={`value_${index}`}
                id={`stat_value_${index}`}
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={statsMotion}
                custom={{ distance: 50, delay: 0.35 }}
                aria-hidden="true"
              >
                {value}
              </motion.p>
            </AnimatePresence>
          </StyledStat>
        </StatsGroup>
        {video && (
          <VideoWrapper>
            <video
              ref={videoRef}
              src={video?.mediaItemUrl}
              autoPlay
              disablePictureInPicture
              loop
              muted
              playsInline
            />
          </VideoWrapper>
        )}
      </div>
    </BlockLayout>
  );
}
