import styled, { css } from "styled-components";
import { darken, rem } from "polished";
import PropTypes from "prop-types";
import ButtonBlank from "@/components/ButtonBlank";
import { hoverUnderline } from "@/styles/helpers";

const appearanceStyles = ({ appearance, theme }) => {
  const darkTheme = theme.appearance === "dark";

  let backgroundColor = theme.colors.yellow;
  let color = theme.colors.offsetBlack;
  let borderColor = theme.colors.grey1;

  switch (appearance) {
    case "tertiary":
      borderColor = darkTheme ? theme.colors.grey2 : theme.colors.grey1;
      color = darkTheme ? theme.colors.white : theme.colors.offsetBlack;
      return css`
        background-color: transparent;
        border-color: ${borderColor};
        color: ${color};
      `;

    case "secondary":
      backgroundColor = theme.colors.grey1;
      color = theme.colors.white;
      // borderColor = darkTheme
      //   ? theme.colors.grey1
      //   : theme.colors.offsetBlack;
      return css`
        background-color: ${backgroundColor};
        border-color: ${backgroundColor};

        /* specificity */
        &&&,
        &&&:hover {
          color: ${color};
        }
      `;

    case "primary":
    default:
      return css`
        background-color: ${backgroundColor};
        border-color: ${backgroundColor}; /* ${darken(0.2, backgroundColor)}; */
        color: ${color};

        &:disabled {
          background-color: ${theme.colors.grey2};
          border-color: ${theme.colors.grey2};
          color: ${theme.colors.white};
        }
      `;

    case "filledOnDark":
      return css`
        background-color: ${theme.colors.white};
        border-color: ${theme.colors.white};
        color: ${theme.colors.offsetBlack};
      `;

    case "borderedOnDark":
      return css`
        background-color: transparent;
        border-color: ${theme.colors.white};
        color: ${theme.colors.white};
      `;

    case "filledOnLight":
      return css`
        background-color: ${theme.colors.grey5};
        border-color: ${theme.colors.grey5};
        color: ${theme.colors.grey1};
      `;
  }
};

const StyledButton = styled(ButtonBlank)`
  font-size: ${({ theme }) => theme.fonts.smallBody.fontSize};
  font-weight: ${({ theme }) => theme.fonts.smallBody.fontWeight};
  line-height: ${({ theme }) => theme.fonts.smallBody.lineHeight};
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.yellow};
  border-radius: ${rem(200)};
  border-style: solid;
  border-width: 2px;
  min-width: ${rem(100)};
  padding: ${rem(16)};
  text-decoration: none;

  ${hoverUnderline()}

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  ${({ largeFont }) =>
    largeFont &&
    css`
      height: ${rem(56)};
      padding-top: 0;
      padding-bottom: 0;
      font-size: ${({ theme }) => theme.fonts.body.fontSize};
      font-weight: ${({ theme }) => theme.fonts.body.fontWeight};
      line-height: ${({ theme }) => theme.fonts.body.lineHeight};
    `};

  ${({ compact }) =>
    compact &&
    css`
      padding: ${rem(12)} ${rem(10)};
    `};

  ${({ largePadding }) =>
    largePadding &&
    css`
      border-width: 1px;
      padding: ${rem(19)} ${rem(19)};
    `};

  ${appearanceStyles};

  @media ${({ theme }) => theme.devices.mobile} {
    ${({ largeFont }) =>
      largeFont &&
      css`
        height: ${rem(48)};
        font-size: ${({ theme }) => theme.fontsMobile.smallBody.fontSize};
        font-weight: ${({ theme }) => theme.fontsMobile.smallBody.fontWeight};
        line-height: ${({ theme }) => theme.fontsMobile.smallBody.lineHeight};
      `};
  }
`;

Button.propTypes = {
  appearance: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "filledOnDark",
    "borderedOnDark",
    "filledOnLight",
  ]),
};

export default function Button({
  as,
  href,
  title,
  compact,
  largeFont,
  largePadding,
  disabled,
  appearance = "primary",
  linkProps = {},
  onClick,
  children,
  className,
  style,
  type,
  testName,
  id,
}) {
  const defaultLinkProps =
    as === "a"
      ? {
          href,
          target: "_blank",
          rel: "noopenner noreferrer",
          ...linkProps,
        }
      : {
          ...linkProps,
        };
  return (
    <StyledButton
      largeFont={largeFont}
      as={as}
      title={title}
      compact={compact}
      largePadding={largePadding}
      disabled={disabled}
      appearance={appearance}
      onClick={onClick}
      className={className}
      style={style}
      type={type}
      data-testid={testName}
      {...defaultLinkProps}
      id={id}
    >
      {children}
    </StyledButton>
  );
}
