import styled from "styled-components";
import { rem } from "polished";
import Section from "@/components/Section";
import Text from "@/components/Text";
import useMenuItems from "@/hooks/useMenuItems";
import Nav from "@/components/Nav";
import Link from "@/components/Link";
import Message from "@/components/Message";

const Layout = styled.div`
  nav {
    margin-top: ${rem(40)};
  }

  @media ${({ theme }) => theme.devices.tablet} {
    display: grid;
    grid-template-columns: 58fr 76fr;
    grid-column-gap: ${rem(40)};

    nav {
      margin-top: 0;
    }
  }
`;

export default function ErrorFallback({ error, resetErrorBoundary }) {
  const navItems = useMenuItems("Tertiary menu");
  return (
    <>
      <Section>
        <h1 style={{ margin: 0 }}>
          <Message
            id="something_went_wrong"
            fallbackText="Something went wrong"
          />
        </h1>
        <Text light>
          <pre>{error.message}</pre>
        </Text>

        <Link onClick={resetErrorBoundary} to="/">
          <Message id="aria_goto_home" fallbackText="Go to home page" />
        </Link>
      </Section>

      <Section>
        <Layout>
          <Nav items={navItems} vertical />
        </Layout>
      </Section>
    </>
  );
}
