import styled from "styled-components";
import { rem } from "polished";
import SocialIcon from "@/components/SocialIcon";

const StyledNav = styled.div`
  ul {
    display: flex;
    margin: ${rem(20)} 0 0 0;
  }

  li {
    padding: 0;
  }

  li + li {
    margin-left: ${rem(24)};
  }

  a {
    color: ${({ theme }) =>
      theme.appearance === "dark"
        ? theme.colors.grey3
        : theme.colors.offsetBlack};

    &:hover {
      opacity: 0.8;
    }
  }
`;

export default function SocialLinks({ items, large }) {
  return (
    <StyledNav>
      <ul>
        {items.map(({ url, label, id }) => (
          <li key={id}>
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              title={label}
            >
              <SocialIcon id={label} large={large} />
            </a>
          </li>
        ))}
      </ul>
    </StyledNav>
  );
}
