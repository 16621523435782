import styled from "styled-components";
import { rem } from "polished";
import useMenuItems from "@/hooks/useMenuItems";
import Nav from "@/components/Nav";
import Message from "@/components/Message";
import { hoverUnderline } from "@/styles/helpers";

export const fragment = `
  fragment SocialBlock on Page_Contentblocks_content_Section_Blocks_SocialBlock {
    fieldGroupName
  }
`;

const SocialGroup = styled.div`
  display: grid;
  grid-row-gap: ${rem(20)};

  nav ul {
    flex-wrap: wrap;
    margin: ${rem(-8)} ${rem(-12)};

    li {
      margin: ${rem(8)} ${rem(12)};
    }

    a {
      color: ${({ theme }) => theme.colors.offsetBlack};
      text-decoration: none;

      ${hoverUnderline()}
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${rem(20)};
  }

  @media ${({ theme }) => theme.devices.laptop} {
    grid-template-columns: 28fr 20fr 40fr 28fr;

    p {
      grid-column: 1 / 3;
    }

    nav {
      grid-column: 3 / 5;
    }
  }
`;

const NavWrapper = styled(Nav)`
  ul {
    display: inline-flex;
    flex-direction: column;
    margin: 0;
    margin-top: ${rem(20)};
  }

  li {
    padding: 0;
    border-bottom: none;
  }

  li + li {
    margin-left: ${rem(24)};
  }

  a {
    border-bottom: 1px solid ${({ theme }) => theme.colors.offsetBlack};
    margin: -0.5em 0 -0.5em 0;
    padding: 0.5em 0 0 0;

    &:hover {
      text-decoration: none !important;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    ul {
      flex-direction: row;
    }
  }
`;

const renderSocialLink = (item) => {
  const { id, label, url } = item;
  return (
    <li key={id} role="presentation">
      <a href={url} role="tab" aria-roledescription="link" target="_blank" rel="noopener noreferrer" title={label}>
        {label}
      </a>
    </li>
  );
};

export default function SocialBlock({}) {
  const socialNavItems = useMenuItems("Social menu");

  return (
    <SocialGroup>
      <h3>
        <Message
          id="contacts_social_label"
          fallbackText="And can be found here"
        />
      </h3>
      <NavWrapper items={socialNavItems} render={renderSocialLink} />
    </SocialGroup>
  );
}
