import styled from "styled-components";
import { rem } from "polished";
import Block from "@/blocks/Block";
import Message from "@/components/Message";
import Typography from "@/components/Typography";

const BlockLayout = styled(Block)`
  display: grid;
  grid-row-gap: ${rem(18)};
  margin-top: ${rem(8)};

  ul {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    grid-template-columns: repeat(4, 1fr);

    /* layout: A2 (customized): 36fr 98fr */
    grid-template-columns: 36fr 30fr 30fr 34fr;

    ul {
      margin-top: ${rem(14)};
    }
  }
`;

export const fragment = `
  fragment MetaBlock on Page_Contentblocks_content_Section_Blocks_Meta {
    fieldGroupName
    
    client {
      ... on Partner {
        id
        title
      }
    }

    sector {
     name
    }

    solution {
      ... on Solution {
        id
        title
      }
    }

    ourRole {
      text
    }
  }
`;

export default function Meta(props) {
  const { client, sector, solution, ourRole } = props;
  return (
    <BlockLayout>
      {client?.title && (
        <div>
          <Typography variant="h3">
            <Message id="work-client" fallbackText="Client" />
          </Typography>
          <ul>
            <li key={client.title}>
              <Typography
                variant="body"
                colorVariant="textLight"
                render={client.title}
              />
            </li>
          </ul>
        </div>
      )}

      {sector?.name && (
        <div>
          <Typography variant="h3">
            <Message id="work-sector" fallbackText="Sector" />
          </Typography>
          <ul>
            <li key={sector.name}>
              <Typography
                variant="body"
                colorVariant="textLight"
                render={sector.name}
              />
            </li>
          </ul>
        </div>
      )}

      {solution?.title && (
        <div>
          <Typography variant="h3">
            <Message id="work-solution" fallbackText="Solution" />
          </Typography>
          <ul>
            <li>
              <Typography
                variant="body"
                colorVariant="textLight"
                render={solution.title}
              />
            </li>
          </ul>
        </div>
      )}

      {ourRole && (
        <div>
          <Typography variant="h3">
            <Message id="work-our-role" fallbackText="Our role" />
          </Typography>
          <ul>
            {ourRole.map(({ text }) => (
              <li key={text}>
                <Typography
                  variant="body"
                  colorVariant="textLight"
                  render={text}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </BlockLayout>
  );
}
