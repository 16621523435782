import { useMemo } from "react";
import useStrings from "@/hooks/useStrings";

export default function Message({ id, fallbackText, render }) {
  const message = useMessage(id, fallbackText);
  return render ? render(message) : message;
}

export function useMessage(id, fallbackText) {
  const strings = useStrings();
  // Is it optimal to use useMemo here?
  const message = useMemo(() => {
    const entry = strings.find((entry) => entry.id === id);
    if (!entry) {
      console.warn(`Functional text "${id}" not defined`);
    }
    const message = entry ? entry.string : fallbackText;
    return message;
  }, [strings, id, fallbackText]);
  return message;
}
