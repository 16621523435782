import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import styled, { css } from "styled-components";
import { rem } from "polished";
import ResponsiveImage from "@/components/ResponsiveImage";
import { hoverUnderline } from "@/styles/helpers";

const StyledCard = styled.div`
  line-height: 1.4;
  display: contents;

  > div:last-child {
    cursor: pointer;
    transition: opacity 200ms ${({ theme }) => theme.easing.standard};
    will-change: opacity;

    &:hover {
      opacity: 0.9;
    }
  }
`;

const CardImageWrapper = styled.a`
  > div:first-child {
    width: 100%;
    height: 100%;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    margin-bottom: ${rem(20)};
  }

  @media ${({ theme }) => theme.devices.mobile} {
    padding: 0;
    margin-left: ${rem(-10)};
    margin-right: ${rem(-10)};
  }

  @media ${({ theme }) => theme.devices.tablet} {
    ${({ row, column }) =>
      row != null &&
      column != null &&
      css`
        grid-row: ${row};
        grid-column: ${column};
      `};
    ${({ emphasize }) =>
      emphasize &&
      css`
        grid-column-end: span 2;
      `};
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media ${({ theme }) => theme.devices.tablet} {
    ${({ row, column }) =>
      row != null &&
      column != null &&
      css`
        grid-row: ${row};
        grid-column: ${column};
      `};
    ${({ emphasize }) =>
      emphasize &&
      css`
        grid-column-end: span 2;
      `};
  }
`;

const Title = styled.h3`
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;

  a {
    color: ${({ theme }) => theme.colors.offsetBlack};
    text-decoration: none;
  }

  ${hoverUnderline(`
    text-decoration: none;
    a {
      text-decoration: underline;
    }
    & + p {
      color: ${({ theme }) => theme.colors.offsetBlack};
    }
  `)}
`;

const Category = styled.p`
  color: ${({ theme }) => theme.colors.grey1};
  margin-top: 0.2em;

  > span {
    display: inline-block;
  }
`;

const WorkCard = React.forwardRef((props, ref) => {
  const {
    title,
    categories,
    uri,
    featuredImage,
    workFields,
    row,
    column,
    emphasize,
  } = props;
  const router = useRouter();

  return (
    <StyledCard>
      <Header row={row * 2} column={column} emphasize={emphasize}>
        <Title>
          <Link href={uri} passHref>
            <a ref={ref} data-testid={`workcard-link-${title}`}>
              {title}
            </a>
          </Link>
        </Title>
        <Category>
          {categories.nodes.map((category, index) => {
            if (index === categories.nodes.length - 1) {
              return <span key={category.id}>{category.name}</span>;
            }
            return <span key={category.id}>{category.name + ","}</span>;
          })}
        </Category>
      </Header>
      <Link href={uri} passHref>
        <CardImageWrapper row={row * 2 + 1} column={column} tabIndex={-1}>
          <ResponsiveImage
            mobileImage={workFields?.showcaseImage?.squareImage}
            laptopImage={workFields?.showcaseImage?.wideImage}
            fallbackImage={featuredImage?.node}
            onClick={() => {
              router.push(uri);
            }}
            row={row + 1}
            column={column}
            emphasize={emphasize}
          />
        </CardImageWrapper>
      </Link>
    </StyledCard>
  );
});

WorkCard.displayName = "WorkCard";
export default WorkCard;
