import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import HtmlToReact from "html-to-react";

const parser = new HtmlToReact.Parser();

const StyledTypography = styled.p`
  line-height: 1.2;
  font-weight: 300;

  ${({ theme, colorVariant }) => css`
    color: ${colorVariant === "textOnDark"
      ? theme.colors.white
      : colorVariant === "textLight"
      ? theme.colors.grey1
      : theme.colors.offsetBlack};
  `};

  ul {
    list-style: disc;
    padding-left: 26px;
  }

  ${({ variant }) => {
    return css`
      font-size: ${({ theme }) => theme.fontsMobile[variant].fontSize};
      line-height: ${({ theme }) => theme.fontsMobile[variant].lineHeight};
      font-weight: ${({ theme }) => theme.fontsMobile[variant].fontWeight};
      text-decoration: ${({ theme }) =>
        theme.fontsMobile[variant].textDecoration};
      text-transform: ${({ theme }) =>
        theme.fontsMobile[variant].textTransform};
    `;
  }}

  @media ${({ theme }) => theme.devices.tablet} {
    ${({ variant }) => {
      return css`
        font-size: ${({ theme }) => theme.fonts[variant].fontSize};
        line-height: ${({ theme }) => theme.fonts[variant].lineHeight};
        font-weight: ${({ theme }) => theme.fonts[variant].fontWeight};
        text-decoration: ${({ theme }) => theme.fonts[variant].textDecoration};
        text-transform: ${({ theme }) => theme.fonts[variant].textTransform};
      `;
    }}
  }

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 500;
    `};

  > * {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
  }
`;

const Typography = ({
  className,
  render,
  children,
  component = "p",
  variant = "body",
  bold,
  colorVariant = "text",
  ...rest
}) => {
  const content = parser.parse(render);

  return (
    <StyledTypography
      className={className}
      as={component}
      variant={variant}
      colorVariant={colorVariant}
      bold={bold}
      {...rest}
    >
      {render ? content : children}
    </StyledTypography>
  );
};

Typography.propTypes = {
  variant: PropTypes.oneOf([
    "extraLarge",
    "h1",
    "h2",
    "h3",
    "largeBody",
    "mediumBody",
    "linkCapitalized",
    "body",
    "linkBody",
    "smallBody",
    "linkSmallBody",
    "secondaryCTA",
  ]),
  colorVariant: PropTypes.oneOf(["text", "textOnDark", "textLight"]),
};

export default Typography;
