import React from "react";
import Message from "@/components/Message";
import styled from "styled-components";
import { rem } from "polished";
import { hoverUnderline } from "@/styles/helpers";

const BackAnchor = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: ${rem(20)};
  text-decoration: none;
  font-size: ${({ theme }) => theme.fonts.linkCapitalized.fontSize};
  font-weight: ${({ theme }) => theme.fonts.linkCapitalized.fontWeight};
  line-height: ${({ theme }) => theme.fonts.linkCapitalized.lineHeight};
  text-transform: ${({ theme }) => theme.fonts.linkCapitalized.textTransform};

  @media ${({ theme }) => theme.devices.mobile} {
    font-size: ${({ theme }) => theme.fontsMobile.linkCapitalized.fontSize};
    font-weight: ${({ theme }) => theme.fontsMobile.linkCapitalized.fontWeight};
    line-height: ${({ theme }) => theme.fontsMobile.linkCapitalized.lineHeight};
    text-transform: ${({ theme }) =>
      theme.fontsMobile.linkCapitalized.textTransform};
  }

  svg {
    margin-right: ${rem(8)};
    transition: transform 200ms ${({ theme }) => theme.easing.standard};
  }

  ${hoverUnderline(`svg {
      transform: translateX(${rem(-8)});
    }`)}

  @media ${({ theme }) => theme.devices.tablet} {
    margin-bottom: 0;
  }
`;

const BackLink = React.forwardRef(
  ({ className, href, messageId, fallbackText }, ref) => {
    const handleOnClick = (e) => {
      e.preventDefault();
      window.history.back();
    };

    return (
      <BackAnchor
        ref={ref}
        className={className}
        href={href}
        onClick={href ? undefined : handleOnClick}
      >
        <svg
          width="29"
          height="13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M29 6.5H1M7 12.5l-6-6 6-6"
            stroke="currentColor"
            strokeWidth="1.4"
          />
        </svg>
        <Message id={messageId} fallbackText={fallbackText} />
      </BackAnchor>
    );
  }
);

BackLink.displayName = "BackLink";
export default BackLink;
