import styled from "styled-components";
import { rem } from "polished";
import Link from "next/link";
import Block from "@/blocks/Block";
import Text from "@/components/Text";
import Image from "@/components/Image";
import useMedia from "@/hooks/useMedia";
import theme from "@/styles/theme";
import { Fragment } from "react";

const BlockLayout = styled(Block)`
  display: inherit;
  grid-template-columns: inherit;
  grid-row-gap: ${rem(40)};
  grid-column: 1 / -1;

  > h3 {
    grid-column: 3 / 4;
    margin-bottom: 0;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    grid-column: 3 / 4;
    /* layout A2 */
    grid-template-columns: 36fr 98fr;
    grid-template-rows: 1fr auto;
    grid-row-gap: ${rem(20)};

    > h3 {
      grid-column: 1;
    }
  }
`;

const Item = styled.li`
  user-select: none;

  > * {
    display: block;
    margin: 0;
    padding: 0;

    > div {
      max-width: ${rem(80)};
      margin: 0 auto;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    filter: grayscale(1);
    transition: filter 250ms ${({ theme }) => theme.easing.standard};

    &:hover {
      filter: grayscale(0);
    }

    > * > div {
      max-width: ${rem(160)};
    }
  }
`;

const fragmentPartnerFields = `
partner {
  ... on Partner {
    id
    title
    featuredImage {
      node {
        mediaDetails {
          width
          height
        }
        src: sourceUrl
      }
    }
    partnerFields {
      link
    }
  }
}
`;

export const fragment = `
  fragment PartnersBlock on Page_Contentblocks_content_Section_Blocks_Partners {
    fieldGroupName
    label

    partners {
      ${fragmentPartnerFields}
    }

    groupByIndustry
    industries {
      industry {
        id
        name
        industryFields {
          icon {
            src: sourceUrl
            mediaDetails {
              width
              height
            }
          }
        }
      }
      partners {
        ${fragmentPartnerFields}
      }
    }
  }
`;

const StyledIndustryTag = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${rem(10)};

  > span {
    display: inline-block;
    margin-left: ${rem(40)};
  }
`;

function renderIndustryTag(industry, style) {
  const icon = industry?.industryFields?.icon;
  const width = Math.round(icon.mediaDetails.width / 3);
  const height = Math.round(icon.mediaDetails.height / 3);
  return (
    <StyledIndustryTag style={style}>
      {icon && (
        <Image
          src={icon.src}
          alt=""
          layout="responsive"
          width={width}
          height={height}
          transparent
          style={{ width: "3rem", height: "3rem" }}
        />
      )}
      <span>{industry.name}</span>
    </StyledIndustryTag>
  );
}

function renderItems(items = [], large = true) {
  return items.map(({ partner: item }) => {
    const { id, title, featuredImage, partnerFields } = item;

    if (!featuredImage) {
      return null;
    }

    const image = (
      <Image
        src={featuredImage.node.src}
        alt=""
        title={title}
        layout="responsive"
        objectFit="contain"
        width={large ? 160 : 80}
        height={large ? 40 : 20}
        sizes="320px"
        transparent
      />
    );

    return (
      <Item key={id} data-testid={`partner-${title}`}>
        {partnerFields.link ? (
          <Link href={partnerFields.link}>
            <a title={title}>{image}</a>
          </Link>
        ) : (
          image
        )}
      </Item>
    );
  });
}

const PartnersListPlainWrapper = styled.div`
  grid-column: 3 / 4;

  > span {
    display: block;
    margin-bottom: ${rem(20)};
  }
`;

const PlainList = styled.ul`
  grid-column: 3 / 4;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${rem(80)}, 1fr));
  grid-column-gap: ${rem(40)};
  grid-row-gap: ${rem(24)};
  margin: 0;

  @media ${({ theme }) => theme.devices.tablet} {
    grid-column: 2 / 4;
    grid-template-columns: repeat(auto-fill, minmax(${rem(144)}, 1fr));
    grid-column-gap: ${rem(80)};
    grid-row-gap: ${rem(40)};
    padding-bottom: ${rem(20)};
  }
`;

function PartnersListPlain({ items, style }) {
  return <PlainList style={style}>{renderItems(items, false)}</PlainList>;
}

export default function Partners(props) {
  const { label, partners, groupByIndustry, industries } = props;
  const matchTablet = useMedia(theme.devices.tablet);

  return (
    <BlockLayout includesIndustry={groupByIndustry}>
      {label && (
        <h3>
          {label}
        </h3>
      )}
      {groupByIndustry ? (
        industries.map((entry) => {
          const { industry } = entry;
          return matchTablet ? (
            <Fragment key={industry.name}>
              {renderIndustryTag(industry, { gridColumn: 2, margin: 0 })}
              <PartnersListPlain
                items={entry.partners || []}
                style={{ gridColumn: 3 }}
              />
            </Fragment>
          ) : (
            <PartnersListPlainWrapper key={industry.name}>
              <span>{industry.name}</span>
              <PartnersListPlain items={entry.partners || []} />
            </PartnersListPlainWrapper>
          );
        })
      ) : (
        <PartnersListPlain items={partners || []} />
      )}
    </BlockLayout>
  );
}
