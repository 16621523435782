import styled from "styled-components";
import Block from "@/blocks/Block";
import DetailsPanel from "@/components/DetailsPanel";
import RichText from "@/components/RichText";

const BlockLayout = styled(Block)`
  @media ${({ theme }) => theme.devices.tablet} {
    /* layout: A2 (customized): 36fr 98fr */
    grid-template-columns: 36fr 98fr;
  }
`;

export const fragment = `
  fragment DetailsBlock on Page_Contentblocks_content_Section_Blocks_Details {
    fieldGroupName
    label
    entries {
      summary
      content
    }
  }
`;

export default function Detals(props) {
  const { label, entries } = props;
  return (
    <BlockLayout>
      {label && <h3>{label}</h3>}
      <DetailsPanel
        data={entries}
        renderContent={(content) => <RichText light render={content} />}
      />
    </BlockLayout>
  );
}
