import styled from "styled-components";
import { rem } from "polished";
import Block from "@/blocks/Block";
import RichText from "@/components/RichText";

const BlockLayout = styled(Block)`
  @media ${({ theme }) => theme.devices.laptop} {
    /* layout: A2 */
    grid-template-columns: 36fr 98fr;

    > * {
      grid-column: 2;
    }

    div {
      padding-right: 0;
    }
  }
`;

export const fragment = `
  fragment ExcerptBlock on Page_Contentblocks_content_Section_Blocks_Excerpt {
    fieldGroupName
    text
  }
`;

export default function Excerpt({ text }) {
  return (
    text && (
      <BlockLayout>
        <RichText render={text} />
      </BlockLayout>
    )
  );
}
