import styled, { css, ThemeProvider } from "styled-components";
import { rem } from "polished";
import theme from "@/styles/theme";

const setAppearance = ({ appearance, theme }) => {
  switch (appearance) {
    case "dark":
      return css`
        background-color: ${theme.colors.offsetBlack};
        color: ${theme.colors.white};

        *::selection {
          color: ${theme.colors.offsetBlack};
          background-color: ${theme.colors.white};
        }

        /* specificity */
        a[href] {
          color: ${theme.colors.white};

          &:hover {
            color: ${theme.colors.white};
          }
        }
      `;
    case "gray":
      return css`
        background-color: ${theme.colors.grey5};
      `;
    case "yellow":
      return css`
        background-color: ${theme.colors.yellow};
        color: ${theme.colors.offsetBlack};
      `;

    default:
      return;
  }
};

const StyledSection = styled.section`
  ${setAppearance};

  grid-column: 3/4;
  grid-row-gap: ${rem(24)};

  margin-top: ${rem(38)};
  &:not(:last-of-type) {
    margin-bottom: ${rem(64)};
  }

  &:first-of-type:not(:first-child) {
    margin-top: ${rem(40)};
  }

  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  ${({ appearance }) =>
    ["dark", "gray"].includes(appearance) &&
    css`
      padding-top: ${rem(64)};
      padding-bottom: ${rem(64)};

      div + &&& {
        margin-top: 0;
      }
    `};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      display: inherit;
      grid-template-columns: inherit;
      grid-column: 1/-1;

      > * {
        grid-column: 3/4;
      }
    `};

  @media ${({ theme }) => theme.devices.tablet} {
    grid-row-gap: ${rem(80)};

    margin-top: ${rem(77)};
    &:not(:last-of-type) {
      margin-bottom: ${rem(106)};
    }

    &:first-of-type:not(:first-child) {
      margin-top: ${rem(80)};
    }

    ${({ appearance }) =>
      ["dark", "gray"].includes(appearance) &&
      css`
        padding-top: ${rem(80)};
        padding-bottom: ${rem(80)};
      `};
  }
`;

export default function Section({
  appearance,
  fullWidth,
  children,
  className,
  style,
}) {
  return (
    <StyledSection
      appearance={appearance}
      fullWidth={fullWidth}
      className={className}
      style={style}
    >
      <ThemeProvider theme={{ ...theme, appearance }}>{children}</ThemeProvider>
    </StyledSection>
  );
}
