import { useMemo } from "react";
import { useRouter } from "next/router";
import ChipGroup from "@/components/ChipGroup";

// TODO: Render as links with Chip appearance

export default function ChipGroupSolutions({ solutions, isSolutionPost = false }) {
  const router = useRouter();
  const items = useMemo(
    () =>
      solutions.map((solution) => ({
        id: solution.slug,
        label: solution.title,
      })),
    [solutions]
  );

  const current =
    isSolutionPost &&
    solutions.find((solution) => {
      let uri = solution.uri;
      if (uri.endsWith("/")) {
        uri = uri.substr(0, uri.length - 1);
      }
      return uri === router.asPath;
    });

  return (
    <ChipGroup
      items={items}
      current={current && current.slug}
      onClick={(id) => {
        const solution = solutions.find((solution) => solution.slug === id);
        if (solution) {
          router.push(solution.uri);
        }
      }}
    />
  );
}
