import FeaturedImage from "@/components/FeaturedImage";
import { renderSection, BlockContext } from "@/lib/template";

export default function DefaultPageTemplate({
  page,
  postType,
  pageTemplate,
  solutions,
  solutionsUri,
  works,
}) {
  const { featuredImage } = page;
  const sections = page?.contentBlocks?.content ?? [];

  return (
    <>
      {featuredImage && <FeaturedImage fallbackImage={featuredImage.node} />}
      <BlockContext.Provider
        value={{ postType, pageTemplate, solutions, solutionsUri, works }}
      >
        {sections.map(renderSection)}
      </BlockContext.Provider>
    </>
  );
}
