import { useMemo, useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { rem } from "polished";
import WorkShowcase from "@/blocks/WorkShowcase";
import ButtonBlank from "@/components/ButtonBlank";
import Message from "@/components/Message";
import { useBlockContext } from "@/lib/template";
import useMedia from "@/hooks/useMedia";
import theme from "@/styles/theme";

export const fragment = `
  fragment WorkByCategoryBlock on Page_Contentblocks_content_Section_Blocks_WorkByCategory {
    fieldGroupName
    prioritizedWork
  }
`;

const StyledLoadMoreButton = styled(ButtonBlank)`
  font-size: ${({ theme }) => theme.fonts.linkCapitalized.fontSize};
  font-weight: ${({ theme }) => theme.fonts.linkCapitalized.fontWeight};
  line-height: ${({ theme }) => theme.fonts.linkCapitalized.lineHeight};
  text-transform: ${({ theme }) => theme.fonts.linkCapitalized.textTransform};
  color: ${({ theme }) => theme.colors.grey1};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: ${rem(10)};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.offsetBlack};
  }

  @media ${({ theme }) => theme.devices.mobile} {
    font-size: ${({ theme }) => theme.fontsMobile.linkCapitalized.fontSize};
    font-weight: ${({ theme }) => theme.fontsMobile.linkCapitalized.fontWeight};
    line-height: ${({ theme }) => theme.fontsMobile.linkCapitalized.lineHeight};
    text-transform: ${({ theme }) =>
      theme.fontsMobile.linkCapitalized.textTransform};
  }
`;

function LoadMore({ onClick }) {
  const largerThanMobile = useMedia(theme.devices.tablet);

  return (
    <StyledLoadMoreButton onClick={onClick}>
      <Message id="load_more" fallbackText="Load more" />
      {largerThanMobile && (
        <svg
          width="16"
          height="16"
          fill="none"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: rem(16), height: rem(16) }}
        >
          <path
            d="M7.406 2.375v9.142l-4.75-5.472a.124.124 0 00-.219.082v1.382c0 .061.022.12.061.164l5.124 5.905a.499.499 0 00.755 0L13.53 7.64a.123.123 0 00.031-.081v-1.43a.124.124 0 00-.218-.081l-4.75 5.47V2.375a.125.125 0 00-.125-.125H7.53a.125.125 0 00-.125.125z"
            fill="currentColor"
          />
        </svg>
      )}
    </StyledLoadMoreButton>
  );
}

export default function WorkByCategory(props) {
  const { prioritizedWork } = props;
  const { categories, category, works } = useBlockContext();

  const mapWorkIdsToObject = (id) => {
    return works.nodes.find((node) => node?.id === id);
  };

  const work = useMemo(() => {
    if (category) {
      const currentCategory = categories?.nodes?.find(
        (node) => node.slug === category
      );

      let result = currentCategory?.works?.nodes.map((node) =>
        mapWorkIdsToObject(node.id)
      );

      works?.nodes.forEach((w) => {
        const isCurrent = w.categories.nodes.findIndex(
          (c) => c.id === currentCategory.id
        );
        if (isCurrent === -1) {
          result.push(w);
        }
      });

      return result;
    }
    return works.nodes;
  }, [prioritizedWork, category, works]);

  const extendLayout = work.length > 6;
  const [showCount, setShowCount] = useState(extendLayout ? 6 : work.length);
  const moreWork = showCount < work.length;
  const [loadMoreClicked, setLoadMoreClicked] = useState(false);
  const lastCardRef = useRef(null);

  useEffect(() => {
    if (lastCardRef.current) {
      lastCardRef.current.focus();
    }
  }, [showCount]);

  return (
    work?.length > 0 && (
      <>
        <WorkShowcase
          work={work.slice(0, showCount)}
          emphasize={prioritizedWork}
          showCount={showCount}
          lastCardRef={lastCardRef}
          loadMoreClicked={loadMoreClicked}
        />
        {moreWork && (
          <LoadMore
            onClick={() => {
              setShowCount((showCount) => showCount + 3);
              setLoadMoreClicked(true);
            }}
          />
        )}
      </>
    )
  );
}
