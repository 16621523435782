import {
  createGlobalStyle,
  ThemeProvider as StyledThemeProvider,
} from "styled-components";
import defaultTheme from "@/styles/theme";
import { fluidValue } from "@/styles/helpers";

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  *:focus {
    outline: 2px solid ${defaultTheme.colors.yellow};
    outline-offset: 4px;

    &:not(:focus-visible) {
      outline: none;
    }
  }

  :root {
    --font-size-base: ${defaultTheme.fontsMobile.body.fontSize};

    ${fluidValue(
      "--container-padding-inline",
      10,
      80,
      defaultTheme.breakpoints.mobile,
      defaultTheme.sizes.containerWidth
    )};
  }

  ::selection {
    color: ${defaultTheme.colors.white};
    background-color: ${defaultTheme.colors.offsetBlack};
  }

  html {
    scroll-behavior: smooth;
    scrollbar-gutter: stable;
  }

  body {
    color: ${defaultTheme.colors.offsetBlack};
    font-family: "Public Sans", sans-serif;
    font-size: var(--font-size-base);
    font-weight: ${defaultTheme.fontsMobile.body.fontWeight};
    margin: 0;

    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;

    overflow-x: hidden;
  }

  button {
    font-family: "Public Sans", sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0.6em;
    margin-bottom: 1.4em;
  }

  h4, h5, h6 {
    font-weight: 400;
    line-height: 1.2;
  }
  
  h1 {
    font-size: ${defaultTheme.fontsMobile.h1.fontSize};
    font-weight: ${defaultTheme.fontsMobile.h1.fontWeight};
    line-height: ${defaultTheme.fontsMobile.h1.lineHeight};
  }

  h2 {
    font-size: ${defaultTheme.fontsMobile.h2.fontSize};
    font-weight: ${defaultTheme.fontsMobile.h2.fontWeight};
    line-height: ${defaultTheme.fontsMobile.h2.lineHeight};
  }

  h3 {
    font-size: ${defaultTheme.fontsMobile.h3.fontSize};
    font-weight: ${defaultTheme.fontsMobile.h3.fontWeight};
    line-height: ${defaultTheme.fontsMobile.h3.lineHeight};
  }
  
  p, label {
    font-size: ${defaultTheme.fontsMobile.body.fontSize};
    font-weight: ${defaultTheme.fontsMobile.body.fontWeight};
    line-height: ${defaultTheme.fontsMobile.body.lineHeight};
    margin-top: 0;
    margin-bottom: 0;
  }
  
   a {
    color: ${defaultTheme.colors.grey1};
    font-size: ${defaultTheme.fontsMobile.linkBody.fontSize};
    font-weight: ${defaultTheme.fontsMobile.linkBody.fontWeight};
    line-height: ${defaultTheme.fontsMobile.linkBody.lineHeight};
    display: inline-flex;
    align-items: center;
    margin: -0.5em;
    padding: 0.5em;

    &:hover {
      color: ${defaultTheme.colors.offsetBlack};
    }
  }
   
  img,
  svg {
    display: block;
    
  }

  img {
    max-width: 100%;

    ::selection {
      background-color: ${defaultTheme.colors.yellow};
    }
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  @media ${defaultTheme.devices.tablet} {
    :root {
      --font-size-base: ${defaultTheme.fonts.body.fontSize};
    }
    
    body {
      font-weight: ${defaultTheme.fonts.body.fontWeight};
    }
    
    h1 {
      font-size: ${defaultTheme.fonts.h1.fontSize};
      font-weight: ${defaultTheme.fonts.h1.fontWeight};
      line-height: ${defaultTheme.fonts.h1.lineHeight};
    }
  
    h2 {
      font-size: ${defaultTheme.fonts.h2.fontSize};
      font-weight: ${defaultTheme.fonts.h2.fontWeight};
      line-height: ${defaultTheme.fonts.h2.lineHeight};
    }
  
    h3 {
      font-size: ${defaultTheme.fonts.h3.fontSize};
      font-weight: ${defaultTheme.fonts.h3.fontWeight};
      line-height: ${defaultTheme.fonts.h3.lineHeight};
    }

    p, label {
      font-size: ${defaultTheme.fonts.body.fontSize};
      font-weight: ${defaultTheme.fonts.body.fontWeight};
      line-height: ${defaultTheme.fonts.body.lineHeight};
    }
    
    a {
      font-size: ${defaultTheme.fonts.linkBody.fontSize};
      font-weight: ${defaultTheme.fonts.linkBody.fontWeight};
      line-height: ${defaultTheme.fonts.linkBody.lineHeight};
    }
  }
`;

export default function ThemeProvider({ children }) {
  return (
    <StyledThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      {children}
    </StyledThemeProvider>
  );
}
