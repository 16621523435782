import { useEffect, useMemo } from "react";
import { useMessage } from "@/components/Message";
import { useBlockContext } from "@/lib/template";
import styled from "styled-components";
import { rem } from "polished";
import Select from "@/components/Select";

export const fragment = `
  fragment CategoriesBlock on Page_Contentblocks_content_Section_Blocks_Categories {
    fieldGroupName
    label
    taxonomy {
      id
      name
      slug
    }
  }
`;

const BlockLayout = styled.div`
  @media ${({ theme }) => theme.devices.tablet} {
    margin-bottom: ${rem(-56)};
  }
`;

export default function Categories(props) {
  const { taxonomy = [] } = props;
  const { category, setCategory, categories } = useBlockContext();
  const viewAllMessage = useMessage("view_all", "View all");

  useEffect(() => {
    const param = location.hash.replace("#", "");
    categories.nodes.map((category) => {
      if (category.slug === param) {
        setCategory(param);
      }
    });
  }, [categories, setCategory]);

  useEffect(() => {
    const param = location.hash.replace("#", "");
    if (param && !category) {
      location.replace('#all');
    } else if (category) {
      location.replace(`#${category }`);
    }
  }, [category]);

  const items = useMemo(() => {
    let items = categories.nodes
      // filter used to sort related taxonomies by term order
      .filter((node) => taxonomy.some((taxonomy) => taxonomy.id === node.id))
      .map(
        (taxonomy) =>
          taxonomy && {
            id: taxonomy.slug,
            label: taxonomy.name,
          }
      )
      .filter((taxonomy) => taxonomy !== null);
    items.unshift({ id: "all", label: viewAllMessage });
    return items;
  }, [taxonomy, categories, viewAllMessage]);

  return (
    <BlockLayout>
      <Select
        testName="our-work-category-select"
        items={items}
        current={category === null ? "all" : category}
        onClick={(id) => {
          setCategory(id === "all" ? null : id);
        }}
      />
    </BlockLayout>
  );
}
