import styled from "styled-components";
import { rem } from "polished";

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: ${rem(16)};
`;

const StyledCheckbox = styled.input`
  flex-shrink: 0;
  appearance: none;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.grey2};
  border-radius: 2px;
  width: ${rem(24)};
  height: ${rem(24)};
  margin: 0;
  cursor: pointer;

  &:checked {
    position: relative;

    &::after {
      content: "";
      background-color: ${({ theme }) => theme.colors.grey1};
      width: ${rem(16)};
      height: ${rem(16)};
      position: absolute;
      top: ${rem(3)};
      left: ${rem(3)};
      pointer-events: none;
    }
  }

  & + label {
    color: ${({ theme }) => theme.colors.grey2};
    margin-left: ${rem(10)};

    a {
      text-transform: none;
      font-size: inherit;
      line-height: inherit;

      span {
        font-size: inherit;
        line-height: inherit;
      }

      svg {
        display: none;
      }
    }
  }

  @media ${({ theme }) => theme.devices.mobile} {
    & + label {
      font-size: ${({ theme }) => theme.fontsMobile.extraSmallBody.fontSize};
      font-weight: ${({ theme }) =>
        theme.fontsMobile.extraSmallBody.fontWeight};
      line-height: ${({ theme }) =>
        theme.fontsMobile.extraSmallBody.lineHeight};
    }
  }
`;

export default function Checkbox({ renderLabel = () => {}, ...props }) {
  return (
    <Wrapper>
      <StyledCheckbox type="checkbox" {...props} />
      {renderLabel()}
    </Wrapper>
  );
}
