import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { em } from "polished";
import Header from "@/components/Header";
import Footer from "@/blocks/Footer";
import DialogCookies from "@/components/DialogCookies";
import MobileNav from "@/components/MobileNav";
import DialogScheduleCall from "@/components/DialogScheduleCall";
import { DialogContext } from "@/hooks/useDialogContext";
import { MobileNavContext } from "@/hooks/useMobileNavContext";

const StyledLayout = styled.div`
  display: grid;
  grid-template-columns: ${({ theme }) =>
    `1fr var(--container-padding-inline) minmax(0, ${em(
      theme.sizes.containerWidth
    )}) var(--container-padding-inline) 1fr`};
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;

  > main {
    display: inherit;
    grid-template-columns: inherit;
    grid-column: 1/-1;
    align-content: start;
  }

  ${({ hideFooter }) =>
    hideFooter &&
    css`
      > main {
        display: flex;
        flex-direction: column;
      }
    `};

  /* TODO: Limit max width and use light grey background on sides */
  @media ${({ theme }) => theme.devices.wide} {
  }
`;

export default function Layout({ hideFooter, children }) {
  const scheduleCallState = useState(false);
  const [scheduleCallOpen, setScheduleCallOpen] = scheduleCallState;

  const mobileNavState = useState(false);
  const [mobileNavOpen, setMobileNavOpen] = mobileNavState;

  useEffect(() => {
    if (scheduleCallOpen || mobileNavOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [scheduleCallOpen, mobileNavOpen]);

  return (
    <DialogContext.Provider value={scheduleCallState}>
      <MobileNavContext.Provider value={mobileNavState}>
        <StyledLayout hideFooter={hideFooter}>
          <Header />
          <main>{children}</main>
          {!hideFooter && <Footer />}

          <DialogCookies />

          {scheduleCallOpen && (
            <DialogScheduleCall
              onClose={() => {
                setScheduleCallOpen(false);
              }}
            />
          )}

          {mobileNavOpen && (
            <MobileNav
              onClose={() => {
                setMobileNavOpen(false);
              }}
            />
          )}
        </StyledLayout>
      </MobileNavContext.Provider>
    </DialogContext.Provider>
  );
}
