import React from "react";
import styled from "styled-components";
import { rem } from "polished";

const StyledBlock = styled.div`
  grid-column: 3/4;

  @media ${({ theme }) => theme.devices.tablet} {
    display: grid;
    grid-column-gap: ${rem(20)};
    grid-row-gap: ${rem(10)};
  }

  @media ${({ theme }) => theme.devices.laptop} {
    grid-row-gap: ${rem(40)};
  }
`;

// TODO: Implement layout prop with different layouts instead of Grid component

const Block = React.forwardRef(({ children, className, style }, ref) => {
  return (
    <StyledBlock ref={ref} className={className} style={style}>
      {children}
    </StyledBlock>
  );
});

Block.displayName = "Block";
export default Block;
