import { useRef, useState } from "react";
import styled, { css } from "styled-components";
import { rem } from "polished";
import { AnimatePresence, motion } from "framer-motion";
import ButtonBlank from "@/components/ButtonBlank";
import Line from "@/components/Line";
import theme from "@/styles/theme";

const Wrapper = styled.div`
  @media ${({ theme }) => theme.devices.tablet} {
    /* summary button padding */
    margin-top: ${rem(-20)};

    max-width: ${rem(700)};
  }
`;

const StyledDetails = styled.div``;

const StyledSummary = styled.div`
  user-select: none;
`;

const StyledButton = styled(ButtonBlank)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${rem(20)} 0;
  width: 100%;
  text-align: left;
  font-size: ${({ theme }) => theme.fontsMobile.body.fontSize};
  font-weight: ${({ theme }) => theme.fontsMobile.body.fontWeight};
  line-height: ${({ theme }) => theme.fontsMobile.body.lineHeight};
  transition-property: color, opacity;
  transition-duration: 100ms;
  transition-timing-function: ${({ theme }) => theme.easing.standard};

  &:hover {
    opacity: 0.8;
  }

  svg {
    flex-shrink: 0;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    font-size: ${({ theme }) => theme.fonts.body.fontSize};
    font-weight: ${({ theme }) => theme.fonts.body.fontWeight};
    line-height: ${({ theme }) => theme.fonts.body.lineHeight};
  }

  ${({ theme }) =>
    theme.appearance === "dark" &&
    css`
      color: ${theme.colors.white};
    `};
`;

const Content = styled(motion.div)`
  overflow: hidden;
`;

const contentMotion = {
  collapsed: {
    height: 0,
    opacity: 0,
    transition: {
      duration: 0.15,
    },
  },
  open: {
    height: "auto",
    opacity: 1,
    ...theme.motion.standard,
  },
};

const Padding = styled.div`
  padding: ${rem(20)} 0;

  @media ${({ theme }) => theme.devices.laptop} {
    margin-right: ${rem(24)};
    padding: ${rem(20)} ${rem(40)} ${rem(20)} 0;

    > div {
      padding-right: 0;
    }
  }
`;

const arrowMotion = {
  up: {
    rotate: 270,
    ...theme.motion.standard,
  },
  down: {
    rotate: 90,
    ...theme.motion.standard,
  },
};

function Arrow({ up }) {
  return (
    <motion.svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      focusable="false"
      initial="down"
      animate={up ? "up" : "down"}
      variants={arrowMotion}
    >
      <path
        d="m16.869 5.69-1.06 1.06 4.5 4.5H.75v1.5h19.558l-4.5 4.5 1.061 1.06 6.31-6.31-6.31-6.31Z"
        fill="currentColor"
      />
    </motion.svg>
  );
}

export default function DetailsPanel({
  data,
  initialOpenIndex = -1,
  closeBeforeOpen = true,
  onChange = () => {},
  renderContent = (content) => content,
}) {
  const [open, setOpen] = useState([initialOpenIndex]);
  const wrapperRef = useRef();

  return (
    <Wrapper ref={wrapperRef}>
      {data.map((entry, index) => {
        const { id, summary, content } = entry;
        const isOpen = open.includes(index);
        const elementKey = id || summary;
        return (
          <StyledDetails key={`details_${elementKey}`}>
            <StyledSummary>
              <StyledButton
                role="button"
                tabIndex={0}
                aria-controls={`content_${elementKey}`}
                aria-expanded={isOpen}
                onClick={() => {
                  if (isOpen) {
                    setOpen(open.filter((i) => i !== index));
                  } else {
                    if (closeBeforeOpen) {
                      setOpen([index]);
                    } else {
                      setOpen([...open, index]);
                    }
                  }
                  onChange(index);
                }}
              >
                {summary}
                <Arrow up={isOpen} />
              </StyledButton>
              <Line highlight={isOpen} withTransition />
            </StyledSummary>

            <AnimatePresence initial={true}>
              {isOpen && (
                <Content
                  key={`content_${elementKey}`}
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={contentMotion}
                >
                  <Padding>{renderContent(content)}</Padding>
                </Content>
              )}
            </AnimatePresence>
          </StyledDetails>
        );
      })}
    </Wrapper>
  );
}
