import styled from "styled-components";
import { rem } from "polished";
import Message from "@/components/Message";
import Person from "@/blocks/People/Person";
import Block from "@/blocks/Block";

export const fragment = `
  fragment PeopleBlock on Page_Contentblocks_content_Section_Blocks_People {
    fieldGroupName
    people {
        name
        position
        image {
          src: sourceUrl
          slt: altText
          mediaDetails {
            width
            height
          }
        }
        links {
          link
          text
        }
      }
  }
`;

const PersonsSection = styled(Block)`
  display: grid;
  grid-row-gap: ${rem(40)};

  @media ${({ theme }) => theme.devices.tablet} {
    grid-template-columns: 28fr 100fr;
    grid-column-gap: ${rem(20)};
  }
`;

export default function People({ people = [] }) {
  return (
    <PersonsSection>
      <h3>
        <Message id="contacts_people_label" fallbackText="Hit us up directly" />
      </h3>
      <ul>
        {people.map((person) => (
          <Person key={person.name} {...person} />
        ))}
      </ul>
    </PersonsSection>
  );
}
