import { useRef, useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { rem, rgba } from "polished";
import { AnimatePresence, motion } from "framer-motion";
import Block from "@/blocks/Block";
import ButtonBlank from "@/components/ButtonBlank";
import RichText from "@/components/RichText";
import Message from "@/components/Message";
import Pagination from "@/components/Pagination";
import useMedia from "@/hooks/useMedia";

const BlockLayout = styled(Block)`
  grid-row-gap: ${rem(20)};
  display: flex;
  flex-direction: column;
`;

const TopGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EntriesWrapper = styled.div`
  overflow-x: auto;
  position: relative;
  width: 100%;
  display: flex;

  &::after {
    content: "";
    background: ${({ theme }) =>
      `linear-gradient(90deg, ${rgba(
        theme.appearance === "dark"
          ? theme.colors.offsetBlack
          : theme.colors.white,
        0
      )} 0%, ${rgba(
        theme.appearance === "dark"
          ? theme.colors.offsetBlack
          : theme.colors.white,
        1
      )} 100%)`};
    width: ${rem(32)};
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }

  &::before {
    content: "";
    background: ${({ theme }) =>
      `linear-gradient(270deg, ${rgba(
        theme.appearance === "dark"
          ? theme.colors.offsetBlack
          : theme.colors.white,
        0
      )} 0%, ${rgba(
        theme.appearance === "dark"
          ? theme.colors.offsetBlack
          : theme.colors.white,
        1
      )} 100%)`};
    width: ${rem(32)};
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const EntriesList = styled.ul`
  display: flex;
  margin: 0;
  overflow-x: auto;
  width: 100%;
  padding: 0 ${rem(16)};
  justify-content: space-between;

  @media ${({ theme }) => theme.devices.laptop} {
    padding: 0 ${rem(48)};
  }

  ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
`;

const EntryItem = styled.li`
  display: flex;
  flex-wrap: wrap;
  align-content: center;

  button {
    color: ${({ theme }) =>
      theme.appearance === "dark" ? theme.colors.grey2 : theme.colors.grey1};
    font-size: ${rem(17)};
    font-weight: 400;

    @media ${({ theme }) => theme.devices.laptop} {
      font-size: ${rem(19)};
    }

    &:hover {
      color: ${({ theme }) =>
        theme.appearance === "dark"
          ? theme.colors.white
          : theme.colors.offsetBlack};
    }
  }

  ${({ current }) =>
    current &&
    css`
      button {
        color: ${({ theme }) => theme.colors.offsetBlack};
        pointer-events: none;
        font-size: ${rem(20)};

        @media ${({ theme }) => theme.devices.laptop} {
          font-size: ${rem(28)};
        }
      }
    `};

  & + & {
    margin-left: ${rem(25)};
  }
`;

const Meta = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey1};
  color: ${({ theme }) => theme.colors.offsetBlack};
  display: flex;
  font-size: var(--font-size-base);
  margin-bottom: ${rem(20)};
  margin-top: ${rem(10)};
  padding-bottom: ${rem(10)};

  > span {
    display: flex;
    align-items: center;
    min-height: ${rem(24)};
  }

  > span + span {
    margin-left: ${rem(20)};
  }
`;

const Events = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: ${rem(40)};

  a {
    color: ${({ theme }) => theme.colors.offsetBlack};

    &:hover {
      color: ${({ theme }) => theme.colors.offsetBlack};
    }
  }

  @media ${({ theme }) => theme.devices.laptop} {
    p {
      padding-right: 0;
    }
  }
`;

const contentMotion = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

function renderMeta(meta) {
  const { type, text } = meta;
  if (text) {
    switch (type) {
      case "employees":
        return (
          <span>
            <svg
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              style={{ width: rem(24), height: rem(24) }}
            >
              <title>Company yearly employee count</title>
              <path
                d="M18 21s1.5 0 1.5-1.5-1.5-6-7.5-6-7.5 4.5-7.5 6S6 21 6 21h12zM6.033 19.5A.37.37 0 016 19.494c.002-.396.25-1.545 1.14-2.58C7.968 15.944 9.423 15 12 15c2.575 0 4.03.945 4.86 1.914.89 1.035 1.137 2.185 1.14 2.58l-.012.003a.379.379 0 01-.021.003H6.033zm5.967-9a3 3 0 100-6 3 3 0 000 6zm4.5-3a4.5 4.5 0 11-9.002 0 4.5 4.5 0 019.002 0z"
                fill="currentColor"
              />
            </svg>
            {text}
          </span>
        );
      default:
        return <span>{text}</span>;
    }
  }
  return null;
}

export const fragment = `
  fragment TimelineBlock on Page_Contentblocks_content_Section_Blocks_Timeline {
    fieldGroupName
    entries {
      year
      events {
        text
      }
      meta {
        type
        text
      }
    }
  }
`;

export default function Timeline(props) {
  const { entries = [] } = props;
  const [currentIndex, setCurrentIndex] = useState(entries.length - 1);
  const currentEntry = entries[currentIndex];
  const entriesRef = useRef(null);
  const isDesktop = useMedia("(min-width: 1025px)");

  const updateScrollPosition = () => {
    const { scrollWidth, clientWidth } = entriesRef.current;
    const itemWidth = scrollWidth / entries.length;
    const scrollLeft = itemWidth * currentIndex - (clientWidth - itemWidth) / 2;

    entriesRef.current.scrollTo({
      left: scrollLeft,
      behavior: "smooth",
    });
  };

  const handlePrevious = () => {
    const newIndex =
      currentIndex - 1 < 0 ? entries.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const handleNext = () => {
    const newIndex = currentIndex + 1 >= entries.length ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    window.addEventListener("resize", updateScrollPosition);
    return () => {
      window.removeEventListener("resize", updateScrollPosition);
    };
  }, [updateScrollPosition]);

  useEffect(() => {
    updateScrollPosition();
  }, [currentIndex, updateScrollPosition]);

  return (
    <BlockLayout>
      <TopGroup>
        <Pagination
          showNext={false}
          onPrevious={handlePrevious}
          large={isDesktop}
        />
        <EntriesWrapper>
          <EntriesList ref={entriesRef}>
            {entries.map((entry, index) => {
              const { year } = entry;
              return (
                <EntryItem key={year} current={index === currentIndex}>
                  <Message
                    id="timeline_year"
                    fallbackText="Year"
                    render={(message) => (
                      <ButtonBlank
                        title={`${message} ${year}`}
                        onClick={() => {
                          setCurrentIndex(index);
                        }}
                      >{`${year}`}</ButtonBlank>
                    )}
                  />
                </EntryItem>
              );
            })}
          </EntriesList>
        </EntriesWrapper>
        <Pagination
          showPrevious={false}
          onNext={handleNext}
          large={isDesktop}
        />
      </TopGroup>

      <div>
        <Meta>{renderMeta(currentEntry.meta)}</Meta>

        {/* TODO: Layout transition */}
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            key={currentEntry.year}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={contentMotion}
          >
            <Events>
              {currentEntry?.events?.map((event, index) => (
                <RichText
                  key={`${currentEntry.year}-event-${index}`}
                  render={event.text}
                  style={{ paddingRight: 0 }}
                />
              ))}
            </Events>
          </motion.div>
        </AnimatePresence>
      </div>
    </BlockLayout>
  );
}
