import React from "react";
import styled, { css } from "styled-components";
import Image from "@/components/Image";
import Typography from "@/components/Typography";
import ButtonBlank from "@/components/ButtonBlank";
import expand from "@/assets/icon-expand-down-grey1.svg";
import { rem } from "polished";

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1.14px;
`;

const PageNumber = styled(Typography)`
  margin-left: ${rem(16)};
  width: 24px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey1};

  ${({ isSelected }) =>
    isSelected &&
    css`
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.offsetBlack};
    `};
`;

const PaginationPages = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: ${rem(20)} ${rem(36)};
`;

const PaginationButton = styled(ButtonBlank)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.grey1};

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

const ArticlePagination = ({
  selectedPage,
  setSelectedPage,
  articleChunks,
}) => {
  return (
    <PaginationWrapper>
      <PaginationButton
        onClick={() => setSelectedPage(selectedPage - 1)}
        disabled={selectedPage === 0}
      >
        <Image
          src={expand}
          alt="Previous Page"
          width={24}
          height={24}
          layout="fixed"
          style={{
            marginRight: 16,
            backgroundColor: "transparent",
            transform: `rotate(90deg)`,
          }}
        />
        Older posts
      </PaginationButton>

      <PaginationPages>
        {selectedPage > 1 && articleChunks.length > 3 && (
          <>
            <PageNumber
              colorVariant="textLight"
              as="span"
              light
              onClick={() => setSelectedPage(0)}
            >
              1
            </PageNumber>
            <PageNumber
              colorVariant="textLight"
              as="span"
              light
              style={{ cursor: "default" }}
            >
              ...
            </PageNumber>
          </>
        )}
        {articleChunks.map((content, index) => {
          let show;
          if (selectedPage < 2) {
            // If on the first two pages, show the first three pages
            show = index < 3;
          } else if (selectedPage > articleChunks.length - 3) {
            // If on the last two pages, show the last three pages
            show = index > articleChunks.length - 4;
          } else {
            // Otherwise, show the previous, current, and next page
            show = index >= selectedPage - 1 && index <= selectedPage + 1;
          }

          if (show) {
            return (
              <PageNumber
                isSelected={index === selectedPage}
                colorVariant="textLight"
                as="span"
                light
                onClick={() => setSelectedPage(index)}
              >
                {index + 1}
              </PageNumber>
            );
          }
        })}
        {articleChunks.length > 3 && selectedPage < articleChunks.length - 2 && (
          <>
            <PageNumber
              colorVariant="textLight"
              as="span"
              light
              style={{ cursor: "default" }}
            >
              ...
            </PageNumber>
            <PageNumber
              colorVariant="textLight"
              as="span"
              light
              onClick={() => setSelectedPage(articleChunks.length - 1)}
            >
              {articleChunks.length}
            </PageNumber>
          </>
        )}
      </PaginationPages>

      <PaginationButton
        onClick={() => setSelectedPage(selectedPage + 1)}
        disabled={selectedPage === articleChunks.length - 1}
      >
        Newer posts
        <Image
          src={expand}
          alt="Next Page"
          width={24}
          height={24}
          layout="fixed"
          style={{
            marginLeft: 16,
            backgroundColor: "transparent",
            transform: `rotate(270deg)`,
          }}
        />
      </PaginationButton>
    </PaginationWrapper>
  );
};

export default ArticlePagination;
