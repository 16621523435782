import styled from "styled-components";
import { rem } from "polished";

const StyledHairline = styled.hr`
  border: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.grey4};
  grid-column: 3 / 4;
  margin: 0;
  width: 100%;

  /* FIXME: Margin based on <Section /> style */
  &:last-child {
    margin-top: ${rem(76)};
  }
`;

export default function Hairline() {
  return <StyledHairline />;
}
