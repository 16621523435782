import { default as NextLink } from "next/link";
import BackLink from "@/components/BackLink";

export const BackToServices = ({ solutionsUri, parentPath }) => {
  return (
    <NextLink href={solutionsUri || parentPath} passHref>
      <BackLink
        messageId="solutions-back"
        fallbackText="Back to all solutions"
      />
    </NextLink>
  );
};
