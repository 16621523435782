import styled from "styled-components";
import { rem } from "polished";
import Image from "@/components/Image";
import Text from "@/components/Text";
import LinksList from "@/components/LinksList";

const StyledPerson = styled.li`
  display: grid;
  grid-template-columns: ${rem(120)} auto;
  grid-gap: ${rem(20)};

  & + & {
    margin-top: ${rem(40)};
  }

  ul {
    grid-column: 1 / -1;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;

    > div:first-child {
      grid-row: 1 / 3;
    }

    ul {
      grid-column: 2;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    grid-template-columns: ${rem(120)} 1fr 44%;
    grid-template-rows: auto;

    > div:first-child {
      grid-row: 1;
    }

    & + & {
      margin-top: ${rem(20)};
    }

    ul {
      grid-column: 3;
    }
  }
`;

const PersonImageWrapper = styled.div`
  position: relative;
  width: ${rem(120)};
  height: ${rem(120)};
`;

export default function Person(props) {
  const { name, position, image, links = [] } = props;
  return (
    <StyledPerson>
      <PersonImageWrapper>
        <Image src={image.src} alt={name} sizes="120px" />
      </PersonImageWrapper>
      <div>
        <Text>{name}</Text>
        <Text light>{position}</Text>
      </div>
      {links && <LinksList lightText links={links} />}
    </StyledPerson>
  );
}
