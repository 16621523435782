import { useEffect, useState } from "react";
import styled from "styled-components";
import { rem } from "polished";
import Block from "@/blocks/Block";
import Text from "@/components/Text";
import Input from "@/components/Input";
import Message, { useMessage } from "@/components/Message";
import RecaptchaForm, { FORM_TYPE } from "@/components/RecaptchaForm";
import * as gtm from "@/lib/gtm";
import Typography from "@/components/Typography";

const BlockLayout = styled(Block)`
  > p {
    max-width: 24ch;
    margin-bottom: ${rem(40)};
  }

  @media ${({ theme }) => theme.devices.tablet} {
    /* layout: A2 */
    grid-template-columns: 36fr 98fr;

    > p {
      margin-bottom: 0;
    }
  }
`;

const EbookSuccessText = styled(Typography)`
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.success};
`;

export const fragment = `
  fragment SubscribeDownloadBlock on Page_Contentblocks_content_Section_Blocks_SubscribeDownload {
    fieldGroupName
    label
    ebook {
      url: mediaItemUrl
    }
  }
`;

function SubscribeSuccessMessage() {
  const message = useMessage(
    "form_subscribe_success",
    "Thank you! We'll get in touch with you soon."
  );

  return <Text>{message}</Text>;
}

function EbookSuccessMessage({ ebook }) {
  const messageEbook = useMessage("form_subscribe_success_ebook", "Thank you!");
  useEffect(() => {
    if (ebook) {
      //TODO Old gtm method is commented out. As GTM is refactored altogether,
      // come back here once new events are in place
      //gtm.event("Lead- Ebook download");
    }
  }, [ebook]);

  return <EbookSuccessText variant="smallBody" render={messageEbook} />;
}

export default function SubscribeDownload(props) {
  const { label, ebook } = props;
  const [emailValid, setEmailValid] = useState(false);

  const formType = ebook ? FORM_TYPE.EBOOK : FORM_TYPE.SUBSCRIBE;

  const subscribeMessage = useMessage("cta_subscribe", "Subscribe");
  const subscribeAndDownloadMessage = useMessage(
    "cta_subscribe_download",
    "Subscribe and download"
  );
  const submitLabel = ebook ? subscribeAndDownloadMessage : subscribeMessage;

  const onBeforeSubmit = () => {
    if (ebook) {
      window.open(ebook.url, "_blank");
    }
  };

  return (
    <BlockLayout>
      <Text light>{label}</Text>

      <RecaptchaForm
        formType={formType}
        submitLabel={submitLabel}
        customSuccessMessage={
          formType === FORM_TYPE.SUBSCRIBE ? (
            <SubscribeSuccessMessage />
          ) : undefined
        }
        showFormAfterSuccess={formType === FORM_TYPE.EBOOK ? true : undefined}
        customSuccessMessageAfterForm={
          formType === FORM_TYPE.EBOOK ? (
            <EbookSuccessMessage ebook={ebook} />
          ) : undefined
        }
        formInvalid={!emailValid}
        onBeforeSubmit={onBeforeSubmit}
        testName="subscribeDownload"
      >
        <Message
          id="form_email_required"
          fallbackText="* Email"
          render={(message) => (
            <Input
              type="email"
              placeholder={message}
              autoComplete="email"
              name="email"
              required
              pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$"
              onChange={(e) => setEmailValid(e.target.validity.valid)}
              data-testid="subscribeDownload-input"
            />
          )}
        />
        {ebook && <input name="ebook" type="hidden" value={ebook.url} />}
      </RecaptchaForm>
    </BlockLayout>
  );
}
