import Image from "next/image";
import styled from "styled-components";
import { rem } from "polished";
import { absoluteSrcLoader } from "@/lib/wp";

const IconWrapper = styled.div`
  > div {
    max-width: ${rem(48)};
    max-height: ${rem(48)};
  }
`;

export default function Icon({ src, className, style, ariaLabel, onClick = () => {} }) {
  return (
    <IconWrapper className={className} style={style} aria-label={ariaLabel} onClick={onClick}>
      <Image
        src={src}
        alt=""
        width={48}
        height={48}
        layout="fixed"
        loader={absoluteSrcLoader}
      />
    </IconWrapper>
  );
}
