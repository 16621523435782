import styled from "styled-components";
import { rem } from "polished";
import Typography from "@/components/Typography";
import { hoverUnderline } from "@/styles/helpers";

const StyledLinksList = styled.ul`
  margin-top: ${rem(10)};
  margin-bottom: 0;

  @media ${({ theme }) => theme.devices.laptop} {
    margin-top: ${rem(20)};
  }
`;

const TypographyLink = styled(Typography)`
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  text-decoration: none;

  @media ${({ theme }) => theme.devices.mobile} {
    text-decoration: underline;
    margin-bottom: ${rem(8)};
  }

  ${hoverUnderline()}
`;

export default function LinksList({ links, lightText }) {
  return (
    <StyledLinksList>
      {links.map((entry) => {
        const { link, text } = entry;
        return (
          <li key={text}>
            <TypographyLink
              component="a"
              variant="body"
              target="blank"
              colorVariant={lightText && "textLight"}
              href={link || text}
              rel="noopenner noreferrer"
              render={text}
            />
          </li>
        );
      })}
    </StyledLinksList>
  );
}
