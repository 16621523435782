import { Fragment } from "react";
import styled from "styled-components";
import { rem } from "polished";
import Block from "@/blocks/Block";
import Icon from "@/components/Icon";
import Text from "@/components/Text";

const BlockLayout = styled(Block)`
  @media ${({ theme }) => theme.devices.laptop} {
    /* layout: A2 (customized) */
    grid-template-columns: 36fr 36fr 62fr;
  }
`;

const StyledIcon = styled(Icon)`
  margin-bottom: ${rem(10)};
  margin-right: ${rem(24)};

  @media ${({ theme }) => theme.devices.laptop} {
    justify-self: end;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const StyledText = styled(Text)`
  margin-top: ${rem(26)};

  @media ${({ theme }) => theme.devices.laptop} {
    margin-top: 0;
    padding-right: 4em;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${rem(32)};

  @media ${({ theme }) => theme.devices.laptop} {
    grid-column: 2;
    margin-top: 0;
  }
`;

export const fragment = `
  fragment TextIconsList on Page_Contentblocks_content_Section_Blocks_TextIconsList {
    fieldGroupName
    label
    entries {
      icon {
        src: sourceUrl
      }
      label
      text
    }
  }
`;

export default function TextIconsList(props) {
  const { label, entries } = props;
  return (
    <BlockLayout>
      <h3>
        {label}
      </h3>
      {entries.map((entry) => {
        const { icon, label, text } = entry;
        return (
          <Fragment key={label}>
            <IconWrapper>
              <StyledIcon src={icon.src} />
              <Text>{label}</Text>
            </IconWrapper>
            <StyledText light>{text}</StyledText>
          </Fragment>
        );
      })}
    </BlockLayout>
  );
}
