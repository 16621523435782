import { useMemo } from "react";
import styled from "styled-components";
import { rem } from "polished";
import Dialog from "@/components/Dialog";
import Nav from "@/components/Nav";
import SocialLinks from "@/components/SocialLinks";
import useMenuItems from "@/hooks/useMenuItems";
import { hoverUnderline } from "@/styles/helpers";

const StyledDialog = styled(Dialog)`
  /* FIXME: Specificity */
  z-index: 49 !important;

  > section {
    min-height: 100vh;
  }
`;

const Wrapper = styled.div`
  grid-column: 3 / 4;
  grid-row: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  > div {
    margin-top: auto;
  }

  hr {
    border: none;
    border-top: 2px solid ${({ theme }) => theme.colors.grey3};
    margin: 0;
  }
`;

const StyledNav = styled(Nav)`
  margin-left: 20%;

  ul {
    margin: ${rem(20)} 0;
  }

  li {
    padding: 0;
  }

  li + li {
    margin-top: ${rem(20)};
  }

  a {
    text-decoration: none;

    ${hoverUnderline()}
  }

  &:first-of-type {
    a {
      font-size: ${rem(28)};
    }
  }

  &:last-of-type {
    a {
      font-size: ${rem(20)};
    }
  }
`;

const SocialLinksStyled = styled(SocialLinks)`
  ul {
    justify-content: center;
  }
`;

export default function MobileNav({ onClose = () => {} }) {
  const secondaryNavItems = useMenuItems("Secondary menu");
  const socialNavItems = useMenuItems("Social menu");

  const secondaryNavItemsSplit = useMemo(() => {
    const half = Math.ceil(secondaryNavItems.length / 2);
    const firstHalf = secondaryNavItems.slice(0, half);
    const secondHalf = secondaryNavItems.slice(-half);
    return [firstHalf, secondHalf];
  }, [secondaryNavItems]);

  return (
    <StyledDialog align="top" delay={0} appearance="dark">
      <Wrapper>
        <div>
          <StyledNav
            items={secondaryNavItemsSplit[0]}
            vertical
            onItemClick={() => {
              onClose();
            }}
            testNamePrefix="header-mobile-"
          />
          <hr />
          <StyledNav
            items={secondaryNavItemsSplit[1]}
            vertical
            onItemClick={() => {
              onClose();
            }}
            testNamePrefix="header-mobile-"
          />
        </div>
        <div>
          <SocialLinksStyled items={socialNavItems} large />
        </div>
      </Wrapper>
    </StyledDialog>
  );
}
