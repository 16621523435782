import styled from "styled-components";
import { rem } from "polished";
import Chip from "@/components/Chip";

const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin-left: ${rem(24)};

  button {
    margin: ${rem(-4)} 0;
    margin-left: ${rem(48)};
    text-align: left;

    svg {
      flex: 0 0 auto;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: row;
    align-items: center;
    justify-content: start;
    overflow-x: auto;

    ::-webkit-scrollbar {
      height: 4px;
      background-color: ${({ theme }) => theme.colors.grey4};
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.grey1};
      border-radius: 4px;
    }

    button {
      margin: 0;
      margin-left: 0;
      text-align: center;
      white-space: nowrap;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
     display: block;
  }
`;

export default function ChipGroup({
  items = [],
  current,
  onClick = () => {},
  className,
  style,
}) {
  return (
    <List className={className} style={style}>
      {items.map((item) => {
        const { id, label } = item;
        return (
          <li key={id}>
            <Chip
              id={id}
              label={label}
              current={id === current}
              onClick={() => {
                onClick(id);
              }}
              style={id === current ? { marginLeft: 0 } : {}}
            />
          </li>
        );
      })}
    </List>
  );
}
