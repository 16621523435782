import styled, { css } from "styled-components";
import { rem } from "polished";
import { useRouter } from "next/router";
import ButtonBlank from "@/components/ButtonBlank";
import { hoverUnderline } from "@/styles/helpers";

export const styles = css`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.grey1};
  font-size: ${rem(16)};
  font-weight: 500;
  line-height: normal;
  padding: ${rem(12)};

  ${hoverUnderline()}

  ${({ current }) =>
    current &&
    css`
      color: ${({ theme }) => theme.colors.offsetBlack};
      pointer-events: none;
      text-decoration: underline;
    `};
`;

const StyledChip = styled(ButtonBlank)`
  ${styles}

  display: flex;
  align-items: center;

  > svg {
    margin-right: ${rem(24)};
  }

  @media ${({ theme }) => theme.devices.tablet} {
    > svg {
      margin-right: ${rem(12)};
    }
  }
`;

export default function Chip({
  id,
  label,
  current,
  onClick = (id) => {},
  className,
  style,
}) {
  const router = useRouter();
  return (
    <StyledChip
      current={current}
      onClick={() => {
        router.push(`#${id}`);
        onClick(id);
      }}
      className={className}
      style={style}
    >
      {label}
    </StyledChip>
  );
}
