import { useEffect, useRef } from "react";
import styled, { css, ThemeProvider } from "styled-components";
import { rem, transparentize } from "polished";
import { motion } from "framer-motion";
import theme from "@/styles/theme";

const Wrapper = styled.div`
  display: inherit;
  grid-template-columns: inherit;
  grid-column: 1 / -1;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  z-index: 50;

  overflow-y: auto;
`;

const Overlay = styled(motion.div)`
  background-color: ${({ theme }) => transparentize(0.2, theme.colors.grey1)};
  opacity: 0.5;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Section = styled(motion.section)`
  display: inherit;
  grid-template-columns: inherit;
  grid-column: 1 / -1;

  padding: ${rem(40)} 0;
  overflow: hidden;
  width: 100%;

  position: absolute;
  left: 0;

  ${({ align }) => css`
    ${align}: 0;
  `};

  ${({ theme }) => {
    switch (theme.appearance) {
      case "dark":
        return css`
          background-color: ${theme.colors.offsetBlack};
        `;

      case "yellow":
        return css`
          background-color: ${theme.colors.yellow};
        `;

      case "light":
      default:
        return css`
          background-color: ${theme.colors.white};
        `;
    }
  }};
`;

const transition = {
  type: "spring",
  damping: 35,
  stiffness: 350,
};

const overlayMotion = {
  hidden: {
    opacity: 0,
  },
  visible: ({ delay }) => ({
    opacity: 0.5,
    transition: {
      ...transition,
      delay,
    },
  }),
};

const sectionVariants = {
  hidden: ({ align }) => ({
    y: align === "bottom" ? "101%" : "-101%",
  }),
  visible: ({ delay }) => ({
    y: 0,
    transition: {
      ...transition,
      delay: delay + 0.2,
    },
  }),
};

export default function Dialog({
  align = "bottom",
  overlay = true,
  delay = 0.1,
  appearance,
  onOpen = () => {},
  onClose = () => {},
  hideSection,
  children,
  className,
  style,
  sectionStyle,
}) {
  const onAnimationComplete = (definition) => {
    if (definition === "visible") {
      onOpen();
    }
  };

  const dialogRef = useRef();

  useEffect(() => {
    const handleFocusTrap = (e) => {
      if (!dialogRef.current.contains(e.target)) {
        e.stopPropagation();
        dialogRef.current.focus();
      }
    };
    const previouslyFocusedElement = document.activeElement;
    if (overlay) {
      document.addEventListener("focus", handleFocusTrap, true);
      dialogRef.current.focus();
    }
    return () => {
      document.removeEventListener("focus", handleFocusTrap, true);
      previouslyFocusedElement.focus();
    };
  }, [overlay]);

  return (
    <Wrapper role="dialog" className={className} style={style} ref={dialogRef} tabIndex="-1">
      {overlay && (
        <Overlay
          initial="hidden"
          animate="visible"
          variants={overlayMotion}
          custom={{ delay }}
        />
      )}
      <ThemeProvider theme={{ ...theme, appearance }}>
        {!hideSection && (
          <Section
            align={align}
            initial="hidden"
            animate="visible"
            variants={sectionVariants}
            custom={{ align, delay }}
            onAnimationComplete={onAnimationComplete}
            style={sectionStyle}
          >
            {children}
          </Section>
        )}
      </ThemeProvider>
    </Wrapper>
  );
}