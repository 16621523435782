import { rem } from "polished";
import styled from "styled-components";
import useMedia from "@/hooks/useMedia";
import theme from "@/styles/theme";
import Section from "@/components/Section";
import RichText from "@/components/RichText";
import Image from "@/components/Image";
import Video from "@/components/Video";
import CtaButton from "@/components/CtaButton";
import ClutchWidget from "@/components/ClutchWidget";

const Wrapper = styled.div`
  grid-column: 1 / -1;
  background-color: ${theme.colors.videoOffsetBlack};
  display: inherit;
  grid-template-columns: inherit;
`;

const GraphicContent = styled.div`
  grid-column: 1 / -1;

  @media ${({ theme }) => theme.devices.laptop} {
    grid-template-columns: 50fr 50fr;
    display: grid;
    color: white;
    grid-column-gap: 2rem;
    padding-top: 0;
  }

  video {
    height: 37vw;
    max-width: 47.55vw;
    object-fit: contain;

    @media ${({ theme }) => theme.devices.wide} {
      max-height: 710px;
      max-width: 912.44px;
    }
  }
`;

const StyledSection = styled(Section)`
  color: white;
  background-color: ${theme.colors.videoOffsetBlack};
  margin: 0;
  padding: 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  margin-bottom: ${rem(16)};

  h1 {
    margin-top: 0;
  }

  @media ${({ theme }) => theme.devices.mobile} {
    margin-top: ${rem(32)};
  }

  @media ${({ theme }) => theme.devices.laptop} {
    margin: 0;

    h1 {
      font-size: ${theme.fontsMobile.h1.fontSize};
      font-size: ${theme.fontsMobile.h1.lineHeight};
      margin-bottom: 1em;
    }
  }

  @media ${({ theme }) => theme.devices.site} {
    h1 {
      font-size: ${theme.fonts.h2.fontSize};
      font-size: ${theme.fonts.h2.lineHeight};
      margin-bottom: 1em;
    }
  }

  @media ${({ theme }) => theme.devices.wide} {
    h1 {
      font-size: ${theme.fonts.h1.fontSize};
      font-size: ${theme.fonts.h1.lineHeight};
      margin-bottom: 1em;
    }
  }
`;

const CtaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-top: ${rem(16)};

  p {
    display: block;
    margin-right: ${rem(16)};
  }

  @media ${({ theme }) => theme.devices.mobile} {
    justify-content: center;
  }
`;

export default function FeaturedVideo(props) {
  const { mobileSquare, desktopWide, heading, cta, ctaText } = props;
  const matchLaptop = useMedia(theme.devices.laptop);
  const matchTablet = useMedia(theme.devices.tablet);
  const videoProps = {
    autoPlay: true,
    disablePictureInPicture: true,
    muted: true,
    playsInline: true,
    loop: true,
  };

  if (!desktopWide && !mobileSquare) return null;

  return (
    <Wrapper>
      <StyledSection appearance="dark">
        <GraphicContent>
          {!matchLaptop && mobileSquare && (
            <Image
              src={mobileSquare?.mediaItemUrl}
              width="50%"
              height="50%"
              transparent
              layout="responsive"
              objectFit="contain"
              style={{
                maxWidth: matchTablet ? "40%" : "50%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          )}
          <TextWrapper>
            <RichText render={heading} style={{ paddingRight: 0 }} />
            {!matchLaptop && (
                <ClutchWidget />
              )}
            {ctaText && cta.label && (
              <CtaWrapper>
                {matchTablet && <p>{ctaText}</p>}
                <CtaButton id="feature-cta-button" cta={cta} />
              </CtaWrapper>
            )}
            {matchLaptop && desktopWide && (
              <ClutchWidget />
              )}
          </TextWrapper>
          {matchLaptop && desktopWide && (
            <Video src={desktopWide?.mediaItemUrl} {...videoProps} />
          )}
        </GraphicContent>
      </StyledSection>
    </Wrapper>
  );
}
