import { useRouter } from "next/router";
import styled from "styled-components";
import { rem } from "polished";
import Text from "@/components/Text";
import Link from "@/components/Link";
import Message from "@/components/Message";
import Button from "@/components/Button";
import { fluidValue } from "@/styles/helpers";
import { BlockLayout } from "@/blocks/Solutions";

const Item = styled.li`
  display: grid;
  grid-row-gap: ${rem(20)};

  @media ${({ theme }) => theme.devices.laptop} {
    padding-right: 10em;
  }

  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.grey4};
    margin-top: ${rem(40)};
    padding-top: ${rem(40)};
  }

  a {
    font-size: ${rem(16)};
  }

  button {
    justify-self: start;
  }

  h2 {
    ${({ theme }) =>
      fluidValue(
        "font-size",
        28,
        58,
        theme.breakpoints.mobile,
        theme.sizes.containerWidth
      )};

    margin: 0;
    margin-top: -0.075em;
  }

  p {
    color: ${({ theme }) => theme.colors.offsetBlack};
  }

  > ul {
    padding-left: ${rem(20)};

    p {
      color: ${({ theme }) => theme.colors.grey1};
    }
  }
`;

export const AllSolutions = ({ solutions, label }) => {
  const router = useRouter();

  return (
    <BlockLayout>
      {label && <h3>{label}</h3>}
      <ul>
        {solutions?.map((solution) => {
          const { id, title, slug, uri, solutionFields } = solution;
          return (
            <Item key={id}>
              <h2>{title}</h2>
              <Text light>{solutionFields.shortDescription}</Text>
              <ul>
                {solutionFields.ourRole.map((entry) => (
                  <li key={entry.text}>
                    <Text>{entry.text}</Text>
                  </li>
                ))}
              </ul>
              <Button
                onClick={() => router.push(uri)}
                appearance="primary"
                testName={`services-learn-more-${title}`}
              >
                <Message id="learn_more" fallbackText="Learn more" />
              </Button>
            </Item>
          );
        })}
      </ul>
    </BlockLayout>
  );
};
