import Anchor from "@/components/Anchor";

export default function Contacts({ contacts }) {
  return (
    <ul>
      {contacts.links.map((entry) => {
        const { text, link } = entry;
        const href = link || text;
        return (
          <li key={text}>
            <Anchor url={href} label={text} noUnderline={href.startsWith("tel:")} />
          </li>
        );
      })}
    </ul>
  );
}
