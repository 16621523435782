import styled from "styled-components";
import { rem } from "polished";
import useMedia from "@/hooks/useMedia";
import useMenuItems from "@/hooks/useMenuItems";
import { useGlobalContext } from "@/lib/global-context";
import Section from "@/components/Section";
import Logo from "@/components/Logo";
import SocialLinks from "@/components/SocialLinks";
import Anchor from "@/components/Anchor";
import PartnersLinks from "@/blocks/Footer/PartnersLinks";
import Contacts from "@/blocks/Footer/Contacts";
import NavLinksMobile from "@/blocks/Footer/NavLinksMobile";
import NavLinksDesktop from "@/blocks/Footer/NavLinksDesktop";
import theme from "@/styles/theme";

const StyledFooter = styled.footer`
  display: inherit;
  grid-template-columns: inherit;
  grid-column: 1/-1;
  background-color: ${theme.colors.offsetBlack};

  > section {
    display: inherit;
    margin: 0 !important;
  }
`;

const UpperSection = styled(Section)`
  grid-template-columns: 38fr 49fr 49fr !important;
  padding: ${rem(32)} 0 ${rem(16)} 0;

  @media ${({ theme }) => theme.devices.laptop} {
    grid-template-columns: 38fr 98fr !important;
    padding: ${rem(64)} 0 ${rem(30)} 0;
  }
`;

const LowerSection = styled(Section)`
  grid-template-columns: 38fr 49fr 49fr !important;
  align-items: flex-end;
  padding: ${rem(24)} 0 ${rem(20)} 0;

  div:first-of-type {
    grid-column: 2;
  }

  @media ${({ theme }) => theme.devices.laptop} {
    grid-template-columns: 38fr 22fr 76fr !important;
    padding: ${rem(56)} 0 ${rem(64)} 0 !important;
    div:first-of-type {
      grid-column: 1;
    }
  }

  @media ${({ theme }) => theme.devices.mobile} {
    > div {
      grid-column: 2;
    }
    grid-template-columns: 38fr 98fr !important;
  }
`;

const LinksWrapper = styled.div`
  ul {
    margin: 0;
  }

  li {
    padding: ${rem(24)} 0 0 0;
  }

  @media ${({ theme }) => theme.devices.mobile} {
    li {
      padding-top: ${rem(16)};
    }
  }
`;

const PrivacyLinks = styled.ul`
  @media ${({ theme }) => theme.devices.mobile} {
    display: grid;
    grid-template-columns: 49fr 49fr;
  }
`;

export default function Footer() {
  const matchDesktop = useMedia(theme.devices.laptop);
  const site = useGlobalContext();
  const { contacts, partners } = site?.acfOptionsFooter?.footerFields ?? {};
  const socialNavItems = useMenuItems("Social menu");
  const privacyNavItems = useMenuItems("Privacy menu");

  return (
    <StyledFooter>
      <UpperSection appearance="dark">
        <Logo showType={matchDesktop} style={{ height: "26.6px" }} />
        {matchDesktop ? <NavLinksDesktop /> : <NavLinksMobile />}
      </UpperSection>
      <LowerSection appearance="dark">
        {contacts?.links && (
          <LinksWrapper>
            <Contacts contacts={contacts} />
            <SocialLinks items={socialNavItems} navName="Social navigation" />
          </LinksWrapper>
        )}
        <LinksWrapper>
          <PrivacyLinks>
            {privacyNavItems.map(({ url, label }) => (
              <li key={label}>
                <Anchor noUnderline url={url} label={label} />
              </li>
            ))}
          </PrivacyLinks>
        </LinksWrapper>
        {partners && matchDesktop && <PartnersLinks partners={partners} />}
      </LowerSection>
    </StyledFooter>
  );
}
