import { fragment as CategoriesFragment } from "@/blocks/Categories";
import { fragment as CareerFragment } from "@/blocks/Career";
import { fragment as CtaFragment } from "@/blocks/Cta";
import { fragment as DetailsFragment } from "@/blocks/Details";
import { fragment as DetailsWithImageFragment } from "@/blocks/DetailsWithImage";
import { fragment as ExcerptFragment } from "@/blocks/Excerpt";
import { fragment as ImagesFragment } from "@/blocks/Images";
import { fragment as MetaFragment } from "@/blocks/Meta";
import { fragment as PartnersFragment } from "@/blocks/Partners";
import { fragment as CloudPartnersFragment } from "@/blocks/CloudPartners";
import { fragment as PressCoverageFragment } from "@/blocks/PressCoverage";
import { fragment as RelatedPostsFragment } from "@/blocks/RelatedPosts";
import { fragment as SolutionsFragment } from "@/blocks/Solutions";
import { fragment as SubscribeDownloadFragment } from "@/blocks/SubscribeDownload";
import { fragment as StatsFragment } from "@/blocks/Stats";
import { fragment as StatsWithTransitionFragment } from "@/blocks/StatsWithTransition";
import { fragment as TestimonialsFragment } from "@/blocks/Testimonials";
import { fragment as TextFragment } from "@/blocks/Text";
import { fragment as TextIconsListFragment } from "@/blocks/TextIconsList";
import { fragment as TimelineFragment } from "@/blocks/Timeline";
import { fragment as WorkShowcaseFragment } from "@/blocks/WorkShowcase";
import { fragment as WorkByCategoryFragment } from "@/blocks/WorkByCategory";
import { fragment as ContactBlockFragment } from "@/blocks/ContactBlockAsync";
import { fragment as PeopleFragment } from "@/blocks/People";
import { fragment as LocationsFragment } from "@/blocks/Locations";
import { fragment as SocialBlockFragment } from "@/blocks/SocialBlock";
import { fragment as VideoBlockFragment } from "@/blocks/VideoBlock";

const API_URL = process.env.WORDPRESS_API_URL;

async function fetchAPI(query, { variables } = {}) {
  const headers = { "Content-Type": "application/json" };

  if (process.env.WORDPRESS_AUTH_REFRESH_TOKEN) {
    headers[
      "Authorization"
    ] = `Bearer ${process.env.WORDPRESS_AUTH_REFRESH_TOKEN}`;
  }

  const res = await fetch(API_URL, {
    method: "POST",
    headers,
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  const json = await res.json();
  // Soften the response to an error. In addition to the data,
  // errors may be returned if the object is null (not unlinked in WordPress).
  if (!json.data && json.errors) {
    console.error(json.errors);
    throw new Error("Failed to fetch API");
  }

  return json.data;
}

export const absoluteSrcLoader = ({ src, width, quality }) => {
  return src;
};

export async function getPreviewPost(id, idType = "DATABASE_ID") {
  const data = await fetchAPI(
    `
      query PreviewPost($id: ID!, $idType: PostIdType!) {
        post(id: $id, idType: $idType) {
          id
          databaseId
          slug
          status
        }
      }
    `,
    {
      variables: { id, idType },
    }
  );
  return data.post;
}

export async function getLatestPosts(preview) {
  const data = await fetchAPI(
    `
      query LatestPosts {
        posts(first: 20, where: { orderby: { field: DATE, order: DESC } }) {
          edges {
            node {
              id
              title
              excerpt
              slug
              date
              featuredImage {
                node {
                  sourceUrl
                }
              }
              author {
                node {
                  name
                  firstName
                  lastName
                  avatar {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        onlyEnabled: !preview,
        preview,
      },
    }
  );

  return data?.posts;
}

// FIXME: Remove frontendFeatures.preRenderPage fields.
// Now all main pages and subpages are considered as static.

export async function getAllStaticPages() {
  const data = await fetchAPI(
    `
      query StaticPages {
        pages(first: 1000, where: {status: PUBLISH}) {
          nodes {
            id
            uri
          }
        }
        solutions(first: 1000, where: {status: PUBLISH}) {
          nodes {
            id
            uri
          }
        }
        works(first: 1000, where: {status: PUBLISH}) {
          nodes {
            id
            uri
          }
        }
      }
    `
  );

  const pages = data.pages.nodes.filter((node) => node.uri !== "/");
  const solutions = data.solutions.nodes;
  const works = data.works.nodes;

  return {
    nodes: [...pages, ...solutions, ...works],
  };
}

export async function getPageBy(id, idType = "URI") {
  const data = await fetchAPI(
    `
      query PageBy($id: ID!, $idType: PageIdType!) {
        page(id: $id, idType: $idType) {
          id
          uri
          isFrontPage

          contentType {
            node {
              graphqlSingleName
            }
          }

          frontendFeatures {
            pageTemplate
          }

          title

          featuredImage {
            node {
              src: sourceUrl
              alt: altText
            }
          }

          contentBlocks {
            content {
              section {
                headline
                biggerHeadline
                hasBackButton
                backButtonLabel
                blocks {
                  ...CategoriesBlock
                  ...CareerBlock
                  ...CtaBlock
                  ...DetailsBlock
                  ...DetailsWithImage
                  ...ExcerptBlock
                  ...ImagesBlock
                  ...MetaBlock
                  ...PartnersBlock
                  ...CloudPartnersBlock
                  ...PressCoverageBlock
                  ...RelatedPostsBlock
                  ...SolutionsBlock
                  ...SubscribeDownloadBlock
                  ...StatsBlock
                  ...StatsWithTransitionBlock
                  ...Testimonials
                  ...TextBlock
                  ...TextIconsList
                  ...TimelineBlock
                  ...WorkShowcaseBlock
                  ...WorkByCategoryBlock
                  ...ContactBlock
                  ...PeopleBlock
                  ...Locations
                  ...SocialBlock
                  ...VideoBlock
                }
                theme
                graphic {
                  image {
                    src: sourceUrl
                    mediaDetails {
                      width
                      height
                    }
                  }
                  imageMobile {
                    src: sourceUrl
                    mediaDetails {
                      width
                      height
                    }
                  }
                  align
                }
                line
              }
            }
          }

          seo {
            title
            metaDesc
            focuskw
            opengraphTitle
            opengraphDescription
            opengraphImage {
              altText
              sourceUrl
            }
            opengraphUrl
            opengraphSiteName
          }
        }
      }

      ${CategoriesFragment}
      ${CareerFragment}
      ${CtaFragment}
      ${DetailsFragment}
      ${DetailsWithImageFragment}
      ${ExcerptFragment}
      ${ImagesFragment}
      ${MetaFragment}
      ${PartnersFragment}
      ${CloudPartnersFragment}
      ${PressCoverageFragment}
      ${RelatedPostsFragment}
      ${SolutionsFragment}
      ${SubscribeDownloadFragment}
      ${StatsFragment}
      ${StatsWithTransitionFragment}
      ${TestimonialsFragment}
      ${TextFragment}
      ${TextIconsListFragment}
      ${TimelineFragment}
      ${WorkShowcaseFragment}
      ${WorkByCategoryFragment}
      ${ContactBlockFragment}
      ${PeopleFragment}
      ${LocationsFragment}
      ${SocialBlockFragment}
      ${VideoBlockFragment}
    `,
    {
      variables: { id, idType },
    }
  );

  return data.page;
}

export async function getCustomPostType(id, postType) {
  switch (postType) {
    default: {
      return {};
    }
  }
}

export async function getPageTemplate(id, pageTemplate) {
  switch (pageTemplate) {
    case "home":
      return await getHomeTemplate(id);
    default: {
      return {};
    }
  }
}

export async function getHomeTemplate(id) {
  const data = await fetchAPI(
    `
    query Home($id: ID!, $idType: PageIdType!) {
      page(id: $id, idType: $idType) {
        frontPage {
          featuredVideo {
            mobileSquare {
              mediaItemUrl
            }
            desktopWide {
              mediaItemUrl
            }
            heading,
            ctaText,
            cta {
              action
              label
              anchor
              ctaAppearance
              pageUrl
              dataTestid
            }
          }
        }
      }
    }
    `,
    {
      variables: { id, idType: "URI" },
    }
  );
  return data.page?.frontPage;
}

export async function getWorks() {
  const data = await fetchAPI(
    `
    query Works {
      works(first: 100, where: {status: PUBLISH, orderby: {field: MENU_ORDER, order: DESC}}) {
        nodes {
          id
          title
          contentBlocks {
            content {
              section {
                blocks {
                  ... on Work_Contentblocks_content_Section_Blocks_Testimonials {
                    entries {
                      caption
                      quote
                      image {
                        sourceUrl
                        altText
                      }
                    }                 
                  }
                }
              }
            }
          }
          featuredImage {
            node {
              src: sourceUrl
              alt: altText
              mediaDetails {
                width
                height
              }
            }
          }
          categories {
            nodes {
              id
              name
              slug
              categoryTaxonomy {
                prioritizedWork {
                  ... on Work {
                    id
                  }
                }
              }
            }
          }
          uri
          workFields {
            featuredImage {
              mobileImage {
                src: sourceUrl
                alt: altText
              }
              laptopImage {
                src: sourceUrl
                alt: altText
              }
            }
            showcaseImage {
              squareImage {
                src: sourceUrl
                alt: altText
              }
              wideImage {
                src: sourceUrl
                alt: altText
              }
            }
            solutions {
              ... on Solution {
                id
                uri
              }
            }
          }
        }
      }
    }
    `
  );
  return data.works;
}

export async function getCategories() {
  const data = await fetchAPI(
    `
    query Categories {
      categories(where: {orderby: TERM_ORDER, order: ASC}) {
        nodes {
          id
          name
          slug
          categoryTaxonomy {
            prioritizedWork {
              ... on Work {
                id
              }
            }
          }
          works {
            nodes {
              id
            }
          }
        }
      }
    }
    `
  );
  return data.categories;
}

export async function getSolutions() {
  const data = await fetchAPI(
    `
    query Solutions {
      solutions(first: 100, where: {status: PUBLISH, orderby: {field: MENU_ORDER, order: DESC}}) {
        nodes {
          id
          title
          slug
          uri
          label: title
          solutionFields {
            shortDescription
            ourRole {
              text
            }
          }
        }
      }
    }
    `
  );
  return data.solutions;
}

export async function getSolutionsUri() {
  const data = await fetchAPI(
    `
    query SolutionsUri {
      pageBy(pageId: 7) {
        uri
      }
    }
    `
  );
  return data.pageBy.uri;
}
