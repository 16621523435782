import { rem } from "polished";

function Wrapper({ children, large }) {
  const size = large ? rem(32) : rem(24);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: size, height: size }}
    >
      {children}
    </svg>
  );
}

export default function SocialIcon({ id, large }) {
  switch (id) {
    case "LinkedIn":
    case "LINKEDIN":
      return (
        <Wrapper large={large}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 2.838A1.838 1.838 0 012.838 1H21.16A1.836 1.836 0 0123 2.838V21.16A1.838 1.838 0 0121.161 23H2.838A1.839 1.839 0 011 21.161V2.838zm8.708 6.55h2.979v1.496c.43-.86 1.53-1.634 3.183-1.634 3.169 0 3.92 1.713 3.92 4.856v5.822h-3.207v-5.106c0-1.79-.43-2.8-1.522-2.8-1.515 0-2.145 1.089-2.145 2.8v5.106H9.708V9.388zm-5.5 10.403h3.208V9.25H4.208V19.791zM7.875 5.812a2.063 2.063 0 11-4.125.09 2.063 2.063 0 014.125-.09z"
            fill="currentColor"
          />
        </Wrapper>
      );

    case "Facebook":
    case "FACEBOOK":
      return (
        <Wrapper large={large}>
          <path
            d="M20 3H4a1 1 0 00-1 1v16a1 1 0 001 1h8.615v-6.96h-2.338v-2.725h2.338v-2c0-2.325 1.42-3.592 3.5-3.592.699-.002 1.399.034 2.095.107v2.42h-1.435c-1.128 0-1.348.538-1.348 1.325v1.735h2.697l-.35 2.725h-2.348V21H20a1 1 0 001-1V4a1 1 0 00-1-1z"
            fill="currentColor"
          />
        </Wrapper>
      );

    case "Twitter":
    case "TWITTER":
      return (
        <Wrapper large={large}>
          <path
            d="M23.643 4.937c-.835.37-1.732.62-2.675.733a4.67 4.67 0 002.048-2.578 9.3 9.3 0 01-2.958 1.13 4.66 4.66 0 00-7.938 4.25 13.229 13.229 0 01-9.602-4.868 4.66 4.66 0 001.442 6.22 4.647 4.647 0 01-2.11-.583v.06a4.66 4.66 0 003.737 4.568 4.692 4.692 0 01-2.104.08 4.661 4.661 0 004.352 3.234 9.348 9.348 0 01-5.786 1.995 9.5 9.5 0 01-1.112-.065 13.175 13.175 0 007.14 2.093c8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602a9.47 9.47 0 002.323-2.41l.002-.003z"
            fill="currentColor"
          />
        </Wrapper>
      );

    case "Instagram":
    case "INSTAGRAM":
      return (
        <Wrapper large={large}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.465 1.066C8.638 1.012 9.012 1 12 1c2.988 0 3.362.013 4.534.066 1.172.053 1.972.24 2.672.511.733.277 1.398.71 1.948 1.27.56.549.992 1.213 1.268 1.947.272.7.458 1.5.512 2.67C22.988 8.639 23 9.013 23 12c0 2.988-.013 3.362-.066 4.535-.053 1.17-.24 1.97-.512 2.67a5.396 5.396 0 01-1.268 1.949c-.55.56-1.215.992-1.948 1.268-.7.272-1.5.458-2.67.512-1.174.054-1.548.066-4.536.066-2.988 0-3.362-.013-4.535-.066-1.17-.053-1.97-.24-2.67-.512a5.396 5.396 0 01-1.949-1.268 5.392 5.392 0 01-1.269-1.948c-.271-.7-.457-1.5-.511-2.67C1.012 15.361 1 14.987 1 12c0-2.988.013-3.362.066-4.534.053-1.172.24-1.972.511-2.672a5.396 5.396 0 011.27-1.948 5.392 5.392 0 011.947-1.269c.7-.271 1.5-.457 2.67-.511h.001zm8.98 1.98c-1.16-.053-1.508-.064-4.445-.064-2.937 0-3.285.011-4.445.064-1.073.049-1.655.228-2.043.379-.513.2-.88.437-1.265.822a3.412 3.412 0 00-.822 1.265c-.151.388-.33.97-.379 2.043-.053 1.16-.064 1.508-.064 4.445 0 2.937.011 3.285.064 4.445.049 1.073.228 1.655.379 2.043.176.477.457.91.822 1.265.355.365.788.646 1.265.822.388.151.97.33 2.043.379 1.16.053 1.507.064 4.445.064 2.938 0 3.285-.011 4.445-.064 1.073-.049 1.655-.228 2.043-.379.513-.2.88-.437 1.265-.822.365-.355.646-.788.822-1.265.151-.388.33-.97.379-2.043.053-1.16.064-1.508.064-4.445 0-2.937-.011-3.285-.064-4.445-.049-1.073-.228-1.655-.379-2.043-.2-.513-.437-.88-.822-1.265a3.413 3.413 0 00-1.265-.822c-.388-.151-.97-.33-2.043-.379zm-5.85 12.345a3.67 3.67 0 102.641-6.846 3.67 3.67 0 00-2.641 6.846zM8.002 8.002a5.654 5.654 0 117.996 7.996 5.654 5.654 0 01-7.996-7.996zm10.906-.814a1.336 1.336 0 10-1.834-1.944 1.336 1.336 0 001.834 1.944z"
            fill="currentColor"
          />
        </Wrapper>
      );

    case "YouTube":
    case "YOUTUBE":
      return (
        <Wrapper large={large}>
          <path
            d="M23.5 6.507a2.786 2.786 0 00-.766-1.27 3.05 3.05 0 00-1.338-.742C19.518 4 11.994 4 11.994 4a76.622 76.622 0 00-9.39.47c-.502.14-.962.4-1.338.76-.37.356-.638.795-.778 1.276A29.09 29.09 0 000 12c-.012 1.841.15 3.68.488 5.494.137.48.404.916.775 1.27.37.352.833.607 1.34.742 1.904.494 9.39.494 9.39.494 3.141.036 6.281-.121 9.403-.47a3.05 3.05 0 001.338-.742 2.78 2.78 0 00.765-1.27c.345-1.811.513-3.651.5-5.495.027-1.851-.141-3.7-.5-5.517v.001zM9.602 15.424V8.577l6.26 3.424-6.26 3.423z"
            fill="currentColor"
          />
        </Wrapper>
      );

    default:
      console.warn(`Social icon for "${id}" not implemented`);
      return null;
  }
}
