import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { rem } from "polished";
import Cookie from "js-cookie";
import ReCAPTCHA from "react-google-recaptcha";
import Text from "@/components/Text";
import Button from "@/components/Button";
import Spinner from "@/components/Spinner";
import Message from "@/components/Message";
import Checkbox from "@/components/Checkbox";
import { useGlobalContext } from "@/lib/global-context";

const StyledForm = styled.form`
  transition: opacity 350ms ${({ theme }) => theme.easing.standard};

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${rem(40)};
    min-width: ${rem(120)};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      user-select: none;
      opacity: 0.5;
    `};

  @media ${({ theme }) => theme.devices.mobile} {
    button {
      margin-top: ${rem(24)};
      min-width: auto;
      width: 100%;
    }
  }
`;

const CaptchaWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .grecaptcha-badge {
    margin-top: ${rem(40)};
    margin-left: auto;
  }

  @media ${({ theme }) => theme.devices.mobile} {
    flex-direction: column;
    align-items: flex-start;

    .grecaptcha-badge {
      margin-top: ${rem(16)};
    }
  }
`;

const MessageWrapper = styled.div`
  margin-top: ${rem(80)};

  h3 {
    font-size: ${rem(28)};
    line-height: 1;
    margin-top: 0;
    margin-bottom: 1em;
  }

  p {
    margin-top: ${rem(20)};
  }

  @media ${({ theme }) => theme.devices.mobile} {
    margin-top: 0;
  }
`;

const CheckBoxLabel = styled.label`
  font-size: ${({ theme }) => theme.fonts.smallBody.fontSize};
  font-weight: ${({ theme }) => theme.fonts.smallBody.fontWeight};
  line-height: ${({ theme }) => theme.fonts.smallBody.lineHeight};
  color: ${({ theme }) => theme.colors.grey2};

  a {
    font: inherit;
    text-decoration: underline;
  }
`;

function SuccessMessage({ onClose }) {
  return (
    <MessageWrapper
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "right",
        position: "relative",
      }}
    >
      <Text style={{ marginTop: 0 }}>
        <Message
          id="form_success"
          fallbackText="Thank you, your message is sent! We'll get in touch with you soon."
        />
      </Text>
    </MessageWrapper>
  );
}

function ErrorMessage({ reset = () => {} }) {
  return (
    <MessageWrapper>
      <Text>
        <Message
          id="form_error"
          fallbackText="An unexpected error has occurred."
        />
        <br />{" "}
        <Message
          id="form_resubmission"
          fallbackText="Please try form submission again."
        />{" "}
        <Message
          id="aria_thank_you"
          fallbackText="Thank You"
          render={(message) => (
            <span role="img" aria-label={message}>
              🙏
            </span>
          )}
        />
      </Text>
    </MessageWrapper>
  );
}

export const FORM_TYPE = {
  CONTACT: "contact",
  EBOOK: "ebook",
  SUBSCRIBE: "subscribe",
};

export const FORM_STATE = {
  INITIAL: "initial",
  SUBMIT: "submit",
  ERROR: "error",
  SUCCESS: "success",
};

const privacyLinkKey = "{{privacy-policy}}";

export default function RecaptchaForm({
  formType,
  submitLabel,
  customErrorMessage,
  customSuccessMessage,
  customSuccessMessageAfterForm,
  customStateMessage,
  showFormAfterSuccess,
  children,
  className,
  style,
  onChange = () => {},
  onBeforeSubmit = () => {},
  onAfterSuccess = () => {},
  formInvalid,
  testName,
}) {
  const site = useGlobalContext();
  const { privacyPolicyPath: privacyLink } =
    site?.acfOptionsCookiePolicy?.cookiePolicyFields ?? {};

  const recaptchaRef = useRef();
  const [formState, setFormState] = useState();
  const [policyAgreement, setPolicyAgreement] = useState(false);

  useEffect(() => {
    onChange(formState);
  }, [formState, onChange]);

  async function onFormSubmit(event) {
    event.preventDefault();
    setFormState(FORM_STATE.SUBMIT);

    const formData = new FormData(event.target);

    try {
      formData.delete("g-recaptcha-response");

      const token = await recaptchaRef.current.executeAsync();
      formData.append("token", token);

      // NOTE: Parse UTM parameters and add feilds: source, medium.
      const params = new URLSearchParams(document.location.search.substring(1));
      const source = params.get("utm_source");
      const medium = params.get("utm_medium");
      if (source && medium) {
        formData.append("source", source);
        formData.append("medium", medium);
      }

      // NOTE: Get a cookie to determine if the user has completed the contact form.
      const contactUsValue = Cookie.get("contact-us");
      const hasCompletedContactForm = contactUsValue === "yes";
      formData.append("contactus", hasCompletedContactForm);
      formData.append('policyAgreement', policyAgreement)

      let apiUrl = "/api/subscribe";
      if (formType === FORM_TYPE.CONTACT) {
        apiUrl = "/api/contact";
      }

      const res = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.fromEntries(formData)),
      });
      if (res.ok) {
        // NOTE: Set a cookie to determine if the user has completed the contact form,
        // and include this information in the "Schedule A Call" consultation form.
        if (formType === FORM_TYPE.CONTACT) {
          Cookie.set("contact-us", "yes", { expires: 365, sameSite: "Lax" });
        }
        onAfterSuccess(formData);
        setFormState(FORM_STATE.SUCCESS);
      } else {
        setFormState(FORM_STATE.ERROR);
      }
    } catch (error) {
      console.warn(error);
      setFormState(FORM_STATE.ERROR);
    }
  }

  if (formState === FORM_STATE.SUCCESS && !showFormAfterSuccess) {
    return customStateMessage === false
      ? null
      : customSuccessMessage || (
          <SuccessMessage
            onClose={() => {
              setFormState(FORM_STATE.INITIAL);
            }}
          />
        );
  }

  return (
    <StyledForm
      onSubmit={onFormSubmit}
      disabled={formState === FORM_STATE.SUBMIT}
      className={className}
      style={style}
    >
      <input name="form" type="hidden" value={formType} />

      {children}

      <Checkbox
        renderLabel={() => (
          <Message
            id="form_policy_agreement"
            fallbackText={`I have read and agree to Mooncascade’s ${privacyLinkKey}`}
            render={(message) => {
              return (
                <CheckBoxLabel htmlFor="policyAgreement">
                  <span dangerouslySetInnerHTML={{ __html: message }} />
                </CheckBoxLabel>
              );
            }}
          />
        )}
        id="policyAgreement"
        data-testid={testName ? `${testName}-policyAgreement` : undefined}
        required
        onChange={(event) => {
          setPolicyAgreement(event.target.checked);
        }}
      />
      {formType === FORM_TYPE.CONTACT && (
        <Checkbox
          id="subscribeNewsletter"
          name="subscribeNewsletter"
          data-testid={testName ? `${testName}-subscribeNewsletter` : undefined}
          renderLabel={() => (
            <Message
              id="form_subscribe_newsLetter"
              fallbackText={`I want to subscribe to Mooncascade’s (Mooncascade OÜ) e-mail newsletter to receive general information about Mooncascade and its services (e.g. blog posts, industry news, event invitations etc). I understand that I can unsubscribe from the newsletter at any time by pressing “Unsubscribe” below the e-mails.`}
              render={(message) => (
                <CheckBoxLabel htmlFor="subscribeNewsletter">
                  {message}
                </CheckBoxLabel>
              )}
            />
          )}
        />
      )}
      <CaptchaWrapper>
        <Button
          largeFont
          appearance="primary"
          type="submit"
          disabled={!!formInvalid || !policyAgreement}
          onClick={onBeforeSubmit}
          testName={testName ? `${testName}-submit` : undefined}
        >
          {formState === FORM_STATE.SUBMIT ? (
            <Spinner />
          ) : (
            submitLabel || <Message id="form_send" fallbackText="Send" />
          )}
        </Button>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          badge="inline"
          sitekey={
            process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || "reCAPTCHA_site_key"
          }
        />
      </CaptchaWrapper>

      {formState === FORM_STATE.SUCCESS &&
        !!customSuccessMessageAfterForm &&
        customSuccessMessageAfterForm}

      {formState === FORM_STATE.ERROR
        ? customStateMessage === false
          ? null
          : customErrorMessage || (
              <ErrorMessage
                reset={() => {
                  setFormState(null);
                }}
              />
            )
        : null}
    </StyledForm>
  );
}
