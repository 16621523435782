import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import styled from "styled-components";
import { rem } from "polished";
import Cookie from "js-cookie";
import Dialog from "@/components/Dialog";
import Button from "@/components/Button";
import Message from "@/components/Message";
import Typography from "@/components/Typography";
import { useGlobalContext } from "@/lib/global-context";
import {
  EVENT_COOKIE_CONSENT_UPDATE,
  EVENT_COOKIE_CONSENT_VISUALIZED,
} from "@/lib/gtm";
import useMedia from "@/hooks/useMedia";
import theme from "@/styles/theme";
import DialogCookiesPreferences from "@/components/DialogCookiesPreferences";

const Layout = styled.div`
  display: grid;
  grid-column: 3 / 4;

  @media ${({ theme }) => theme.devices.tablet} {
    grid-row-gap: ${rem(16)};
    grid-template-columns: 76fr 24fr;
    grid-template-rows: auto auto;
  }
`;

const Title = styled(Typography)`
  @media ${({ theme }) => theme.devices.tablet} {
    grid-column: 1;
    grid-row: 1;
  }
`;

const Description = styled(Typography)`
  padding-top: ${rem(11)};

  a {
    color: ${({ theme }) => theme.colors.white};

    &:hover {
      color: ${({ theme }) => theme.colors.grey2};
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: 0;
    padding-right: 4em;
    grid-column: 1;
    grid-row: 2;
  }
`;

const ButtonsGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: ${rem(24)};

  button + button {
    margin-left: ${rem(16)};
    margin-right: ${rem(16)};
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: 0;
    grid-column: 2;
    grid-row: 1 / 3;
    justify-self: start;
    justify-content: flex-start;

    button + button {
      margin-left: ${rem(48)};
      margin-right: ${rem(48)};
    }
  }
`;

const storeKey = "cookie-policy";

export function hasAcceptedCookies() {
  const value = Cookie.get(storeKey);
  if (value) {
    let parsedValue;
    try {
      parsedValue = JSON.parse(value);
      new Date(parsedValue.data);
    } catch (error) {}
    if (parsedValue.policy === "accept" || parsedValue.policy === "custom") {
      return true;
    }
  }
  return false;
}

export function getCookiePreferences() {
  const value = Cookie.get(storeKey);
  if (value) {
    try {
      const parsedValue = JSON.parse(value);
      if (new Date(parsedValue.date) && parsedValue.policy === "custom") {
        return parsedValue.preferences;
      }
    } catch (error) {
      return null;
    }
  }
  return null;
}

export default function DialogCookies() {
  const site = useGlobalContext();
  const router = useRouter();
  const [domain, setDomain] = useState("");
  const [showPreferences, setShowPreferences] = useState(false);

  const { label, description, privacyPolicyPath } =
    site?.acfOptionsCookiePolicy?.cookiePolicyFields ?? {};

  const isPrivacyPolicyPage = privacyPolicyPath === router.asPath;

  // Do not use lazy initializer because of SSR output mismatch
  const [confirmed, setConfirmed] = useState(hasAcceptedCookies());

  useEffect(() => {
    if (!confirmed) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: EVENT_COOKIE_CONSENT_VISUALIZED,
      });
    }
    const host = window.location.hostname.split(".").reverse();
    const domain = "." + (host[1] ? host[1] + "." : "") + host[0];
    setDomain(domain);
  }, []);

  const matchTablet = useMedia(theme.devices.tablet);

  const getDate = () => new Date().toLocaleDateString("en-GB");

  const handleManagePreferences = () => {
    setShowPreferences(true);
  };

  function onAccept() {
    Cookie.set(
      storeKey,
      JSON.stringify({ policy: "accept", date: getDate() }),
      { expires: 30, sameSite: "Lax", domain }
    );

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: EVENT_COOKIE_CONSENT_UPDATE,
      consent_marketing: "granted",
      consent_analytics: "granted",
      consent_personalization: "granted",
    });

    setConfirmed(true);
  }

  function onSavePreferences(preferences) {
    Cookie.set(
      storeKey,
      JSON.stringify({
        policy: "custom",
        preferences: preferences,
        date: getDate(),
      }),
      { expires: 30, sameSite: "Lax", domain: domain }
    );

    const dataLayerPreferences = {
      consent_marketing: preferences.marketing ? "granted" : "denied",
      consent_analytics: preferences.analytics ? "granted" : "denied",
      consent_personalization: preferences.personalization
        ? "granted"
        : "denied",
    };

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: EVENT_COOKIE_CONSENT_UPDATE,
      ...dataLayerPreferences,
    });

    setConfirmed(true);
    setShowPreferences(false);
  }

  if (showPreferences) {
    return <DialogCookiesPreferences onSave={onSavePreferences} />;
  }

  if (confirmed || isPrivacyPolicyPage) {
    return null;
  }

  return (
    <>
      <Dialog
        appearance="dark"
        sectionStyle={matchTablet ? {} : { paddingTop: rem(15) }}
      >
        <Layout>
          <Title
            variant={matchTablet ? "h3" : "body"}
            colorVariant="textOnDark"
            render={label}
          />
          <Description
            variant={matchTablet ? "body" : "smallBody"}
            component="div"
            colorVariant="textOnDark"
            render={description}
          />
          <ButtonsGroup>
            <Button
              largePadding
              onClick={handleManagePreferences}
              appearance="tertiary"
              testName="cookie-modal-manage-preferences"
            >
              <Message
                id="button_manage_preferences"
                fallbackText="Manage Preferences"
              />
            </Button>
            <Button
              largePadding
              onClick={onAccept}
              appearance="filledOnDark"
              testName="cookie-modal-accept"
            >
              <Message id="button_accept" fallbackText="Accept" />
            </Button>
          </ButtonsGroup>
        </Layout>
      </Dialog>
    </>
  );
}
