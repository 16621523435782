import React from "react";
import styled from "styled-components";
import Text from "@/components/Text";
import Image from "@/components/Image";
import Link from "@/components/Link";
import Message from "@/components/Message";
import { rem } from "polished";

const Article = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey4};
  padding-bottom: ${rem(30)};

  > div:first-child {
    margin-bottom: ${rem(10)};
  }

  a {
    margin-top: ${rem(10)};
  }

  & + & {
    margin-top: ${rem(10)};
  }

  @media ${({ theme }) => theme.devices.mobile} {
    display: flex;
    flex-direction: column;
    padding-bottom: ${rem(54)};

    a {
      margin-top: ${rem(16)};
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    display: grid;
    grid-template-columns: 29fr 29fr 38fr 38fr;
    grid-column-gap: ${rem(20)};

    > div:first-child {
      grid-column: 1 / 3;
      margin-bottom: 0;

      p + p {
        margin-top: ${rem(10)};
      }
    }

    a {
      align-self: start;
      justify-self: start;
      margin-top: 0;
      margin-left: ${rem(40)};
      transform: translateY(-0.5em);
    }
  }

  @media ${({ theme }) => theme.devices.laptop} {
    > div:first-child {
      grid-column: 2;
    }
  }
`;

const ThumbWrapper = styled.div`
  position: relative;
  padding-top: 60%;
  margin: 0 ${rem(-10)};
`;

const ArticleItem = ({ article }) => {
  const { title, medium, thumbnail, link } = article;
  return (
    <Article key={title}>
      <div>
        <Text>{title}</Text>
        <Text light>{medium}</Text>
      </div>
      <ThumbWrapper>
        <Image src={thumbnail.src} alt={thumbnail.alt} />
      </ThumbWrapper>
      <Link linkCapitalized to={link} target="_blank" rel="noopener noreferrer">
        <Message id="read_article" fallbackText="Read article" />
      </Link>
    </Article>
  );
};

export default ArticleItem;
