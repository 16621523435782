import styled from "styled-components";
import { rem } from "polished";
import Typography from "@/components/Typography";

export const HeadingOffset = styled.div`
  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media ${({ theme }) => theme.devices.laptop} {
    display: grid;
    /* layout: A2 */
    grid-template-columns: 36fr 98fr;
    grid-column-gap: ${rem(20)};

    > * {
      grid-column: 2;
    }
  }
  
  h1, h2 {
    color: inherit !important;
  }
`;

export default function SectionHeading(props) {
  const { as = "h2", children, className, style } = props;
  return (
    <HeadingOffset>
      <Typography variant={as} component={as} className={className} style={style}>{children}</Typography>
    </HeadingOffset>
  );
}
